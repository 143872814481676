import {
  ArrowNarrowLeftIcon,
  BookOpenIcon,
  ChevronLeftIcon,
  HeartIcon,
  PlayIcon,
} from "@heroicons/react/solid";
import React, { Fragment, useState, useContext } from "react";
import MenuItemButton from "../components/Buttons/MenuItemButton";
import RatingToStar from "../components/Others/RatingToStar";
import moment from "moment";
import {
  ChevronDownIcon,
  DownloadIcon,
  EmojiSadIcon,
  ExclamationIcon,
  PencilIcon,
  ThumbDownIcon,
  ThumbUpIcon,
  ChatAlt2Icon,
} from "@heroicons/react/outline";
import { Menu, Transition } from "@headlessui/react";
import { FlatButton } from "../components/Buttons/Buttons";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  addToFavorites,
  getContent,
  removeToFavorites,
  reportContent,
} from "../services/contents";
import { Link } from "react-router-dom";
import Modal from "../components/Others/Modal";
import TextArea from "../components/Inputs/TextArea";
import StarRating from "../components/Others/StarRating";
import ContentReview from "../components/Contents/ContentReview";
import { useToasts } from "react-toast-notifications";
import Loader from "react-loader-spinner";
import { AuthContext } from "../context/AuthContext";
import { writeReview } from "../services/review";
import { useQueryClient } from "react-query";
import Loading from "../components/Others/Loading";
import { downloadContent } from "../services/download";
import ReportModal from "../components/Others/ReportModal";
import ReviewModal from "../components/Others/ReviewModal";
import ImageNotFound from "../assets/ImageNotFound.jpg";
import * as mime from "mime-types";
var env = process.env.NODE_ENV || "development";
const server = `${
  env === "development"
    ? process.env.REACT_APP_DEV_SERVER
    : process.env.REACT_APP_PROD_SERVER
}`;

const ContentView = () => {
  let { contentId } = useParams();
  const { addToast } = useToasts();
  const authContext = useContext(AuthContext);
  const { data, isLoading, isError } = useQuery(
    ["content", contentId],
    getContent
  );
  const history = useHistory();
  const queryClient = useQueryClient();
  const [writeReviewModal, setWriteReviewModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [favLoading, setFavLoading] = useState(false);
  // console.warn(data);
  const FavoriteContent = (contentId) => {
    setFavLoading(true);
    addToFavorites(contentId)
      .then((res) => {
        // console.log(res);
        addToast("Successfully added this content to favorites", {
          appearance: "success",
        });
        setFavLoading(false);
        queryClient.invalidateQueries("favArray");
      })
      .catch((e) => {
        // console.log(e);
        addToast("Failed to add content to favorites", {
          appearance: "error",
        });
        setFavLoading(false);
      });
  };

  const RemoveFavorite = (favoriteId) => {
    setFavLoading(true);
    removeToFavorites(favoriteId)
      .then((res) => {
        // console.log(res);
        addToast("Successfully removed this content from favorites", {
          appearance: "success",
        });
        setFavLoading(false);
        queryClient.invalidateQueries("favArray");
      })
      .catch((e) => {
        // console.log(e);
        addToast("Failed to remove content from favorites", {
          appearance: "error",
        });
        setFavLoading(false);
      });
  };

  const DownloadContent = () => {
    downloadContent(data.contentId)
      .then((res) => {
        // console.log(res);
      })
      .catch((e) => {
        // console.log(e);
      });
  };

  if (isError) {
    return (
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="flex flex-col mb-4 justify-center text-gray-500 space-y-3">
            <span className="flex text-3xl font-extrabold justify-center">
              Content Not Found <EmojiSadIcon className="w-10 h-10 ml-3" />
            </span>
            <span className="text-center">
              The content you are accessing is not available.
            </span>
            <div className="flex justify-center">
              <Link
                to="/"
                className=" py-2 px-4 rounded-md text-sm font-medium text-gray-500 hover:bg-gray-200 focus:outline-none"
              >
                Go Back to Home Page
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="animate-pulse">
        <div className="sm:p-10 sm:pb-10 p-3 lg:space-y-4 overflow-auto">
          <div className="flex lg:flex-row flex-col my-2 gap-x-4 ">
            <div className="flex-1 flex-col h-96 bg-gray-300 rounded-md"></div>
            <div className="flex-1 flex-col h-96 bg-gray-300 rounded-md"></div>
          </div>
          <div className="w-full bg-gray-300 h-28 rounded md"></div>
          <div className="flex gap-x-4">
            <div className="w-10/12 bg-gray-300 h-10 rounded md"></div>
            <div className="flex-auto bg-gray-300 h-10 rounded-md"></div>
          </div>
          <div className="h-90 border rounded-md p-4 space-y-2">
            <div className="flex gap-x-4">
              <div className="h-10 w-10 bg-gray-300 rounded-full"></div>
              <div className="w-96 bg-gray-300 h-10 rounded-md"></div>
              <div className="ml-auto w-40 h-10 bg-gray-300 rounded-md"></div>
            </div>
            <div className="space-y-2">
              <div className="w-full bg-gray-300 h-10 rounded md"></div>
              <div className="w-10/12 bg-gray-300 h-10 rounded md"></div>
              <div className="w-11/12 bg-gray-300 h-10 rounded md"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (data)
    return (
      <div className="h-full w-full overflow-auto">
        <ReportModal
          isOpen={reportModal}
          onClose={() => {
            setReportModal(false);
          }}
          setLoading={setLoading}
        />
        <ReviewModal
          isOpen={writeReviewModal}
          onClose={() => {
            setWriteReviewModal(false);
          }}
          contentId={data.contentId}
          setLoading={setLoading}
        />
        {loading && <Loading loading={true} />}
        <div className="space-y-10 overflow-auto h-full">
          <div className="sm:p-20 py-20 px-8  bg-black bg-opacity-80 relative shadow-xl group">
            <FlatButton
              className="absolute top-5 left-5 z-40 transition text-white hover:bg-opacity-20"
              onClick={() => {
                authContext.isAdmin()
                  ? history.push("/all-contents")
                  : history.push("/");
              }}
            >
              <ArrowNarrowLeftIcon className="h-5 w-5 mr-2" />
              Back to {authContext.isAdmin() ? "Contents" : "Home"}
            </FlatButton>
            <div className="absolute top-0 right-0 left-0 bottom-0 overflow-hidden">
              <img
                src={
                  data.thumbnailUrl === ""
                    ? ImageNotFound
                    : server + data.thumbnailUrl
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = ImageNotFound;
                }}
                className=" h-full w-full opacity-50 object-cover z-0 lg:rounded-md rounded-t-md filter blur-md transform scale-125 "
              />
            </div>
            <div className="flex relative z-0 sm:space-x-4 space-x-0 xl:flex-row flex-col gap-y-5">
              <div className="xl:flex-1 flex xl:justify-end justify-center xl:h-96 h-96 relative">
                <div className="sm:10/12 md:w-6/12 xl:w-8/12 w-12/12 h-full shadow-2xl rounded-3xl overflow-hidden">
                  <img
                    src={
                      data.thumbnailUrl === ""
                        ? ImageNotFound
                        : server + data.thumbnailUrl
                    }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = ImageNotFound;
                    }}
                    className="object-cover w-full h-full relative z-10 "
                  />
                </div>
              </div>

              <div className="xl:flex-1 flex xl:justify-start justify-center">
                <div className="flex flex-col sm:w-12/12 lg:w-8/12 md:w-8/12 xl:w-10/12 w-full sm:p-5 p-0 h-full text-white space-y-2  ">
                  <div className="font-bold text-3xl flex items-center">
                    <div className="flex-initial pr-4">{data.name}</div>
                    {authContext.isAuthenticated() ? (
                      authContext.favorites.filter(
                        (c) => c.contentId === data.contentId
                      ).length > 0 ? (
                        <FlatButton
                          className="hidden sm:block text-white ml-auto border transition  hover:bg-red-500  hover:border-red-500"
                          onClick={() => {
                            RemoveFavorite(
                              authContext.favorites.filter(
                                (c) => c.contentId === data.contentId
                              )[0].favoriteId
                            );
                          }}
                        >
                          {!favLoading ? (
                            <div className="flex items-center">
                              Remove from Favorites
                              <HeartIcon className="h-5 w-5 ml-2" />
                            </div>
                          ) : (
                            <div className="flex items-center">
                              <span className="mr-2">
                                Removing from Favorites
                              </span>
                              <Loader
                                type="TailSpin"
                                color="#FFF"
                                height={20}
                                width={20}
                              />
                            </div>
                          )}
                        </FlatButton>
                      ) : (
                        <FlatButton
                          className="hidden sm:block text-white ml-auto border transition  hover:bg-red-500  hover:border-red-500"
                          onClick={() => {
                            FavoriteContent(data.contentId);
                          }}
                        >
                          {!favLoading ? (
                            <div className="flex items-center">
                              Add to Favorites
                              <HeartIcon className="h-5 w-5 ml-2" />
                            </div>
                          ) : (
                            <div className="flex items-center">
                              <span className="mr-2">Adding to Favorites</span>
                              <Loader
                                type="TailSpin"
                                color="#FFF"
                                height={20}
                                width={20}
                              />
                            </div>
                          )}
                        </FlatButton>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="flex items-center">
                    <span className="text-lg font-semibold opacity-70">
                      {data.author === null || data.author === ""
                        ? "Unknown Author"
                        : data.author}
                    </span>
                  </div>

                  <div className="flex flex-col gap-y-1 items-start justify-start text-sm font-semibold">
                    <div className="flex items-center">
                      <RatingToStar rating={data.ratings} size={4} />
                      <span className="ml-2">{data.ratings || 0} out of 5</span>
                    </div>
                    <div className="flex items-center gap-x-2">
                      {data.contentReviews.length || 0} Reviews
                      <ChatAlt2Icon className="h-4 w-4" />
                    </div>
                    <div className="flex items-center gap-x-2">
                      {data.numberOfDownloads} Downloads
                      <DownloadIcon className="h-4 w-4" />
                    </div>
                  </div>
                  <div className="text-sm">
                    <div>
                      {data.publisher === null
                        ? "Unknown Publisher"
                        : data.publisher}
                    </div>
                    <div>
                      {data.publishedDate &&
                        moment(data.publishedDate).format("LL")}
                    </div>
                    <div className="my-1 flex justify-start space-x-1">
                      {data.categories.map((c, key) => {
                        return (
                          <div
                            key={key}
                            className="px-2 py-1 bg-black bg-opacity-10 text-xs rounded-md"
                          >
                            {c.categoryName}
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="flex flex-col mt-2 ">
                    <p className="w-full font-semibold h-28 overflow-elipsis xl:h-auto opacity-80">
                      {data.description}
                    </p>
                  </div>
                  <div className="flex h-full items-end space-x-2">
                    {data &&
                      (data.isEmbeddedMain ? (
                        <FlatButton
                          className="bg-black bg-opacity-30 hover:bg-orange  xl:w-auto w-full xl:justify-start justify-center transition"
                          onClick={() => {
                            history.push(
                              `/content-view-embed/${data.contentId}`
                            );
                          }}
                        >
                          View Embed
                          <BookOpenIcon className="h-5 w-5 ml-2" />
                        </FlatButton>
                      ) : data.files !== null &&
                        mime
                          .lookup(
                            data.files.filter((s) => s.isMain)[0].fileName
                          )
                          .includes("video") ? (
                        <FlatButton
                          className="bg-black bg-opacity-30 hover:bg-orange xl:w-auto w-full xl:justify-start justify-center transition"
                          onClick={() => {
                            history.push(`/content-watch/${data.contentId}`);
                          }}
                        >
                          Watch Now
                          <PlayIcon className="h-5 w-5 ml-2" />
                        </FlatButton>
                      ) : (
                        <FlatButton
                          className="bg-black bg-opacity-30 hover:bg-orange  xl:w-auto w-full xl:justify-start justify-center transition"
                          onClick={() => {
                            history.push(`/content-read/${data.contentId}`);
                          }}
                        >
                          Read Now
                          <BookOpenIcon className="h-5 w-5 ml-2" />
                        </FlatButton>
                      ))}
                    {!data.isViewOnly && !data.isEmbeddedMain && (
                      <a
                        className="flex gap-x-2 xl:w-auto w-full bg-black bg-opacity-30 xl:justify-start justify-center hover:bg-orange transition px-3 py-2 text-sm font-semibold rounded-md"
                        onClick={() => {
                          if (authContext.isAuthenticated())
                            window.location = `${server}/download/${data.contentId}?access_token=${authContext.authState.token}`;
                          else history.push("/signin");
                        }}
                        download
                      >
                        Download <DownloadIcon className="h-5 w-5" />
                      </a>
                    )}
                  </div>

                  {authContext.isAuthenticated() ? (
                    authContext.favorites.filter(
                      (c) => c.contentId === data.contentId
                    ).length > 0 ? (
                      <FlatButton
                        className="sm:hidden justify-center w-full block text-white ml-auto border transition  hover:bg-red-500  hover:border-red-500"
                        onClick={() => {
                          RemoveFavorite(
                            authContext.favorites.filter(
                              (c) => c.contentId === data.contentId
                            )[0].favoriteId
                          );
                        }}
                      >
                        {!favLoading ? (
                          <div className="flex items-center">
                            Remove from Favorites
                            <HeartIcon className="h-5 w-5 ml-2" />
                          </div>
                        ) : (
                          <div className="flex items-center">
                            <span className="mr-2">
                              Removing from Favorites
                            </span>
                            <Loader
                              type="TailSpin"
                              color="#FFF"
                              height={20}
                              width={20}
                            />
                          </div>
                        )}
                      </FlatButton>
                    ) : (
                      <FlatButton
                        className="sm:hidden w-full justify-center block text-white ml-auto border transition  hover:bg-red-500  hover:border-red-500"
                        onClick={() => {
                          FavoriteContent(data.contentId);
                        }}
                      >
                        {!favLoading ? (
                          <div className="flex items-center">
                            Add to Favorites
                            <HeartIcon className="h-5 w-5 ml-2" />
                          </div>
                        ) : (
                          <div className="flex items-center">
                            <span className="mr-2">Adding to Favorites</span>
                            <Loader
                              type="TailSpin"
                              color="#FFF"
                              height={20}
                              width={20}
                            />
                          </div>
                        )}
                      </FlatButton>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="w-full flex justify-center pb-20 md:px-0 px-5">
            <div className="md:w-8/12 lg:10/12 w-full space-y-4">
              <div className="flex items-center ">
                <span className="text-lg font-bold text-Orange">
                  Reviews
                  {/* {data.type === 0 ? "Watcher" : "Reader"} Reviews */}
                </span>
                {authContext.isAuthenticated() ? (
                  <div className="space-x-2 ml-auto flex items-center">
                    <FlatButton
                      className="ml-auto btn-Gray text-gray-600"
                      onClick={() => {
                        setReportModal(true);
                      }}
                    >
                      {/* <ExclamationIcon className="h-5 w-5 mr-2" /> */}
                      <span>Report Content</span>
                    </FlatButton>
                    <FlatButton
                      className={`btn-Orange-primary ${
                        data.contentReviews.filter(
                          (review) =>
                            review.userId ===
                            authContext.authState.userInfo.userId
                        ).length > 0 && "opacity-70 cursor-not-allowed"
                      } ${
                        data.contentReviews.filter(
                          (review) =>
                            review.userId ===
                            authContext.authState.userInfo.userId
                        ).length > 0
                          ? "hidden"
                          : "block"
                      }`}
                      disabled={
                        data.contentReviews.filter(
                          (review) =>
                            review.userId ===
                            authContext.authState.userInfo.userId
                        ).length > 0
                      }
                      onClick={() => {
                        setWriteReviewModal(true);
                      }}
                    >
                      {/* <PencilIcon className="h-5 w-5 mr-2" /> */}
                      <span>Write a Review</span>
                    </FlatButton>
                  </div>
                ) : (
                  <div className="space-x-2 ml-auto flex items-center">
                    <FlatButton
                      className="btn-Orange-primary"
                      onClick={() => {
                        history.push("/signin");
                      }}
                    >
                      Sign in to submit your review
                    </FlatButton>
                  </div>
                )}
              </div>

              {/* {data.contentReviews.length > 0 && (
                <div>
                  <Menu as="div" className="relative">
                    <div>
                      <Menu.Button className="flex items-center text-sm px-3 rounded-md py-2 bg-gray-200 ">
                        Most Recent
                        <ChevronDownIcon
                          className="w-5 h-5 ml-2 -mr-1 "
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute left-0 w-40 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-1 py-1 ">
                          <Menu.Item>
                            <MenuItemButton text="My Favorites" />
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              )} */}

              {data.contentReviews.length > 0 ? (
                <div className="space-y-2">
                  {data.contentReviews.map((review, key) => {
                    return <ContentReview key={key} review={review} />;
                  })}
                </div>
              ) : (
                <div className="flex justify-center w-full py-10 text-2xl font-bold text-gray-400">
                  This content has no reviews yet.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
};

export default ContentView;
