import React, { useState, useContext, useEffect } from "react";
import Textbox from "../../Inputs/Textbox";
import { DocumentDownloadIcon } from "@heroicons/react/outline";
import { FlatButton } from "../../Buttons/Buttons";
import Select from "../../Inputs/Select";
import { ReportsContext } from "../../../context/ReportsContext";
import DatePicker from "../../Inputs/DatePicker";

const Filters = ({ ExportFileFunction }) => {
  const context = useContext(ReportsContext);

  return (
    <div className="space-y-7">
      <div className="flex gap-x-5 items-end">
        <div className="">
          <Textbox
            label="Content Name"
            value={context.contentName}
            onChange={(e) => {
              context.setContentName(e.target.value);
            }}
          />
        </div>
        <div className="">
          <Textbox
            value={context.author}
            label="Author"
            onChange={(e) => {
              context.setAuthor(e.target.value);
            }}
          />
        </div>
        {context.table === "AllRequests" && (
          <div className="">
            <Select
              value={context.status}
              label="Status"
              onChange={(e) => {
                context.setStatus(e.target.value);
              }}
            >
              <option>Active</option>
              <option>Inactive</option>
              <option>Pending</option>
              <option>Declined</option>
            </Select>
          </div>
        )}
        <div className="">
          <DatePicker
            value={context.dateRequestedFrom}
            label="Date Requested (From)"
            onChange={(e) => {
              context.setDateRequestedFrom(e.target.value);
            }}
          />
        </div>
        <div className="">
          <DatePicker
            value={context.dateRequestedTo}
            label="Date Requested (To)"
            onChange={(e) => {
              context.setDateRequestedTo(e.target.value);
            }}
          />
        </div>
        <div className="flex-1 flex gap-x-4">
          <FlatButton
            className="w-full justify-center btn-CSIBlue"
            onClick={() => {
              context.ApplyFilters();
            }}
          >
            Search
          </FlatButton>
          <FlatButton
            className="w-full justify-center btn-Gray"
            onClick={() => {
              context.ResetFilters();
            }}
          >
            Reset
          </FlatButton>
        </div>
      </div>

      <div className="flex">
        <div className="flex items-center space-x-2">
          <span>Show</span>
          <div className="w-16">
            <Select
              value={context.entries}
              onChange={(e) => {
                context.setEntries(e.target.value);
                context.setPage(1);
              }}
            >
              {[...Array(6)].map((x, i) => {
                return <option key={i}>{i + 5}</option>;
              })}
            </Select>
          </div>
          <span>Entries</span>
        </div>

        <div className="ml-auto flex space-x-3">
          <FlatButton
            className="bg-green-400 hover:bg-green-500 text-white px-5 ml-3 space-x-2"
            onClick={ExportFileFunction}
          >
            <span>Export File </span>
            <DocumentDownloadIcon className="h-5 w-5" />
          </FlatButton>
        </div>
      </div>
    </div>
  );
};

export default Filters;
