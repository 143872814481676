import React, { createContext, useState, useEffect } from "react";
import { deleteCategory, editCategory, postCategory } from "../services/admin";
import { useToasts } from "react-toast-notifications";
import { useQueryClient } from "react-query";

const CategoriesContext = createContext();
const { Provider } = CategoriesContext;

const CategoriesProvider = ({ children }) => {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  const [filters, setFilters] = useState({
    categoryName: "",
  });

  const [categoryName, setCategoryName] = useState("");

  const ApplyFilters = () => {
    setFilters({ categoryName });
    setPage(1);
  };
  const ResetFilters = () => {
    setFilters({ categoryName: "" });
    setCategoryName("");
  };

  const [entries, setEntries] = useState(10);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [sortBy, setSortBy] = useState("idAsc");
  const [count, setCount] = useState(0);
  const [newCategory, setNewCategory] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [edit, setEdit] = useState({
    id: "",
    state: false,
  });

  const Delete = (id) => {
    setSaveLoading(true);

    deleteCategory(id)
      .then((res) => {
        setSaveLoading(false);
        addToast("Category Deleted: " + id, {
          appearance: "success",
        });
        queryClient.invalidateQueries("categories");
      })
      .catch((e) => {
        setSaveLoading(false);
        addToast("Error Saving Category", { appearance: "error" });
      });
  };

  const Save = (data) => {
    setSaveLoading(true);
    editCategory(data)
      .then((res) => {
        setEdit({ id: "", state: false });
        setSaveLoading(false);
        addToast("Category Saved: " + data.categoryName, {
          appearance: "success",
        });
        queryClient.invalidateQueries("categories");
      })
      .catch((e) => {
        setEdit({ id: "", state: false });
        setSaveLoading(false);
        addToast("Error Saving Category", { appearance: "error" });
      });
  };

  const SaveNewCategory = (newCategory) => {
    const data = { categoryName: newCategory };
    setSaveLoading(true);
    postCategory(data)
      .then((res) => {
        if (res.status === 200) {
          setSaveLoading(false);
          addToast("Added New Category: " + newCategory, {
            appearance: "success",
          });
          queryClient.invalidateQueries("categories");
        } else {
          addToast("Error Saving New Category", { appearance: "error" });
        }
        setNewCategory(false);
      })
      .catch((e) => {
        // console.log(e);
        setSaveLoading(false);
        setNewCategory(false);
        addToast("Error Saving New Category", { appearance: "error" });
      });
  };

  return (
    <Provider
      value={{
        count,
        setCount,
        sortBy,
        setSortBy,
        saveLoading,
        filters,
        newCategory,
        entries,
        page,
        pageCount,
        categoryName,
        edit,
        setEdit,
        ApplyFilters,
        ResetFilters,
        setCategoryName,
        setEntries,
        setPage,
        setPageCount,
        setFilters,
        Delete,
        Save,
        setNewCategory,
        SaveNewCategory,
      }}
    >
      {children}
    </Provider>
  );
};

export { CategoriesContext, CategoriesProvider };
