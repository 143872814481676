import React from "react";
import { DefaultToast } from "react-toast-notifications";

const CustomToast = ({ children, description, ...props }) => {
  return (
    <DefaultToast {...props}>
      <div className="flex items-center p-3 font-bold ">
        {children}
        <div className="font-normal">{description}</div>
      </div>
    </DefaultToast>
  );
};

export default CustomToast;
