import React, { useContext, useState } from "react";
import ContentSkeleton from "../Others/ContentSkeleton";
import { ClipboardListIcon, EmojiSadIcon } from "@heroicons/react/outline";
import DisplayItem from "../Others/DisplayItem";
import { useQuery, useInfiniteQuery } from "react-query";
import { getAllContents } from "../../services/contents";
import ContentError from "../Others/ContentError";
import { FilterContext } from "../../context/FilterContext";
import ContentNotFound from "../Others/ContentNotFound";
import InfiniteScroll from "react-infinite-scroll-component";

const AllContents = () => {
  const filterContext = useContext(FilterContext);
  const [page, setPage] = useState(1);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isError,
    error,
  } = useInfiniteQuery(
    ["allContents", filterContext.filterState],
    ({ pageParam = page, queryKey }) => getAllContents(queryKey, pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length < 10) return undefined;
        else return allPages.length + 1;
      },
    }
  );

  if (isError) {
    return <ContentError />;
  }

  if (isLoading) {
    return <ContentSkeleton />;
  }

  if (data) {
    if (data.length === 0) {
      return <ContentNotFound />;
    }
  }

  // console.log(data);

  // console.log(size);

  return (
    <>
      <InfiniteScroll
        next={fetchNextPage}
        hasMore={hasNextPage}
        dataLength={data.pages.length}
        scrollableTarget="content-scroll"
        loader={<ContentSkeleton />}
        endMessage={
          <p style={{ textAlign: "center" }} className="py-20 text-gray-400">
            <b>Nothing Follows</b>
          </p>
        }
      >
        <div className="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-1 grid-cols-1 2xl:grid-cols-4 px-0 gap-x-7 gap-y-9 relative w-full ">
          {data.pages.map((pageData) => {
            return pageData.map((item, key) => {
              return <DisplayItem item={item} key={key} />;
            });
          })}
        </div>
      </InfiniteScroll>
    </>
  );
};

export default AllContents;
