import React, { useState, useContext, useEffect } from "react";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ExclamationIcon,
  PencilAltIcon,
  TrashIcon,
  XIcon,
} from "@heroicons/react/outline";
import { useQuery } from "react-query";
import { getCategories } from "../../../services/admin";
import { FlatButton } from "../../Buttons/Buttons";
import { CategoriesContext } from "../../../context/CategoriesContext";
import Textbox from "../../Inputs/Textbox";
import ContentError from "../../Others/ContentError";
import Loading from "../../Others/Loading";
import DataTable from "../../Base/DataTable";
import Modal from "../../Others/Modal";

const Table = () => {
  const context = useContext(CategoriesContext);
  const filters = context.filters;
  const page = context.page;
  const entries = context.entries;
  const sortBy = context.sortBy;

  const { data, isError, isLoading } = useQuery(
    ["categories", { filters, entries, page, sortBy }],
    getCategories
  );

  // console.log(data);

  const [categoryName, setCategoryName] = useState("");
  const [newCategoryName, setNewCategoryName] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [headers, setHeaders] = useState([]);

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  useEffect(() => {
    setNewCategoryName("");
  }, [context.saveLoading]);

  useEffect(() => {
    if (width > 639) {
      setHeaders([
        // {
        //   text: "ID",
        //   className: "px-6 py-4 cursor-pointer",
        //   onClick: () => SortBy("id"),
        //   component:
        //     context.sortBy === "idAsc"
        //       ? ChevronUpIcon
        //       : context.sortBy === "idDesc"
        //       ? ChevronDownIcon
        //       : "",
        // },
        {
          text: "Name",
          className: "text-left px-6 py-4  cursor-pointer",
          onClick: () => SortBy("name"),
          component:
            context.sortBy === "nameAsc"
              ? ChevronUpIcon
              : context.sortBy === "nameDesc"
              ? ChevronDownIcon
              : "",
        },
        {
          text: "Number of Files",
          className: "cursor-pointer",
          textAlign: "center",
          onClick: () => SortBy("files"),
          component:
            context.sortBy === "filesAsc"
              ? ChevronUpIcon
              : context.sortBy === "filesDesc"
              ? ChevronDownIcon
              : "",
        },
        { text: "Actions", className: "", textAlign: "center" },
      ]);
    } else {
      setHeaders([
        {
          text: "Name",
          className: "text-left cursor-pointer py-4 px-6",
          onClick: () => SortBy("name"),
          component:
            context.sortBy === "nameAsc"
              ? ChevronUpIcon
              : context.sortBy === "nameDesc"
              ? ChevronDownIcon
              : "",
        },
        { text: "Actions", className: "", textAlign: "center" },
      ]);
    }
  }, [width, context.sortBy]);

  const SortBy = (type) => {
    const typeAsc = type + "Asc";
    const typeDesc = type + "Desc";
    context.sortBy === typeDesc
      ? context.setSortBy(typeAsc)
      : context.setSortBy(typeDesc);
  };

  // "idAsc" => query.OrderBy(u => u.Id),
  // "idDesc" => query.OrderByDescending(u => u.Id),
  // "nameAsc" => query.OrderBy(u => u.Name),
  // "nameDesc" => query.OrderByDescending(u => u.Name),
  // "filesAsc" => query.OrderBy(u => u.Contents.Count),
  // "filesDesc" => query.OrderByDescending(u => u.Contents.Count),
  // _ => query.OrderBy(u => u.Name)

  const Edit = (id, name) => {
    context.setEdit({ id, state: true });
    setCategoryName(name);
  };

  const Cancel = () => {
    context.setEdit({ id: "", state: false });
  };

  useEffect(() => {
    if (data) {
      context.setPageCount(data.pages);
      context.setCount(data.length);
    }
  }, [data]);

  useEffect(() => {
    Cancel();
  }, [context.newCategory]);

  if (isLoading) {
    return (
      <div className="animate-pulse">
        <div className="w-full h-96 bg-white shadow-xl rounded-3xl p-5 space-y-6 overflow-hidden relative flex justify-center">
          <Loading loading={true} />
        </div>
      </div>
    );
  }

  if (isError) {
    return <ContentError />;
  }

  return (
    <DataTable
      headers={headers}
      data={data.data}
      noDataText="Category Not Found."
    >
      <Modal
        isOpen={deleteModal}
        onClose={() => {
          setDeleteModal(false);
        }}
        title="Remove Category"
        description="Are you sure you want to remove this category?"
        className="relative"
      >
        <div className="absolute inline-block top-3 z-100 bg-red-500 p-2 text-white rounded-full right-3 ">
          <ExclamationIcon className="h-7 w-7" />
        </div>
        <div className="flex items-center gap-x-4 float-right">
          <FlatButton
            className="btn-Red "
            onClick={() => {
              context.Delete(deleteId);
              setDeleteModal(false);
            }}
          >
            Delete
          </FlatButton>
          <FlatButton
            className="btn-Gray"
            onClick={() => {
              setDeleteModal(false);
            }}
          >
            Cancel
          </FlatButton>
        </div>
      </Modal>
      {context.newCategory && (
        <tr>
          {/* <td className="py-2 sm:table-cell hidden"></td> */}
          <td className="flex-auto px-6 justify-center sm:w-6/12 w-full sm:px-0 px-3">
            <Textbox
              value={newCategoryName}
              placeholder="Enter Category Name..."
              onChange={(e) => {
                setNewCategoryName(e.target.value);
              }}
            />
          </td>
          <td className="sm:table-cell hidden"></td>
          <td className="py-3 px-2">
            <div className="flex justify-center sm:flex-row flex-col gap-x-2 gap-y-2 ">
              <FlatButton
                className="bg-Orange hover:bg-opacity-70 justify-center hover:bg-Orange text-white gap-x-2 px-4"
                onClick={() => {
                  if (newCategoryName !== "")
                    context.SaveNewCategory(newCategoryName);
                }}
              >
                <span className="text-xs">Save</span>
                <CheckIcon className="h-4 w-4" />
              </FlatButton>
              <FlatButton
                className="bg-gray-200 hover:bg-gray-300 gap-x-2 px-4"
                onClick={() => {
                  context.setNewCategory(false);
                }}
              >
                <span className="text-xs">Cancel</span>
                <XIcon className="h-4 w-4" />
              </FlatButton>
            </div>
          </td>
        </tr>
      )}
      {data.data.map((c, key) => {
        return (
          <tr
            className="text-center text-sm text-gray-600 hover:bg-gray-100"
            key={key}
          >
            {/* <td className="sm:table-cell hidden">{c.categoryId}</td> */}
            <td className="text-left sm:w-6/12 w-full font-bold px-6">
              {context.edit.state && context.edit.id === c.categoryId ? (
                <Textbox
                  value={categoryName}
                  onChange={(e) => {
                    setCategoryName(e.target.value);
                  }}
                />
              ) : (
                c.categoryName
              )}
            </td>
            <td className="p-2 flex-auto w-full sm:table-cell hidden">
              {c.numberOfFiles}
            </td>
            <td className="py-3 px-2">
              {context.edit.state && context.edit.id === c.categoryId ? (
                <div className="flex justify-center sm:flex-row flex-col gap-x-3 gap-y-2 ">
                  <FlatButton
                    className="bg-Orange hover:bg-opacity-70 hover:bg-Orange text-white gap-x-2 px-4"
                    onClick={() => {
                      context.Save({ categoryId: c.categoryId, categoryName });
                    }}
                  >
                    <span className="text-xs">Save</span>
                    <CheckIcon className="h-4 w-4" />
                  </FlatButton>
                  <FlatButton
                    className="bg-gray-200 hover:bg-gray-300 gap-x-2 px-4"
                    onClick={() => Cancel()}
                  >
                    <span className="text-xs">Cancel</span>
                    <XIcon className="h-4 w-4" />
                  </FlatButton>
                </div>
              ) : (
                <div className="flex justify-center sm:flex-row flex-col gap-x-3 gap-y-2 ">
                  <FlatButton
                    className={`bg-light-blue text-blue gap-x-2 px-4 ${
                      context.newCategory && "cursor-not-allowed opacity-40"
                    }`}
                    onClick={() => Edit(c.categoryId, c.categoryName)}
                    disabled={context.newCategory}
                  >
                    <span className="text-xs">Edit</span>
                    <PencilAltIcon className="h-4 w-4" />
                  </FlatButton>
                  <FlatButton
                    className={`bg-red-600 text-white hover:bg-red-600 hover:bg-opacity-50 flex gap-x-2 ${
                      context.newCategory && "cursor-not-allowed opacity-40"
                    }`}
                    onClick={() => {
                      setDeleteModal(true);
                      setDeleteId(c.categoryId);
                    }}
                    disabled={context.newCategory}
                  >
                    <span className="text-xs">Delete</span>
                    <TrashIcon className="h-4 w-4" />
                  </FlatButton>
                </div>
              )}
            </td>
          </tr>
        );
      })}
    </DataTable>
  );
};

export default Table;
