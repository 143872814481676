import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useQueryClient } from "react-query";
import Modal from "./Modal";
import TextArea from "../Inputs/TextArea";
import { FlatButton } from "../Buttons/Buttons";
import { reportContent } from "../../services/contents";
import { AuthContext } from "../../context/AuthContext";

const ReportModal = ({ isOpen, onClose, setLoading }) => {
  const [remarks, setRemarks] = useState("");
  const [reportType, setReportType] = useState([]);
  let { contentId } = useParams();
  const { addToast } = useToasts();
  const authContext = useContext(AuthContext);

  const reportTypes = [
    { id: "pornograpy", text: "Pornography or sexual explicit material" },
    { id: "unwanted", text: "Unwanted content or spam" },
    { id: "harrasment", text: "Harrasment or bullying" },
    { id: "copyrighted", text: "Copyrighted material" },
  ];

  const SubmitReport = () => {
    setLoading(true);
    const data = {
      contentId,
      userName: authContext.profile.userName,
      message: remarks,
      reportType: reportTypes.filter((type) => type.id === reportType)[0].text,
    };

    reportContent(data)
      .then((res) => {
        // console.log(res);
        addToast("Report Submitted", { appearance: "success" });
        setLoading(false);
      })
      .catch((e) => {
        // console.log(e);
        addToast("Error Submitting Report", { appearance: "error" });
        setLoading(false);
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Report Content"
      description="Help us understand the problem."
    >
      <div className="space-y-2 mb-5">
        <div className="">
          {reportTypes.map((type, key) => {
            return (
              <label
                key={key}
                htmlFor={type.id}
                className="text-sm gap-x-2 flex items-center cursor-pointer"
              >
                {reportType === type.id ? (
                  <i className="bx text-Orange bx-checkbox-square bx-sm  px-0 m-0"></i>
                ) : (
                  <i className="bx text-gray-300 bx-checkbox bx-sm px-0 m-0"></i>
                )}
                <input
                  id={type.id}
                  type="radio"
                  name="test"
                  className="hidden"
                  onChange={() => {
                    setReportType(type.id);
                  }}
                />
                <div>{type.text}</div>
              </label>
            );
          })}
        </div>

        <TextArea
          label="Remarks"
          value={remarks}
          onChange={(e) => {
            setRemarks(e.target.value);
          }}
          placeholder="Write Something ..."
        />
      </div>
      <div className="float-right flex gap-x-2">
        <FlatButton
          className="btn-Gray"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </FlatButton>
        <FlatButton
          className="btn-Orange-primary"
          onClick={() => {
            SubmitReport();
            onClose();
          }}
        >
          Submit Report
        </FlatButton>
      </div>
    </Modal>
  );
};
export default ReportModal;
