import React from "react";
import { ReportsProvider } from "../context/ReportsContext";
import Content from "../components/Admin/Reports/Content";
import AdminSidebar from "../components/Base/AdminSidebar";

const ReportedIssues = () => {
  return (
    <div className="h-full ">
      <div className="flex h-full w-full">
        <AdminSidebar />
        <ReportsProvider>
          <Content />
        </ReportsProvider>
      </div>
    </div>
  );
};

export default ReportedIssues;
