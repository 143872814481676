import React, { useContext, useState, useEffect } from "react";
import { EditContentContext } from "../context/EditContentContext";
import Stepper from "../components/Others/Stepper";
import Details from "../components/Contents/Forms/Details";
import AddFile from "../components/Contents/Forms/AddFile";
import Summary from "../components/Contents/Forms/Summary";
import { getContent } from "../services/contents";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { FlatButton } from "../components/Buttons/Buttons";
import { ArrowNarrowLeftIcon, TrashIcon } from "@heroicons/react/outline";
import Modal from "../components/Others/Modal";
import LoadingNotRounded from "../components/Others/LoadingNotRounded";
import ContentError from "../components/Others/ContentError";

const Content = ({ isLoading, isError, context }) => {
  if (isLoading) {
    return (
      <div className="animate-pulse flex flex-col h-full w-full space-y-4 justify-center">
        <div className="bg-gray-300 w-full h-20 rounded-xl"></div>
        <div className=" w-full flex w-full gap-x-7 flex-auto rounded-3xl shadow-xl bg-white p-10">
          <div className="flex-1  space-y-7 ">
            <div className="space-y-3">
              <div className="h-5 bg-gray-300 w-3/12 rounded-md"></div>
              <div className="bg-gray-300 h-10 w-full rounded-xl"></div>
            </div>
            <div className="space-y-3">
              <div className="h-5 bg-gray-300 w-5/12 rounded-md"></div>
              <div className="bg-gray-300 h-10 w-full rounded-xl"></div>
            </div>
            <div className="space-y-3">
              <div className="h-5 bg-gray-300 w-8/12 rounded-md"></div>
              <div className="bg-gray-300 h-10 w-full rounded-xl"></div>
            </div>
            <div className="space-y-3">
              <div className="h-5 bg-gray-300 w-3/12 rounded-md"></div>
              <div className="bg-gray-300 h-10 w-full rounded-xl"></div>
            </div>
          </div>
          <div className="flex-1 space-y-4 ">
            <div className="space-y-3">
              <div className="h-5 bg-gray-300 w-6/12 rounded-md"></div>
              <div className="bg-gray-300 h-10 w-full rounded-xl"></div>
            </div>
            <div className="space-y-3">
              <div className="h-5 bg-gray-300 w-2/12 rounded-md"></div>
              <div className="bg-gray-300 h-10 w-full rounded-xl"></div>
            </div>
            <div className="h-48 w-full bg-gray-300 rounded-xl"></div>
          </div>
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <div>
        <ContentError />
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <Stepper context={context} />
      {context.step === 1 && <Details context={context} />}
      {context.step === 2 && <AddFile context={context} />}
      {context.step === 3 && <Summary context={context} />}
    </div>
  );
};

const MyContentManage = () => {
  const history = useHistory();
  const [modal, setModal] = useState(false);

  const context = useContext(EditContentContext);

  let { contentId } = useParams();
  const { isFetching, isError } = useQuery(
    ["edit-content", contentId],
    getContent,
    {
      refetchOnWindowFocus: false,
      onSuccess: ({
        name,
        description,
        author,
        publisher,
        publishedDate,
        releaseDate,
        thumbnailUrl,
        files,
        categories,
        keywords,
        contentType,
        isEmbeddedMain,
        embededUrl,
      }) => {
        keywords !== null && context.setKeywords(keywords.split(";"));
        context.setContentId(contentId);
        context.setTitle(name);
        context.setDescription(description);
        context.setAuthor(author);
        context.setPublisher(publisher);
        context.setPublishedDate(publishedDate);
        context.setReleaseDate(releaseDate);
        context.setExistingFiles(files);
        context.setCategories(categories);
        context.setContentType(contentType);
        context.setThumbnailUrl(thumbnailUrl);
        context.setIsEmbeddedMain(isEmbeddedMain);
        context.setEmbed(embededUrl);
      },
    }
  );
  // console.warn(context.isEmbeddedMain);
  return (
    <div className="flex h-full w-full justify-center overflow-auto">
      {context.deleteLoading && <LoadingNotRounded />}
      <Modal
        isOpen={modal}
        title="Delete Content"
        description="Are you sure you want to delete this content?"
        onClose={() => {
          setModal(false);
        }}
      >
        <div className="flex justify-end space-x-2">
          <FlatButton
            onClick={() => {
              setModal(false);
            }}
            className="btn-Gray"
          >
            Cancel
          </FlatButton>
          <FlatButton
            className="btn-Red"
            onClick={() => {
              context.DeleteContent();
            }}
          >
            Delete
          </FlatButton>
        </div>
      </Modal>
      <div className="sm:p-10 p-5 py-10 xl:w-8/12 w-full flex flex-col h-full space-y-4 mb-20">
        <div className="w-full flex sm:flex-row gap-y-4 flex-col items-start text-2xl font-bold text-gray-600 mb-5 ">
          <span className="text-dark-blue uppercase">Edit Content</span>

          <div className="flex sm:ml-auto ml-0 space-x-2 sm:w-auto w-full">
            <FlatButton
              className="btn-Gray w-full gap-x-2 justify-center"
              onClick={() => {
                history.push("/my-contents");
                context.Reset();
              }}
            >
              Cancel Editing Content
            </FlatButton>
            <FlatButton
              className=" btn-Red gap-x-2 w-full justify-center"
              onClick={() => {
                setModal(true);
              }}
            >
              Delete Content <TrashIcon className="h-5 w-5" />
            </FlatButton>
          </div>
        </div>
        <div>
          <Content isLoading={isFetching} isError={isError} context={context} />
        </div>
      </div>
    </div>
  );
};

// const StatusIndicator = ({ bg, children }) => {
//   return (
//     <div
//       className={`rounded-full w-5 h-5 flex items-center justify-center text-white bg-${bg}-600`}
//     >
//       {children}
//     </div>
//   );
// };

// const StatusBadge = ({ status, color, component: Component }) => {
//   return (
//     <div
//       className={`flex items-center gap-x-2 bg-${color}-200 p-2 rounded-full `}
//     >
//       <span className={`font-semibold text-sm text-${color}-600`}>
//         {status}
//       </span>
//       <StatusIndicator bg={color}>
//         <Component className="h-4 w-4" />
//       </StatusIndicator>
//     </div>
//   );
// };

// const DeleteModal = ({ isOpen, onClose, onClick }) => {
//   return (
//     <Modal
//       isOpen={isOpen}
//       onClose={onClose}
//       title="Delete Content"
//       description="Are you sure you want to delete this content?"
//     >
//       <div className="flex">
//         <FlatButton
//           className="bg-yellow-500 hover:bg-yellow-600 text-white"
//           onClick={() => {
//             //   context.DeclineContent(contentId);
//             onClose();
//           }}
//         >
//           Decline Report
//         </FlatButton>
//         <FlatButton className="btn-Gray" onClick={onClose}>
//           Cancel
//         </FlatButton>
//       </div>
//     </Modal>
//   );
// };

// const ViewModal = ({ path, isOpen, onClose, contentId }) => {
//   var type = mime.lookup(devServer + path);
//   type = type !== false ? type.split("/")[0] : "";
//   const baseUrl = devServer + "/api/Content/get-video/";
//   return (
//     <ContentModal
//       isOpen={isOpen}
//       onClose={onClose}
//       className="max-w-full min-h-full my-20"
//     >
//       <div className="overflow-auto h-full w-full py-0 relative">
//         {type === "application" ? (
//           <DocsReader path={path} />
//         ) : type === "video" ? (
//           <div
//             className="relative rounded-lg bg-black bg-opacity-90 flex justify-center "
//             style={{ height: "700px" }}
//           >
//             <video
//               controls
//               className="absolute top-0 h-full object-fit outline-none"
//             >
//               <source src={baseUrl + contentId} />
//             </video>
//           </div>
//         ) : (
//           ""
//         )}
//       </div>
//     </ContentModal>
//   );
// };

// const MyContentManage = () => {
//   const authContext = useContext(AuthContext);
//   const history = useHistory();
//   let { contentId } = useParams();
//   const { data, isLoading, isError } = useQuery(
//     ["content", contentId],
//     viewContent
//   );
//   const [modal, setModal] = useState(false);
//   const [path, setPath] = useState("");

//   if (isLoading) {
//     return <div></div>;
//   }

//   if (isError) {
//     return <div></div>;
//   }

//   if (data.getContent.userId !== authContext.authState.userInfo.userId)
//     history.push("/");

//   return (
//     <div className="h-full w-full overflow-auto">
//       <ViewModal
//         path={path}
//         isOpen={modal}
//         onClose={() => {
//           setModal(false);
//         }}
//         contentId={data.contentId}
//       />
//       <div className="space-y-6 overflow-auto h-full">
//         <div className="p-20 bg-black bg-opacity-80 relative shadow-xl group">
//           <FlatButton
//             className="absolute top-5 left-5 z-40 transition text-white hover:bg-opacity-20"
//             onClick={() => {
//               history.push("/my-contents");
//             }}
//           >
//             <ArrowNarrowLeftIcon className="h-5 w-5 mr-2" />
//             Back to My Contents
//           </FlatButton>
//           <div className="absolute top-0 right-0 left-0 bottom-0 overflow-hidden">
//             <img
//               src={
//                 data.getContent.thumbnailUrl === ""
//                   ? "https://getuikit.com/v2/docs/images/placeholder_600x400.svg"
//                   : process.env.REACT_APP_DEV_SERVER +
//                     data.getContent.thumbnailUrl
//               }
//               className=" h-full w-full opacity-50 object-cover z-0 lg:rounded-md rounded-t-md filter blur-md transform scale-125 "
//             />
//           </div>
//           <div className="flex relative z-0 space-x-4">
//             <div className="flex-1 flex justify-end h-96 relative">
//               <div className="w-8/12 h-full shadow-2xl rounded-3xl overflow-hidden">
//                 <img
//                   src={
//                     data.getContent.thumbnailUrl === ""
//                       ? "https://getuikit.com/v2/docs/images/placeholder_600x400.svg"
//                       : process.env.REACT_APP_DEV_SERVER +
//                         data.getContent.thumbnailUrl
//                   }
//                   className="object-cover w-full h-full relative z-10 "
//                 />
//               </div>
//             </div>

//             <div className="flex-1 ">
//               <div className="flex flex-col w-8/12 p-5 h-full text-white space-y-2  ">
//                 <div className="font-bold text-3xl flex items-center">
//                   <div className="flex-initial">{data.getContent.name}</div>
//                   <div className="ml-auto text-sm">
//                     {data.getContent.status === "Approved" && (
//                       <StatusBadge
//                         status={data.getContent.status}
//                         color="green"
//                         component={CheckIcon}
//                       />
//                     )}
//                     {data.getContent.status === "Pending" && (
//                       <StatusBadge
//                         status={data.getContent.status}
//                         color="yellow"
//                         component={ClockIcon}
//                       />
//                     )}
//                     {data.getContent.status === "Declined" && (
//                       <StatusBadge
//                         status={data.getContent.status}
//                         color="red"
//                         component={BanIcon}
//                       />
//                     )}
//                     {data.getContent.status === "Disabled" && (
//                       <StatusBadge
//                         status={data.getContent.status}
//                         color="gray"
//                         component={BanIcon}
//                       />
//                     )}
//                   </div>
//                 </div>
//                 <div className="flex items-center">
//                   <span className="text-lg font-semibold opacity-70">
//                     {data.getContent.authorName === null ||
//                     data.getContent.authorName === ""
//                       ? "Unknown Author"
//                       : data.getContent.authorName}
//                   </span>
//                 </div>

//                 <div className="flex items-center text-xs">
//                   <RatingToStar rating={data.getContent.ratings} size={4} />
//                   <span className="ml-2">
//                     {data.getContent.numberOfDownloads}
//                   </span>
//                 </div>
//                 <div className="text-sm">
//                   <div>
//                     {data.getContent.publisher === null
//                       ? "Unknown Publisher"
//                       : data.publisher}
//                   </div>
//                   <div>
//                     {moment(data.getContent.publishedDate).format("LL")}
//                   </div>
//                   <div>Religious</div>
//                 </div>

//                 <div className="flex flex-col mt-2 ">
//                   <p className="w-full font-semibold h-96 overflow-elipsis lg:h-auto opacity-80">
//                     {data.getContent.description}
//                   </p>
//                 </div>
//                 <div className="flex h-full items-end justify-end">
//                   <FlatButton
//                     className="bg-black bg-opacity-30 hover:bg-red-500 flex items-center gap-x-2 transition"
//                     onClick={() => {}}
//                   >
//                     Delete Content
//                     <TrashIcon className="h-5 w-5" />
//                   </FlatButton>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="w-full flex justify-center">
//           <div className="w-8/12 space-y-4">
//             <div className="font-bold text-xl text-Orange mx-4">Files</div>
//             <div className="py-6 text-sm rounded-3xl bg-white shadow-xl flex flex-col text-gray-600">
//               {data.files.map((file, i) => {
//                 return (
//                   <div
//                     key={file.fileName}
//                     className="group flex items-center pl-4 w-full hover:bg-gray-50 py-3 px-10"
//                   >
//                     <div className=" flex gap-x-4 font-semibold px-10">
//                       <div className="font-bold">{i + 1}.</div>
//                       <div>{file.fileName}</div>
//                     </div>
//                     <div className="ml-auto flex items-center space-x-2 opacity-0 group-hover:opacity-100 transition">
//                       <button
//                         className="gap-x-2 flex text-xs font-semibold border text-gray-500 hover:bg-gray-100 px-3 py-2 rounded-md"
//                         onClick={() => {
//                           setModal(true);
//                           setPath(file.filePath + file.fileName);
//                         }}
//                       >
//                         View File
//                       </button>
//                       <button
//                         className="gap-x-2 flex text-xs border font-semibold text-gray-500 hover:bg-red-200 hover:text-red-600 hover:border-red-200 px-3 py-2 rounded-md"
//                         onClick={() => {
//                           setModal(true);
//                           setPath(file.filePath + file.fileName);
//                         }}
//                       >
//                         Delete File
//                       </button>
//                     </div>
//                   </div>
//                 );
//               })}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

export default MyContentManage;
