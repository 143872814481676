import React, { useContext } from "react";
import { ContentsContext } from "../../../context/ContentsContext";
import Pagination from "../../Others/Pagination";
import Filters from "./Filters";
import Table from "./Table";
import Tabs from "./Tabs";

const Content = () => {
  const context = useContext(ContentsContext);

  return (
    <div className="z-0 lg:p-10 mb-20 p-4 w-full overflow-auto space-y-7">
      <div className="flex items-center w-full text-2xl font-bold text-dark-blue uppercase mb-5 space-x-4">
        <span>Contents</span>
      </div>
      <Filters />
      <Tabs />
      <div className="space-y-4">
        <Table />
        <Pagination context={context} className="float-right" />
      </div>
    </div>
  );
};

export default Content;
