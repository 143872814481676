import { authAxios } from "./interceptor";

var env = process.env.NODE_ENV || "development";
const baseUrl = `${
  env === "development"
    ? process.env.REACT_APP_DEV_SERVER
    : process.env.REACT_APP_PROD_SERVER
}/users`;

export const myProfile = () => {
  return authAxios.get(`${baseUrl}/my-profile`).then((res) => {
    return res.data;
  });
};

export const changePassword = (data) => {
  return authAxios.put(`${baseUrl}/change-password`, data).then((res) => {
    return res;
  });
};

export const updateProfile = (data) => {
  return authAxios.put(`${baseUrl}/update-profile`, data).then((res) => {
    return res;
  });
};

export const updateUserRole = (userId, data) => {
  return authAxios
    .put(`${baseUrl}/update-user-role/${userId}`, data)
    .then((res) => {
      return res;
    });
};
