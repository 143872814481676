import React, { useEffect, useMemo, useState, useContext } from "react";
import DocViewer, { DocViewerRenderers, MSDocRenderer } from "react-doc-viewer";
import { FlatButton } from "../Buttons/Buttons";
import { useQuery } from "react-query";
import { getContent } from "../../services/contents";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import Loader from "react-loader-spinner";
import { EmojiSadIcon } from "@heroicons/react/outline";
// import Docu from "../../assets/ADDITIONAL BOOK READING LEGAL ASPECTS OF SG.docx";

var env = process.env.NODE_ENV || "development";
const server = `${
  env === "development"
    ? process.env.REACT_APP_DEV_SERVER
    : process.env.REACT_APP_PROD_SERVER
}`;

const DocsReader = ({ path, contentId }) => {
  const fullscreen = () => {
    const docsViewer = document.querySelector("#docsViewer");
    if (docsViewer.requestFullscreen) {
      docsViewer.requestFullscreen();
    } else if (docsViewer.webkitRequestFullscreen) {
      /* Safari */
      docsViewer.webkitRequestFullscreen();
    } else if (docsViewer.msRequestFullscreen) {
      /* IE11 */
      docsViewer.msRequestFullscreen();
    }
  };

  const exitFullscreen = () => {
    document.webkitExitFullscreen();
  };

  const location = useLocation();
  const [filePath, setFilePath] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isOfficeDocument, setIsOfficeDocument] = useState(false);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${server}/content/${path.contentId || contentId}`)
      .then((res) => {
        // console.log(res);
        setIsOfficeDocument(
          res.headers["content-type"].includes("officedocument")
        );
        // console.log(res.headers["content-type"].includes("officedocument"));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }, [path]);
  console.warn(path);
  // console.warn(env);
  //`${server}/Admin/view-content/file/${path.contentId}?access_token=${authContext.authState.token}`,
  useEffect(() => {
    if (path) {
      if (
        location.pathname.includes("/admin") ||
        location.pathname.includes("/reported-view")
      ) {
        isOfficeDocument
          ? setFilePath([
              {
                uri:
                  // env === "development" ||
                  // window.location.origin +
                  //   "/" +
                  // window.location.origin +
                  server + path.filePath + path.fileName,
                // `${server}/view-content/file/${path.fileId}?access_token=${authContext.authState.token}`,
              },
            ])
          : setFilePath([
              {
                uri: `${server}/Admin/view-content/file/${path.fileId}?access_token=${authContext.authState.token}`,
              },
            ]);
      } else {
        isOfficeDocument
          ? setFilePath([
              {
                uri:
                  // env === "development" ||
                  // window.location.origin +
                  //   "/" +
                  // window.location.origin +
                  server +
                  path.files.filter((s) => s.isMain)[0].filePath +
                  path.files.filter((s) => s.isMain)[0].fileName,
              },
            ])
          : setFilePath([
              {
                uri: server + "/content/" + path.contentId,
              },
            ]);
      }
    }
  }, [path, authContext.authState.token, location.pathname, isOfficeDocument]);
  // console.log(server + path.files[0].filePath + path.files[0].fileName);
  if (loading) {
    return (
      <div
        className="w-full bg-black bg-opacity-80 flex flex-col items-center gap-y-4 justify-center"
        style={{ height: "700px" }}
      >
        <div className="font-semibold text-lg text-white">Loading Content</div>
        <Loader type="TailSpin" color="#FFF" height={70} width={70} />
      </div>
    );
  }

  if (error) {
    return (
      <div
        className="w-full bg-black bg-opacity-80 flex text-white flex-col gap-y-4 items-center justify-center"
        style={{ height: "700px" }}
      >
        <div className="font-semibold text-lg">Error Loading Content</div>
        <EmojiSadIcon className="h-10 w-10" />
      </div>
    );
  }
  return (
    <div
      className="w-full shadow-xl relative flex sm:justify-center justify-end"
      style={{ height: "700px" }}
    >
      <DocViewer
        pluginRenderers={DocViewerRenderers}
        id="docsViewer"
        className="w-full h-full "
        style={{ backgroundColor: "#181818" }}
        theme={{
          primary: "white",
          secondary: "white",
          tertiary: "#181818",
          text_primary: "white",
          text_secondary: "white",
          text_tertiary: "white",
          disableThemeScrollbar: false,
        }}
        documents={filePath}
        config={{
          header: {
            disableHeader: true,
            disableFileName: true,
            retainURLParams: true,
          },
        }}
      />

      <div className="absolute sm:mr-0 mr-5 sm:top-2 z-20 sm:bottom-full bottom-5">
        <button
          onClick={() => {
            fullscreen();
          }}
          className="bg-white hover:bg-opacity-20 text-sm text-black rounded-md font-semibold py-1 px-3"
        >
          <div>Fullscreen</div>
        </button>
      </div>
    </div>
  );
};

export default DocsReader;
