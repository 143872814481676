import { BellIcon } from "@heroicons/react/solid";
import React, { useEffect, useContext, Fragment, useState } from "react";
import * as signalR from "@microsoft/signalr";
import { AuthContext } from "../../context/AuthContext";
import { Popover, Transition } from "@headlessui/react";
import moment from "moment";
import { PresenceContext } from "../../context/PresenceContext";
import { useHistory } from "react-router";
import Tooltip from "react-simple-tooltip";
//Connection

const Notification = () => {
  const authContext = useContext(AuthContext);
  const presenceContext = useContext(PresenceContext);
  const history = useHistory();
  const [focus, setFocus] = useState(false);
  const [hover, setHover] = useState(false);

  return (
    <div>
      {authContext.isAuthenticated() && (
        <Popover className="relative flex justify-center">
          <Tooltip
            content="Notifications"
            placement="bottom"
            background="#F7941E"
            border="#F7941E"
            radius="10"
          >
            <Popover.Button className="relative flex items-center text-dark-orange mx-4 rounded-full hover:bg-orange hover:bg-opacity-20 ">
              <BellIcon
                className={`p-2 h-10 w-10 relative z-10 ${
                  presenceContext.notif && "animate-wiggle"
                } `}
                onClick={() => {
                  presenceContext.setNotif(false);
                }}
              />
              {presenceContext.notif && (
                <span className="animate-ping absolute h-full w-full bg-white rounded-full opacity-75"></span>
              )}
            </Popover.Button>
          </Tooltip>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Popover.Panel className="absolute z-10 w-96 max-w-sm px-4 mt-2 right-0 sm:px-0 lg:max-w-3xl top-full">
              <div className="overflow-hidden rounded-xl bg-white shadow-xl ">
                <div className="text-sm font-bold text-blue py-3 px-4 bg-light-blue">
                  Notifications
                </div>
                <div className="max-h-96 overflow-auto p-2">
                  {presenceContext.notifItems.length > 0 ? (
                    presenceContext.notifItems.map((item, i) => {
                      return (
                        <div
                          key={item.notificationId}
                          onMouseEnter={() => {
                            if (!item.isRead)
                              presenceContext.ReadNotification(
                                item.notificationId
                              );
                          }}
                          onClick={() => {
                            if (
                              item.content.includes("Approved") ||
                              item.content.includes("Declined")
                            )
                              history.push("/my-contents");

                            if (item.content.includes("uploaded"))
                              history.push("/contents");

                            if (
                              item.content.includes("User") &&
                              authContext.isAdmin()
                            )
                              history.push("/users");

                            if (
                              item.content.includes("New Content") &&
                              authContext.isAdmin()
                            )
                              history.push("/contents");
                          }}
                          className="text-sm flex group relative items-center px-4 py-2 text-gray-400 hover:text-Orange cursor-pointer hover:bg-LightOrange hover:bg-opacity-20 rounded-md"
                        >
                          {!item.isRead && (
                            <div className="bg-Orange h-1 w-1 rounded-full mr-2"></div>
                          )}
                          <div className="w-60 truncate">{item.content}</div>
                          {/* <div className="h-1 w-1 rounded-full bg-gray-300"></div> */}
                          <div className="text-xs ml-auto flex group-hover:opacity-0 transition text-right">
                            {moment.utc(item.createdOn).local().fromNow()}
                          </div>
                          <div className="group-hover:opacity-100 opacity-0 absolute right-3 transition">
                            <button
                              className="border border-orange rounded-full px-3 py-1 text-xs hover:bg-orange hover:text-white transition"
                              onClick={(e) => {
                                presenceContext.RemoveNotification(
                                  item.notificationId
                                );
                                e.stopPropagation();
                              }}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="text-sm py-2 text-center text-gray-500">
                      You have no notifications
                    </div>
                  )}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      )}
    </div>
  );
};

export default Notification;
