import React, { createContext, useState } from "react";

const ReportsContext = createContext();
const { Provider } = ReportsContext;

const ReportsProvider = ({ children }) => {
  // Filters
  const [contentName, setContentName] = useState("");
  const [author, setAuthor] = useState("");
  const [status, setStatus] = useState("");
  const [dateRequestedFrom, setDateRequestedFrom] = useState("");
  const [dateRequestedTo, setDateRequestedTo] = useState("");

  const filtersInitialState = {
    contentName: "",
    author: "",
    status: "",
    dateRequestedFrom: "",
    dateRequestedTo: "",
  };

  const [filters, setFilters] = useState(filtersInitialState);

  const ApplyFilters = () => {
    setFilters({
      contentName,
      author,
      status,
      dateRequestedFrom,
      dateRequestedTo,
    });
  };

  const ResetFilters = () => {
    setFilters(filtersInitialState);
    setContentName("");
    setAuthor("");
    setStatus("");
    setDateRequestedFrom("");
    setDateRequestedTo("");
  };

  //Pagination
  const [entries, setEntries] = useState(10);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  return (
    <Provider
      value={{
        entries,
        setEntries,
        page,
        setPage,
        pageCount,
        setPageCount,
        filtersInitialState,
        filters,
        setFilters,
        contentName,
        author,
        status,
        dateRequestedFrom,
        dateRequestedTo,
        setContentName,
        setAuthor,
        setStatus,
        setDateRequestedFrom,
        setDateRequestedTo,
        ApplyFilters,
        ResetFilters,
      }}
    >
      {children}
    </Provider>
  );
};

export { ReportsContext, ReportsProvider };
