import axios from "axios";

var env = process.env.NODE_ENV || "development";
const baseUrl = `${
  env === "development"
    ? process.env.REACT_APP_DEV_SERVER
    : process.env.REACT_APP_PROD_SERVER
}/accounts`;

const axiosPost = axios.create({
  baseUrl,
  headers: {
    "Content-Type": "application/json",
    // "Authorization" : `Bearer`
  },
});

export const register = (data) => {
  return axiosPost
    .post(`${baseUrl}/register`, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const login = (data) => {
  return axiosPost
    .post(`${baseUrl}/login`, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const forgotPassword = (data) => {
  return axiosPost
    .post(`${baseUrl}/forgot-password`, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const resetPassword = (data) => {
  return axiosPost
    .post(`${baseUrl}/reset-password`, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const getCountryCode = () => {
  return axiosPost
    .get(`${baseUrl}/get-country-codes`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response;
    });
};
