import React, { useState, useEffect } from "react";
import { FlatButton } from "../../Buttons/Buttons";
import {
  ArrowNarrowRightIcon,
  DocumentAddIcon,
} from "@heroicons/react/outline";
import Textbox from "../../Inputs/Textbox";
import DataTable from "../../Base/DataTable";
import moment from "moment";
import { contentType } from "mime-types";
import Modal from "../../Others/Modal";
import { parse } from "dotenv";
import TextArea from "../../Inputs/TextArea";

const AddFile = ({ context }) => {
  const [tab, setTab] = useState("upload");
  const [removeFile, setRemoveFile] = useState(false);
  const [fileId, setFileId] = useState(null);
  const [newFile, setNewFile] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const [headers, setHeaders] = useState([]);
  const [disableAddFile, setDisableAddFile] = useState(false);
  const [fileExist, setFileExist] = useState(false);

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  useEffect(() => {
    if (width > 639) {
      setHeaders([
        { text: "Filename", className: "py-4 px-6 text-left" },
        { text: "Type", textAlign: "center" },
        { text: "Size", textAlign: "center" },
        { text: "Status", textAlign: "center" },
        { text: "Date", textAlign: "center" },
        { text: "Action", textAlign: "center" },
      ]);
    } else {
      setHeaders([
        { text: "Filename", className: "py-4 px-6 text-left" },
        { text: "Type", textAlign: "center" },
        { text: "Size", textAlign: "center" },
      ]);
    }
  }, [width]);

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    const { files, embed, uploadStatus, existingFiles } = context;

    if (
      (files.length > 0 &&
        uploadStatus.filter((s) => s.status === "success").length ===
          files.length) ||
      embed !== "" ||
      existingFiles.length > 0
    )
      setDisabled(false);
    else {
      setDisabled(true);
    }
  }, [context]);

  return (
    <div className="space-y-7 pb-20">
      <Modal
        isOpen={removeFile}
        onClose={() => {
          setRemoveFile(false);
        }}
        title="Remove File"
        description="Are you sure you want to remove this file? "
      >
        <div className="flex justify-end space-x-2">
          <FlatButton
            onClick={() => {
              setRemoveFile(false);
              // context.RemoveFile(newFile, fileId);
            }}
            className="btn-Gray"
          >
            Cancel
          </FlatButton>
          <FlatButton
            className="btn-Red"
            onClick={() => {
              context.RemoveFile(newFile, fileId);
              setRemoveFile(false);
            }}
          >
            Remove File
          </FlatButton>
        </div>
      </Modal>
      <Modal
        isOpen={context.fileExist}
        onClose={() => {
          context.setFileExist(false);
        }}
        title="Duplicate File"
        description="The file you have selected already exists on the list."
      >
        <div className="flex justify-center space-x-2">
          <FlatButton
            className="btn-Orange-primary w-8/12 justify-center"
            onClick={() => {
              context.setFileExist(false);
            }}
          >
            OK
          </FlatButton>
        </div>
      </Modal>
      <div className="shadow-xl bg-white rounded-3xl md:p-8 p-5 py-8 space-y-4">
        <div className="font-bold text-Orange text-xl flex items-center">
          <span>Add File</span>
          <div className="ml-auto md:block hidden">
            <FlatButton
              className={`w-48 justify-center gap-x-2 ${
                disabled ? "btn-Gray opacity-50" : "btn-Orange-primary "
              }`}
              disabled={disabled}
              onClick={() => context.setStep(3)}
            >
              Step 3: Summary
              <ArrowNarrowRightIcon className="h-5 w-5" />
            </FlatButton>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="md:w-8/12 w-full space-y-4 py-10">
            {/* <div className="flex">
              <div className="flex bg-gray-200 p-1 rounded-lg space-x-1">
                <FlatButton
                  className={
                    tab === "upload"
                      ? "btn-Orange-primary"
                      : `text-gray-400 hover:bg-gray-300`
                  }
                  onClick={() => {
                    setTab("upload");
                  }}
                >
                  Upload File
                </FlatButton>
                <FlatButton
                  className={
                    tab === "embed"
                      ? "btn-Orange-primary"
                      : `text-gray-400 hover:bg-gray-300`
                  }
                  onClick={() => {
                    setTab("embed");
                  }}
                >
                  Embed
                </FlatButton>
              </div>
            </div> */}
            {/* {tab === "upload" && (
              <div className="h-48 w-full flex items-center flex-col px-5 text-center justify-center border-gray-300 rounded-2xl border-dashed border-4 text-gray-500 relative">
                <DocumentAddIcon className="h-24 w-24" />
                <div className="font-bold text-lg">
                  Drag or Drop Files to Upload
                </div>
                <div className="font-semibold">
                  Your file will be uploaded upon Admin's approval.
                </div>
                {context.isUploading ? (
                  <FlatButton
                    className="btn-Orange-primary my-4 disabled:opacity-50 cursor-wait"
                    disabled={context.isUploading}
                  >
                    Please wait
                  </FlatButton>
                ) : (
                  <FlatButton
                    className="btn-Orange-primary my-4"
                    disabled={context.isUploading}
                  >
                    Select Files
                  </FlatButton>
                )}

                {context.isUploading ? (
                  <input
                    type="file"
                    id="upload"
                    className="border border-black absolute top-0 bottom-0 left-0 right-0 w-full disabled:opacity-0"
                    //multiple
                    onChange={(e) => {
                      if (e.target.files.length !== 0) context.UploadFile(e);
                      e.target.value = null;
                    }}
                    disabled={context.isUploading}
                  />
                ) : (
                  <input
                    type="file"
                    id="upload"
                    className="border border-black absolute top-0 bottom-0 left-0 right-0 w-full opacity-0"
                    //multiple
                    onChange={(e) => {
                      if (e.target.files.length !== 0) context.UploadFile(e);
                      e.target.value = null;
                    }}
                    disabled={context.isUploading}
                  />
                )}
              </div>
            )} */}
            <div className="h-68 py-5 w-full flex items-center flex-col px-5 text-center justify-center border-gray-300 rounded-2xl border-dashed border-4 text-gray-500 relative">
              <DocumentAddIcon className="h-24 w-24" />
              <div className="font-bold text-lg">
                Drag or Drop Files to Upload
              </div>
              <div className="font-semibold">
                Your file will be uploaded upon Admin's approval.
              </div>
              {context.isUploading ? (
                <FlatButton
                  className="btn-Orange-primary my-4 disabled:opacity-50 cursor-wait"
                  disabled={context.isUploading}
                >
                  Please wait
                </FlatButton>
              ) : (
                <FlatButton
                  className="btn-Orange-primary my-4"
                  disabled={context.isUploading}
                >
                  Select Files
                </FlatButton>
              )}

              {context.isUploading ? (
                <input
                  type="file"
                  id="upload"
                  className="border border-black absolute top-0 bottom-0 left-0 right-0 w-full disabled:opacity-0"
                  //multiple
                  onChange={(e) => {
                    if (e.target.files.length !== 0) context.UploadFile(e);
                    e.target.value = null;
                  }}
                  disabled={context.isUploading}
                />
              ) : (
                <input
                  type="file"
                  id="upload"
                  className="border border-black absolute top-0 bottom-0 left-0 right-0 w-full opacity-0"
                  //multiple
                  onChange={(e) => {
                    if (e.target.files.length !== 0) context.UploadFile(e);
                    e.target.value = null;
                  }}
                  disabled={context.isUploading}
                />
              )}
            </div>
            <TextArea
              label="Embed Code"
              placeholder="<iframe ...>"
              className="w-96 h-20"
              value={context.embed}
              onChange={(e) => context.setEmbed(e.target.value)}
            />
            {/* {tab === "embed" && (

            )} */}

            <div className="flex justify-end md:hidden block">
              <FlatButton
                className={`w-48 justify-center gap-x-2 ${
                  disabled ? "btn-Gray opacity-50" : "btn-Orange-primary "
                }`}
                disabled={disabled}
                onClick={() => context.setStep(3)}
              >
                Step 3: Summary
                <ArrowNarrowRightIcon className="h-5 w-5" />
              </FlatButton>
            </div>
          </div>
        </div>
      </div>
      {(context.existingFiles.length > 0 || context.files.length > 0) && (
        <div className="text-lg font-bold text-Orange ml-4">Files</div>
      )}

      {context.existingFiles.length > 0 && (
        <div className="p-6 text-sm rounded-3xl bg-white shadow-xl flex flex-col text-gray-600">
          {context.existingFiles.map((file, key) => {
            return (
              <div key={key} className="flex items-center pl-4 w-full">
                <div className=" flex gap-x-4">
                  <div className="font-bold">{key + 1}.</div>
                  <div>{file.fileName}</div>
                </div>
                <button
                  className="gap-x-2 ml-auto flex text-xs font-semibold text-gray-500 hover:bg-red-500 hover:text-white transition px-3 py-2 rounded-md"
                  onClick={() => {
                    setFileId(file.fileId);
                    setRemoveFile(true);
                    setNewFile(false);
                  }}
                >
                  Remove
                </button>
              </div>
            );
          })}
        </div>
      )}

      {context.files.length > 0 && (
        <DataTable headers={headers} data={context.files}>
          {context.files.map((file, key) => {
            return width > 639 ? (
              <tr key={key} className="text-center text-sm text-gray-600">
                <td className="text-left px-6 ">{file.name}</td>
                <td>{file.type}</td>
                <td>{file.size} kb</td>
                <td className="w-3/12 px-3 ">
                  <div className="w-full bg-gray-300 rounded-full overflow-hidden relative">
                    <div
                      className="bg-LightOrange h-5"
                      style={
                        context && context.uploadProgress[key]?.percentage
                          ? {
                              width:
                                context.uploadProgress[key]?.percentage + "%",
                            }
                          : { width: "0px" }
                      }
                    ></div>
                    <div className="flex items-center justify-center h-full text-xs text-DarkOrange absolute top-0 left-0 w-full">
                      {context.uploadStatus[key]?.status === "success"
                        ? "Completed"
                        : parseInt(context.uploadProgress[key]?.percentage) <
                            100 &&
                          context.uploadStatus[key]?.status !== "success"
                        ? context.uploadProgress[key]?.percentage + "%"
                        : context.uploadStatus[key]?.status !== "success" &&
                          parseInt(context.uploadProgress[key]?.percentage) ===
                            100
                        ? "Processing"
                        : ""}
                    </div>
                  </div>
                </td>
                <td>{moment(file.lastModified).format("lll")}</td>
                <td className="flex justify-center py-3 px-3">
                  {context.uploadStatus[key]?.status === "success" ? (
                    <button
                      className="text-xs font-semibold px-3 py-1 border rounded-md"
                      disabled={context.isUploading}
                      onClick={() => {
                        setNewFile(true);
                        setRemoveFile(true);
                        setFileId(file.name);
                      }}
                    >
                      Remove
                    </button>
                  ) : (
                    <button
                      className="text-xs font-semibold px-3 py-1 border rounded-md"
                      disabled={context.isUploading}
                    >
                      Uploading
                    </button>
                  )}
                </td>
              </tr>
            ) : (
              <tr key={key} className="text-center text-sm text-gray-600">
                <td className="text-left px-6">{file.name}</td>
                <td>{file.type}</td>
                <td className="flex justify-center py-3 px-3">
                  {context.uploadStatus[key]?.status === "success" ? (
                    <button
                      className="text-xs font-semibold px-3 py-1 border rounded-md"
                      disabled={context.isUploading}
                      onClick={() => {
                        setNewFile(true);
                        setRemoveFile(true);
                        setFileId(file.name);
                      }}
                    >
                      Remove
                    </button>
                  ) : (
                    <button
                      className="text-xs font-semibold px-3 py-1 border rounded-md"
                      disabled={context.isUploading}
                    >
                      Uploading
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
        </DataTable>
      )}
    </div>
  );
};

export default AddFile;
