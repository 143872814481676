import axios from "axios";
import { saveAs } from "file-saver";

const baseUrl = process.env.REACT_APP_DEV_SERVER + "/download";
const token = localStorage.getItem("token");

const authAxios = axios.create({
  baseUrl,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const downloadContent = (contentId) => {
  //   return authAxios
  //     .get(`${baseUrl}/${contentId}`, { responseType: "blob" })
  //     .then((res) => {
  //       console.log(res.headers["content-disposition"]);
  //       const filename = "test.pdf";
  //       let url = window.URL.createObjectURL(new Blob([res.data]));
  //       saveAs(url, filename);
  //       return res;
  //     });
  //   location.href(`${baseUrl}/${contentId}`);
  window.URL.createObjectURL(`${baseUrl}/${contentId}`);
};
