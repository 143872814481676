import React, { createContext, useState } from "react";

const LogsContext = createContext();
const { Provider } = LogsContext;

const LogsProvider = ({ children }) => {
  const [entries, setEntries] = useState(10);

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [sortBy, setSortBy] = useState("desc");
  const [type, setType] = useState("");
  const [action, setAction] = useState("");

  const [filters, setFilters] = useState({
    type: "",
    action: "",
  });

  const ApplyFilters = () => {
    setFilters({ type, action });
  };
  const ResetFilters = () => {
    setFilters({ type: "", action: "" });
    setType("");
    setAction("");
    setSortBy("desc");
  };

  return (
    <Provider
      value={{
        action,
        setAction,
        ResetFilters,
        ApplyFilters,
        type,
        setType,
        entries,
        setEntries,
        page,
        setPage,
        pageCount,
        setPageCount,
        sortBy,
        setSortBy,
        filters,
        setFilters,
      }}
    >
      {children}
    </Provider>
  );
};

export { LogsContext, LogsProvider };
