import React from "react";
import AllContents from "../../Dashboard/AllContents";
import Sidebar from "../../Base/Sidebar";

const ViewAllContents = () => {
  return (
    <div className="h-full">
      <div className="flex h-full w-full">
        <Sidebar />
        <div
          id="content-scroll"
          className="z-0 mb-20 py-10 px-4 w-full overflow-auto flex flex-col space-y-4"
        >
          <div>
            <div className="flex items-center uppercase text-dark-blue w-full text-2xl font-bold mb-5">
              <span>All contents</span>
            </div>
            <AllContents />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAllContents;
