import React, { createContext, useState, useEffect } from "react";

const FilterContext = createContext();
const { Provider } = FilterContext;

const FilterProvider = ({ children }) => {
  const [filterState, setFilterState] = useState({
    filter: false,
    navbarFilter: {},
    sidebarFilter: {},
  });

  const navbarInitState = {
    category: "Search All",
    string: "",
  };

  const sidebarInitState = {
    contentType: [],
    days: null,
    rating: null,
    categories: [],
  };

  const [navbarFilter, setNavbarFilter] = useState(navbarInitState);
  const [sidebarFilter, setSidebarFilter] = useState(sidebarInitState);

  const setNavFilters = ({ category, string }) => {
    setNavbarFilter({ category, string });
  };

  const setSideFilters = ({ contentType, days, rating, categories }) => {
    setSidebarFilter({ contentType, days, rating, categories });
  };

  useEffect(() => {
    if (
      navbarFilter.category !== "Search All" &&
      navbarFilter.string !== "" &&
      sidebarFilter.days !== null &&
      sidebarFilter.rating !== null &&
      sidebarFilter.categories.length !== 0 &&
      sidebarFilter.contentType.length !== 0
    )
      setFilterState({ filter: false, navbarFilter, sidebarFilter });
    else setFilterState({ filter: true, navbarFilter, sidebarFilter });
  }, [navbarFilter, sidebarFilter]);

  return (
    <Provider
      value={{
        filterState,
        navbarFilter,
        sidebarFilter,
        setNavbarFilter: (filter) => setNavFilters(filter),
        setSidebarFilter: (filter) => setSideFilters(filter),
      }}
    >
      {children}
    </Provider>
  );
};

export { FilterProvider, FilterContext };
