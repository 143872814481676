import React, { useState, useContext, useEffect } from "react";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  EyeIcon,
} from "@heroicons/react/outline";
import { useQuery } from "react-query";
import { FlatButton } from "../../Buttons/Buttons";
import ContentError from "../../Others/ContentError";
import Loading from "../../Others/Loading";
import { getUsers } from "../../../services/admin";
import moment from "moment";
import { useHistory } from "react-router-dom";
import DataTable from "../../Base/DataTable";
import { UsersContext } from "../../../context/UsersContext";

const Table = () => {
  const context = useContext(UsersContext);
  const filters = context.filters;
  const entries = context.entries;
  const sortBy = context.sortBy;
  const page = context.page;
  const history = useHistory();
  const { data, isError, isLoading } = useQuery(
    ["users", { filters, entries, page, sortBy }],
    getUsers,
    { staleTime: 10000 }
  );

  const [width, setWidth] = useState(window.innerWidth);
  const [headers, setHeaders] = useState([]);

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  useEffect(() => {
    if (data) context.setPageCount(data.pages);
  }, [data, context]);

  const SortBy = (type) => {
    const typeAsc = type + "Asc";
    const typeDesc = type + "Desc";
    context.sortBy === typeDesc
      ? context.setSortBy(typeAsc)
      : context.setSortBy(typeDesc);
  };

  useEffect(() => {
    if (width > 768) {
      setHeaders([
        {
          text: "Full Name",
          className: "py-4 px-6 text-left cursor-pointer",
          onClick: () => SortBy("name"),
          component:
            context.sortBy === "nameAsc"
              ? ChevronUpIcon
              : context.sortBy === "nameDesc"
              ? ChevronDownIcon
              : "",
        },
        {
          text: "Username",
          className: "text-left cursor-pointer",
          onClick: () => SortBy("userName"),
          component:
            context.sortBy === "userNameAsc"
              ? ChevronUpIcon
              : context.sortBy === "userNameDesc"
              ? ChevronDownIcon
              : "",
        },
        {
          text: "Total Files Uploaded",
          className: "cursor-pointer",
          textAlign: "center",
          onClick: () => SortBy("upload"),
          component:
            context.sortBy === "uploadAsc"
              ? ChevronUpIcon
              : context.sortBy === "uploadDesc"
              ? ChevronDownIcon
              : "",
        },
        {
          text: "Status",
          className: "cursor-pointer",
          textAlign: "center",
          onClick: () => SortBy("status"),
          component:
            context.sortBy === "statusAsc"
              ? ChevronUpIcon
              : context.sortBy === "statusDesc"
              ? ChevronDownIcon
              : "",
        },
        {
          text: "Role",
          className: "cursor-pointer",
          textAlign: "center",
          onClick: () => SortBy("role"),
          component:
            context.sortBy === "roleAsc"
              ? ChevronUpIcon
              : context.sortBy === "roleDesc"
              ? ChevronDownIcon
              : "",
        },
        {
          text: "Date Created",
          className: "cursor-pointer",
          textAlign: "center",
          onClick: () => SortBy("createdOn"),
          component:
            context.sortBy === "createdOnAsc"
              ? ChevronUpIcon
              : context.sortBy === "createdOnDesc"
              ? ChevronDownIcon
              : "",
        },
        { text: "Actions", className: "", textAlign: "center" },
      ]);
    } else if (width > 639) {
      setHeaders([
        {
          text: "Name",
          className: "text-left cursor-pointer py-4 px-6",
          onClick: () => SortBy("name"),
          component:
            context.sortBy === "nameAsc"
              ? ChevronUpIcon
              : context.sortBy === "nameDesc"
              ? ChevronDownIcon
              : "",
        },
        { text: "Actions", className: "", textAlign: "center" },
      ]);
    } else {
      setHeaders([
        {
          text: "Name",
          className: "text-left cursor-pointer py-4 px-6",
          onClick: () => SortBy("name"),
          component:
            context.sortBy === "nameAsc"
              ? ChevronUpIcon
              : context.sortBy === "nameDesc"
              ? ChevronDownIcon
              : "",
        },
        { text: "Actions", className: "", textAlign: "center" },
      ]);
    }
  }, [width, context.sortBy]);

  // "nameAsc" => query.OrderBy(n => n.FullName),
  //  "nameDesc" => query.OrderByDescending(n => n.FullName),
  //  "userNameAsc" => query.OrderBy(n => n.UserName),
  // "userNameDesc" =>  query.OrderByDescending(n => n.UserName),
  //   "uploadAsc" => query.OrderBy(n => n.Contents.Count),
  //   "uploadDesc" => query.OrderByDescending(n => n.Contents.Count),
  //  "statusAsc" => query.OrderBy(n => n.Status),
  //  "statusDesc" => query.OrderByDescending(n => n.Status),
  // "roleAsc" => query.OrderBy(n => n.UserRoles.SingleOrDefault().Role.Name),
  //  "roleDesc" => query.OrderByDescending(n => n.UserRoles.SingleOrDefault().Role.Name),
  //   "createdOnAsc" => query.OrderBy(n => n.CreatedOn),
  //  "createdOnDesc" => query.OrderByDescending(n => n.CreatedOn),

  // console.log(data);

  if (isLoading) {
    return (
      <div className="animate-pulse">
        <div className="w-full h-96 rounded-3xl bg-white shadow-xl p-5 space-y-6 overflow-hidden relative flex justify-center">
          <Loading loading={true} />
        </div>
      </div>
    );
  }

  if (isError) {
    return <ContentError />;
  }

  return (
    <DataTable headers={headers} data={data.data} noDataText="User Not Found.">
      {data.data.map((user) => {
        return (
          <tr
            className="text-center text-gray-600 text-sm hover:bg-gray-100"
            key={user.userId}
          >
            <td className="text-left px-6 md:w-3/12 w-8/12 py-2">
              <div className="font-bold">{user.fullname}</div>
              <div className="text-xs md:hidden block flex flex-col items-start space-y-1">
                <div>{user.username}</div>
                <span
                  className={`font-semibold ${
                    user.roleName === "Admin"
                      ? "text-white bg-dark-blue  px-3 py-1 rounded-3xl"
                      : user.roleName === "Member"
                      ? "text-blue bg-light-blue px-3 py-1 rounded-3xl"
                      : "text-gray-600 bg-gray-200 px-3 py-1 rounded-3xl"
                  }`}
                >
                  {user.roleName}
                </span>
                <span
                  className={` ${
                    user.userStatus === "Active"
                      ? "text-green-600 bg-green-200 px-3 py-1 rounded-3xl"
                      : user.userStatus === "Pending"
                      ? "text-yellow-600 bg-yellow-200 px-3 py-1 rounded-3xl"
                      : user.userStatus === "Declined"
                      ? "text-red-600 bg-red-200 px-3 py-1 rounded-3xl"
                      : "text-gray-600 bg-gray-200 px-3 py-1 rounded-3xl"
                  }`}
                >
                  {user.userStatus}
                </span>
                <div className="mt-2 text-gray-400">
                  {moment.utc(user.dateCreated).local().format("lll")}
                </div>
              </div>
            </td>
            <td className="w-2/12 text-left md:table-cell hidden">
              {user.username}
            </td>
            <td className="md:table-cell hidden">{user.numberOfUploads} </td>
            <td className="text-xs md:table-cell hidden">
              <span
                className={` ${
                  user.userStatus === "Active"
                    ? "text-green-600 bg-green-200 px-3 py-1 rounded-3xl"
                    : user.userStatus === "Pending"
                    ? "text-yellow-600 bg-yellow-200 px-3 py-1 rounded-3xl"
                    : user.userStatus === "Declined"
                    ? "text-red-600 bg-red-200 px-3 py-1 rounded-3xl"
                    : "text-gray-600 bg-gray-200 px-3 py-1 rounded-3xl"
                }`}
              >
                {user.userStatus}
              </span>
            </td>
            <td className="text-xs md:table-cell hidden ">
              <span
                className={`font-semibold ${
                  user.roleName === "Admin"
                    ? "text-white bg-dark-blue  px-3 py-1 rounded-3xl"
                    : user.roleName === "Member"
                    ? "text-blue bg-light-blue px-3 py-1 rounded-3xl"
                    : "text-gray-600 bg-gray-200 px-3 py-1 rounded-3xl"
                }`}
              >
                {user.roleName}
              </span>
            </td>
            <td className="text-xs md:table-cell hidden">
              {moment(user.dateCreated).format("lll")}
            </td>
            <td className="py-3 px-2 flex justify-center items-center">
              <FlatButton
                onClick={() => {
                  history.push(`/user-view/${user.userId}`);
                }}
                className={`bg-orange text-white items-center flex gap-x-2 px-5 hover:bg-orange hover:bg-opacity-50`}
              >
                <span className="text-xs">View</span>
                <EyeIcon className="h-4 w-4" />
              </FlatButton>
            </td>
          </tr>
        );
      })}
    </DataTable>
  );
};

export default Table;
