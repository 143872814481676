import { ArrowNarrowRightIcon, XIcon } from "@heroicons/react/outline";
import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { FlatButton } from "../../Buttons/Buttons";
import Textbox from "../../Inputs/Textbox";
import TextArea from "../../Inputs/TextArea";
import Select from "react-select";
import { categoryOptions } from "../../../services/categories";
import DatePicker from "../../Inputs/DatePicker";
import moment from "moment";
import ImageNotFound from "../../../assets/ImageNotFound.jpg";
import { contentTypeOptions } from "../../../services/content-type";
import ReactSelect from "../../Inputs/ReactSelect";

var env = process.env.NODE_ENV || "development";
const server = `${
  env === "development"
    ? process.env.REACT_APP_DEV_SERVER
    : process.env.REACT_APP_PROD_SERVER
}`;

const KeywordPill = ({ i, text, onX }) => {
  return (
    <div className="bg-LightOrange text-sm py-1 bg-opacity-30 rounded-full text-DarkOrange px-3 gap-x-2 flex items-center justify-center">
      <div>{text}</div>
      <XIcon className="h-4 w-4 cursor-pointer" onClick={onX} />
    </div>
  );
};

const Details = ({ context }) => {
  const { data: contentTypeOption } = useQuery(
    "contentTypeOptions",
    contentTypeOptions
  );
  const { data: categoryTypeOption } = useQuery(
    "categoryOptions",
    categoryOptions
  );

  const [disabled, setDisabled] = useState(true);
  const [keywords, setKeywords] = useState("");
  const [keywordFocus, setKeywordFocus] = useState(false);
  useEffect(() => {
    if (context.categories) {
      const { title, categories, contentType } = context;
      if (
        title !== "" &&
        categories !== "" &&
        categories.length > 0 &&
        contentType !== ""
      )
        setDisabled(false);
      else setDisabled(true);
    }

    context.description || context.setDescription("");
    context.publishedDate || context.setPublishedDate("");
    context.releaseDate || context.setReleaseDate("");
    context.author || context.setAuthor("");
    context.publisher || context.setPublisher("");
    context.embed || context.setEmbed("");
  }, [context]);
  // console.warn(context.description);
  return (
    <div className="bg-white rounded-3xl shadow-xl p-8 space-y-4">
      <div className="font-bold text-Orange text-xl flex items-center">
        <span>Details</span>
        <div className="ml-auto md:block hidden">
          <FlatButton
            className={`w-48 justify-center gap-x-2 ${
              disabled ? "btn-Gray opacity-50" : "btn-Orange-primary "
            }`}
            disabled={disabled}
            onClick={() => {
              context.setStep(2);
            }}
          >
            Step 2: Add File
            <ArrowNarrowRightIcon className="h-5 w-5" />
          </FlatButton>
        </div>
      </div>
      <div className="flex gap-x-7 gap-y-4 md:flex-row flex-col">
        <div className="flex-1 space-y-4 w-full">
          <Textbox
            label="Title"
            value={context.title}
            onChange={(e) => {
              context.setTitle(e.target.value);
            }}
            required
          />
          <div className="relative">
            <TextArea
              label="Description"
              value={context.description}
              onChange={(e) => {
                if (context.description.length < 255)
                  context.setDescription(e.target.value);
                if (e.nativeEvent.inputType === "deleteContentBackward") {
                  context.setDescription(e.target.value);
                }
              }}
            />
            <div className="p-1 absolute bottom-0 text-sm font-semibold text-gray-500 right-1">
              {context.description && context.description.length + "/255"}
            </div>
          </div>
          <div className="space-y-2">
            {/* <label className="text-sm font-semibold text-gray-500">
              Content Types
            </label> */}
            <ReactSelect
              options={contentTypeOption}
              value={context.contentType}
              label="Content Types"
              isMulti={false}
              isSearchable
              getOptionLabel={(option) => `${option.name}`}
              getOptionValue={(option) => `${option.id}`}
              onChange={(e) => {
                context.setContentType(e);
              }}
              required
            />
          </div>
          <div className="space-y-2">
            {/* <label className="text-sm font-semibold text-gray-500">
              Category
            </label> */}
            <ReactSelect
              options={categoryTypeOption}
              value={context.categories}
              label="Category"
              isMulti
              isSearchable
              getOptionLabel={(option) => `${option.categoryName}`}
              getOptionValue={(option) => `${option.categoryId}`}
              onChange={(e) => {
                context.setCategories(e);
              }}
              required
            />
          </div>
          <div className="relative space-y-4">
            <div
              className={`text-xs right-0 top-1 text-Orange ${
                keywordFocus ? "absolute" : "hidden"
              }`}
            >
              Hit <b>ENTER</b> to add a keyword
            </div>
            <Textbox
              label="Keywords"
              value={keywords}
              onChange={(e) => {
                setKeywords(e.target.value);
              }}
              onKeyDown={(e) => {
                if (keywords !== "")
                  if (e.key === "Enter") {
                    context.setKeywords((k) => [...k, keywords]);
                    setKeywords("");
                  }
              }}
              onFocus={() => setKeywordFocus(true)}
              onBlur={() => setKeywordFocus(false)}
            />
            <div className="flex gap-x-2 flex-wrap gap-y-2">
              {context.keywords.map((k, i) => {
                return (
                  <KeywordPill
                    text={k}
                    i={i}
                    key={i}
                    onX={() => {
                      context.setKeywords((k) =>
                        k.filter((key, index) => index !== i)
                      );
                    }}
                  />
                );
              })}
            </div>
          </div>

          {/* <Select label="Language"></Select> */}
        </div>
        <div className="space-y-4 flex-1 w-full">
          <div className="space-y-4 ">
            <div>
              <Textbox
                label="Author"
                value={context.author}
                onChange={(e) => {
                  context.setAuthor(e.target.value);
                }}
              />
            </div>
            <div>
              <Textbox
                label="Publisher"
                value={context.publisher}
                onChange={(e) => {
                  context.setPublisher(e.target.value);
                }}
              />
            </div>
            <div className="flex gap-x-3 w-full sm:flex-row gap-y-4 flex-col">
              <div className="w-full">
                <DatePicker
                  label="Published Date"
                  value={moment(context.publishedDate).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    context.setPublishedDate(e.target.value);
                  }}
                />
              </div>
              <div className="w-full">
                <DatePicker
                  label="Release Date"
                  value={moment(context.releaseDate).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    context.setReleaseDate(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="gap-y-1 flex flex-col items-center">
              <label className="md:w-96 w-full text-sm font-semibold text-gray-500">
                Thumbnail
              </label>
              <div className="h-50  md:w-96 w-full  rounded-xl shadow-xl overflow-hidden bg-black bg-opacity-70 flex justify-center">
                <img
                  src={
                    context.thumbnailUrl !== "" && context.thumbnail === null
                      ? server + context.thumbnailUrl
                      : context.thumbnail !== null
                      ? URL.createObjectURL(context.thumbnail)
                      : ImageNotFound
                  }
                  className="h-full w-full object-cover"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = ImageNotFound;
                  }}
                />
              </div>
            </div>
            <label className="btn-Orange-primary w-full justify-center px-3 py-2 rounded-5 w-full flex rounded-md">
              <input
                type="file"
                className="hidden"
                accept="image/png, image/jpeg"
                onInput={(e) => {
                  if (e.target.files.length !== 0) {
                    context.setThumbnail(e.target.files[0]);
                    context.setThumbnailUrl("");
                  }
                }}
              />
              Upload Photo
            </label>

            <div className="ml-auto md:hidden flex pt-10 justify-end">
              <FlatButton
                className={`w-48 justify-center gap-x-2 ${
                  disabled ? "btn-Gray opacity-50" : "btn-Orange-primary "
                }`}
                disabled={disabled}
                onClick={() => {
                  context.setStep(2);
                }}
              >
                Step 2: Add File
                <ArrowNarrowRightIcon className="h-5 w-5" />
              </FlatButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Details;
