import React from "react";
import Loader from "react-loader-spinner";

const Loading = ({ loading, text, className }) => {
  return (
    <div className={className}>
      {loading ? (
        <div className="bg-white flex flex-col gap-y-4 justify-center items-center bg-opacity-50 z-40 absolute top-0 left-0 bottom-0 w-full py-8 px-8 sm:rounded-3xl sm:px-10 sm:mx-0">
          {text && (
            <div className="font-semibold text-sm text-Orange animate-pulse ">
              {text}
            </div>
          )}
          <Loader type="TailSpin" color="#6699CC" height={70} width={70} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Loading;
