import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import React from "react";
import { FlatButton } from "../Buttons/Buttons";

const Pagination = ({ context, className }) => {
  return (
    <div className="w-full flex sm:flex-row flex-col-reverse items-center gap-y-4 pb-5">
      <div className="text-sm font-semibold text-gray-600">
        Showing Page {context.page} of {context.pageCount}
      </div>
      <div
        className={`sm:ml-auto ml-0 flex items-center gap-x-1 text-gray-600 ${className}`}
      >
        <button
          className="px-2 py-2 hover:bg-gray-200 rounded-full text-gray-500 hover:text-gray-600"
          disabled={context.page <= 1}
          onClick={() => {
            context.setPage(context.page - 1);
          }}
        >
          <ChevronLeftIcon className="h-4 w-4" />
        </button>

        {context.pageCount <= 5 ? (
          [...Array(context.pageCount)].map((page, i) => {
            return (
              <button
                className={`h-7 w-7 flex items-center justify-center text-sm rounded-md ${
                  context.page === i + 1
                    ? "bg-blue text-white hover:bg-opacity-70"
                    : "font-semibold hover:bg-gray-200"
                }`}
                key={i}
                onClick={() => {
                  context.setPage(i + 1);
                }}
              >
                {i + 1}
              </button>
            );
          })
        ) : (
          <div>
            {context.page + 4 >= context.pageCount ? (
              <div className="flex items-center">
                <button
                  className={`h-7 w-7 flex items-center justify-center text-sm rounded-md ${
                    context.page === 1
                      ? "bg-blue text-white hover:bg-opacity-70"
                      : "font-semibold hover:bg-gray-200"
                  }`}
                  onClick={() => {
                    context.setPage(1);
                  }}
                >
                  1
                </button>
                <button
                  className={`h-7 w-7 flex items-center justify-center text-sm rounded-md `}
                >
                  ...
                </button>
                {[...Array(5)]
                  .map((page, i) => {
                    const temp = context.pageCount - i;
                    return (
                      <button
                        className={`h-7 w-7 flex items-center justify-center text-sm rounded-md ${
                          context.page === temp
                            ? "bg-blue text-white hover:bg-opacity-70"
                            : "font-semibold hover:bg-gray-200"
                        }`}
                        key={i}
                        onClick={() => {
                          context.setPage(temp);
                        }}
                      >
                        {temp}
                      </button>
                    );
                  })
                  .reverse()}
              </div>
            ) : (
              <div className="flex items-center">
                {[...Array(5)].map((page, i) => {
                  return (
                    <button
                      className={`h-7 w-7 flex items-center justify-center text-sm rounded-md ${
                        context.page === i + context.page
                          ? "bg-blue text-white hover:bg-opacity-70"
                          : "font-semibold hover:bg-gray-200"
                      }`}
                      key={i}
                      onClick={() => {
                        context.setPage(i + context.page);
                      }}
                    >
                      {i + context.page}
                    </button>
                  );
                })}
                <button
                  className={`h-7 w-7 flex items-center justify-center text-sm rounded-md `}
                >
                  ...
                </button>
                <button
                  className={`h-7 w-7 flex items-center justify-center text-sm rounded-md ${
                    context.page === context.pageCount
                      ? "bg-blue text-white hover:bg-opacity-70"
                      : "font-semibold hover:bg-gray-200"
                  }`}
                  onClick={() => {
                    context.setPage(context.pageCount);
                  }}
                >
                  {context.pageCount}
                </button>
              </div>
            )}
          </div>
        )}
        <button
          className="px-2 py-2 hover:bg-gray-200 rounded-full text-gray-500 hover:text-gray-600 "
          disabled={context.page === context.pageCount}
          onClick={() => {
            context.setPage(context.page + 1);
          }}
        >
          <ChevronRightIcon className="h-4 w-4" />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
