import React, { createContext, useState } from "react";
import { useQueryClient } from "react-query";
import { updateUserRole, updateUserStatus } from "../services/admin";
import { useToasts } from "react-toast-notifications";

const UsersContext = createContext();
const { Provider } = UsersContext;

const UsersProvider = ({ children }) => {
  const queryClient = useQueryClient();
  const [entries, setEntries] = useState(10);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [sortBy, setSortBy] = useState("createdOnDesc");
  const { addToast } = useToasts();

  const [filters, setFilters] = useState({
    fullname: "",
    username: "",
    status: "",
  });

  const [fullname, setFullname] = useState("");
  const [username, setUsername] = useState("");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);

  const ApplyFilters = () => {
    setFilters({ fullname, username, status });
    setPage(1);
  };

  const ResetFilters = () => {
    setFilters({
      fullname: "",
      username: "",
      status: "",
    });
    setFullname("");
    setUsername("");
    setStatus("");
  };

  const SetUserStatus = ({ id, status }) => {
    setLoading(true);

    const data = {
      Status: status,
    };
    updateUserStatus(id, data)
      .then((res) => {
        setTimeout(() => {
          queryClient.invalidateQueries("user");
          setLoading(false);
          // console.log(res);
          addToast("Updated User Status", { appearance: "success" });
        }, 1000);
      })
      .catch((e) => {
        setLoading(false);
        addToast("Error Updating User Status", { appearance: "error" });
      });
  };

  const UpdateUserRole = ({ id, role }) => {
    setLoading(true);
    const data = {
      roleId: role,
    };

    updateUserRole(id, data)
      .then((res) => {
        setTimeout(() => {
          queryClient.invalidateQueries("user");
          setLoading(false);
          // console.log(res);
          addToast("Updated User Role", { appearance: "success" });
        }, 1000);
      })
      .catch((e) => {
        setLoading(false);
        addToast("Error Updating User Role", { appearance: "error" });
      });
  };

  return (
    <Provider
      value={{
        setSortBy,
        sortBy,
        loading,
        UpdateUserRole,
        SetUserStatus,
        entries,
        filters,
        setEntries,
        page,
        setPage,
        pageCount,
        setPageCount,
        fullname,
        setFullname,
        username,
        setUsername,
        status,
        setStatus,
        ApplyFilters,
        ResetFilters,
      }}
    >
      {children}
    </Provider>
  );
};

export { UsersContext, UsersProvider };
