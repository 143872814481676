import React from "react";

const Select = ({ children, label, onChange, value, className }) => {
  return (
    <div className={`space-y-2 ${className}`}>
      {label && (
        <label
          htmlFor={label}
          className="block text-sm font-medium text-gray-500"
        >
          {label}
        </label>
      )}
      <select
        value={value}
        className={`outline-none rounded-md p-2 w-full text-sm focus:ring-2 focus:outline-none focus:ring-LightOrange focus:ring-offset-2 bg-gray-50 shadow-inner shadow-md ${className}`}
        onChange={onChange}
      >
        {children}
      </select>
    </div>
  );
};

export default Select;
