import React, { useState } from "react";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { Link, withRouter, useHistory } from "react-router-dom";
import Textbox from "../components/Inputs/Textbox";
import { FlatButton } from "../components/Buttons/Buttons";
import { forgotPassword } from "../services/accounts";
import Loading from "../components/Others/Loading";
import Modal from "../components/Others/Modal";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [modal, setModal] = useState(false);

  const SendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = { email };
    forgotPassword(data)
      .then((res) => {
        // console.log(res);
        setLoading(false);
        setModal(true);
      })
      .catch((res) => {
        // console.log(res);
      });
  };

  return (
    <div className="min-h-screen flex justify-center items-center sm:px-6 lg:px-8">
      <Modal
        isOpen={modal}
        title="Email Sent"
        description="Please check your email to reset your password."
        onClose={() => {}}
      >
        <div className="flex space-x-2">
          <FlatButton
            className="btn-Orange-primary w-full flex justify-center"
            onClick={() => {
              history.push("/signin");
            }}
          >
            Back to Sign In Page
          </FlatButton>
        </div>
      </Modal>
      <div className="absolute h-screen w-screen top-0 right-1/2 z-0 bg-orange transform rotate-45 shadow-xl"></div>
      <div className="sm:mx-auto sm:w-full sm:max-w-md relative z-10 space-y-6 ">
        <div className="relative z-10 w-full p-2 space-y-1">
          <div className="w-full text-2xl text-gray-600 font-bold">
            Password Recovery
          </div>
          <div className="text-gray-600">
            Please enter your registered email to send get your account recovery
            link.
          </div>
        </div>
        <div className="bg-white relative py-8 px-8 shadow-xl sm:rounded-3xl sm:px-10 mx-10 sm:mx-0">
          {loading && <Loading loading={true} />}
          <h2 className="mb-4 text-left text-xl font-bold text-Orange">
            Forgot Password
          </h2>
          <form className="space-y-4" onSubmit={(e) => SendEmail(e)}>
            <div>
              <Textbox
                name="Email"
                label="Email"
                type="email"
                value={email}
                placeholder="juan.delacruz@csi.com"
                required={true}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="flex w-full text-center">
              <FlatButton
                className="btn-Orange-primary w-full flex justify-center"
                type="submit"
              >
                Send Email
              </FlatButton>
            </div>
            <div className="font-semibold text-gray-600 w-full text-center text-sm">
              <Link to="/signin" className="hover:text-Orange hover:underline">
                Back to Login
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ForgotPassword);
