import React, { createContext, useState, useEffect } from "react";
import { hideContent } from "../services/contents";
import { useToasts } from "react-toast-notifications";
import { useQueryClient } from "react-query";
const MyContentsContext = createContext();
const { Provider } = MyContentsContext;

const MyContentsProvider = ({ children }) => {
  const [filters, setFilters] = useState({});
  const [titleSearch, setTitleSearch] = useState("");
  const [sortBy, setSortBy] = useState("Latest");
  const [status, setStatus] = useState("Any");
  const [categories, setCategories] = useState([]);
  const [author, setAuthor] = useState("");
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    setFilters({
      titleSearch,
      sortBy,
      status,
      author,
      keyword,
      categories,
    });
  }, [titleSearch, sortBy, status, author, keyword, categories]);

  const [entries, setEntries] = useState(10);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const { addToast } = useToasts();
  const queryClient = useQueryClient();

  const ResetFilters = () => {
    setTitleSearch("");
    setSortBy("Latest");
    setStatus("Any");
    setAuthor("");
    setKeyword("");
    setCategories([]);
  };

  const HideContent = (data) => {
    hideContent(data)
      .then((res) => {
        queryClient.invalidateQueries("my-content");
        addToast(
          `Content ${data.isHidden ? "is now hidden." : "is now visible."}`,
          { appearance: "success" }
        );
      })
      .catch((err) => {
        addToast(`Action failed.`, {
          appearance: "error",
        });
      });
  };

  return (
    <Provider
      value={{
        keyword,
        setKeyword,
        author,
        setAuthor,
        categories,
        setCategories,
        ResetFilters,
        filters,
        titleSearch,
        page,
        pageCount,
        setPageCount,
        setPage,
        setTitleSearch,
        sortBy,
        setSortBy,
        status,
        setStatus,
        entries,
        setEntries,
        HideContent,
      }}
    >
      {children}
    </Provider>
  );
};

export { MyContentsProvider, MyContentsContext };
