import axios from "axios";
import { authAxios } from "./interceptor";
var env = process.env.NODE_ENV || "development";
const baseUrl = `${
  env === "development"
    ? process.env.REACT_APP_DEV_SERVER
    : process.env.REACT_APP_PROD_SERVER
}/content`;

export const getTrending = () => {
  return axios.get(`${baseUrl}?OrderBy=TrendingNow`).then((res) => {
    return res.data;
  });
};

export const getNewReleases = () => {
  return axios.get(`${baseUrl}?OrderBy=RecentAdded`).then((res) => {
    return res.data;
  });
};

export const getAllContents = (queryKey, page) => {
  const [_key, filter] = queryKey;
  const { category, string } = filter.navbarFilter;
  const { categories, days, rating, contentType } = filter.sidebarFilter;

  if (
    days !== null ||
    rating !== null ||
    string !== "" ||
    contentType.length > 0 ||
    categories.length > 0
  ) {
    var navbarFilter;
    var params = [];
    var daysFilter;
    var ratingsFilter;
    if (category === "Search By Title") {
      navbarFilter = "ByName=" + string;
      params.push(navbarFilter);
    }

    if (category === "Search By Author") {
      navbarFilter = "ByAuthor=" + string;
      params.push(navbarFilter);
    }

    if (category === "Search By Keyword") {
      navbarFilter = "ByKeyword=" + string;
      params.push(navbarFilter);
    }

    if (category === "Search All") {
      navbarFilter = `ByAll=${string}`;
      params.push(navbarFilter);
    }

    if (contentType.length !== 0) {
      contentType.forEach((c) => {
        params.push("ContentType=" + c.id);
      });
    }

    if (days !== null) {
      daysFilter = "Days=" + days;
      params.push(daysFilter);
    }
    if (rating !== null) {
      ratingsFilter = "Ratings=" + rating;
      params.push(ratingsFilter);
    }

    if (categories.length > 0) {
      categories.forEach((c) => {
        params.push("Category=" + c.categoryId);
      });
    }
    params = params.join("&");
    params = params !== "" ? "&" + params : "";

    // console.log(params);

    return axios.get(`${baseUrl}?PageNumber=${page}${params}`).then((res) => {
      return res.data;
    });
  } else {
    return axios.get(`${baseUrl}?PageNumber=${page}`).then((res) => {
      return res.data;
    });
  }
};

export const getGuestContents = (queryKey, page) => {
  const [_key, filter] = queryKey;
  const { category, string } = filter.navbarFilter;
  const { categories, days, rating, contentType } = filter.sidebarFilter;

  if (
    days !== null ||
    rating !== null ||
    string !== "" ||
    contentType.length > 0 ||
    categories.length > 0
  ) {
    var navbarFilter;
    var params = [];
    var daysFilter;
    var ratingsFilter;
    if (category === "Search By Title") {
      navbarFilter = "ByName=" + string;
      params.push(navbarFilter);
    }

    if (category === "Search By Author") {
      navbarFilter = "ByAuthor=" + string;
      params.push(navbarFilter);
    }

    if (category === "Search By Keyword") {
      navbarFilter = "ByKeyword=" + string;
      params.push(navbarFilter);
    }

    if (category === "Search All") {
      navbarFilter = `ByAll=${string}`;
      params.push(navbarFilter);
    }

    if (contentType.length !== 0) {
      contentType.forEach((c) => {
        params.push("ContentType=" + c.id);
      });
    }

    if (days !== null) {
      daysFilter = "Days=" + days;
      params.push(daysFilter);
    }
    if (rating !== null) {
      ratingsFilter = "Ratings=" + rating;
      params.push(ratingsFilter);
    }

    if (categories.length > 0) {
      categories.forEach((c) => {
        params.push("Category=" + c.categoryId);
      });
    }
    params = params.join("&");
    params = params !== "" ? "&" + params : "";

    // console.log(params);

    return axios
      .get(`${baseUrl}/guest-content?PageNumber=${page}${params}`)
      .then((res) => {
        return res.data;
      });
  } else {
    return axios
      .get(`${baseUrl}/guest-content?PageNumber=${page}`)
      .then((res) => {
        return res.data;
      });
  }
};

export const getContent = ({ queryKey }) => {
  const [_key, contentId] = queryKey;
  return axios.get(`${baseUrl}/get-content/${contentId}`).then((res) => {
    // console.warn(res.data);
    return res.data;
  });
};

export const addToFavorites = (contentId) => {
  return authAxios
    .post(`${baseUrl}/add-favorite`, { contentId })
    .then((res) => {
      // console.log(res);
      return res;
    });
};

export const removeToFavorites = (favoriteId) => {
  return authAxios
    .delete(`${baseUrl}/remove-favorite/${favoriteId}`)
    .then((res) => {
      // console.log(res);
      return res;
    });
};

export const newContent = (data) => {
  const token = localStorage.getItem("token");
  return axios
    .post(`${baseUrl}/new-content`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      // console.warn(res);
      return res;
    })
    .catch((error) => {
      // console.warn(error.response.data);
      throw error.response.data;
    });
};

export const myContent = ({ queryKey }) => {
  const [_key, { userId, filters, entries, page }] = queryKey;

  var params = [];

  if (filters.titleSearch !== "" && filters.titleSearch !== undefined)
    params.push("TitleSearch=" + filters.titleSearch);
  if (filters.categories.length > 0) {
    filters.categories.forEach((c) => {
      params.push("Category=" + c.categoryId);
    });
  }
  if (filters.keyword !== "") params.push("Keyword=" + filters.keyword);
  if (filters.author !== "") params.push("Author=" + filters.author);
  if (filters.status === "Any") params.push("Status=" + 0);
  if (filters.status === "Approved") params.push("Status=" + 1);
  if (filters.status === "Pending") params.push("Status=" + 2);
  if (filters.status === "Declined") params.push("Status=" + 3);
  if (filters.status === "Disabled") params.push("Status=" + 4);
  if (filters.sortBy === "Title (A - Z)") params.push("OrderBy=name_asc");
  if (filters.sortBy === "Title (Z - A)") params.push("OrderBy=name_desc");
  if (filters.sortBy === "Latest") params.push("OrderBy=date_desc");
  if (filters.sortBy === "Oldest") params.push("OrderBy=date_asc");

  // 0 - Approved
  // 1 - Pending
  // 2 - Declined
  // 3 - Disabled
  // 4 - Deleted

  params = params.join("&");
  params = params !== "" ? "&" + params : "";

  var result;

  // console.log(
  //   `${baseUrl}/get-user-content-list?UserId=${userId}&PageSize=${entries}&PageNumber=${page}${params}`
  // );

  return authAxios
    .get(
      `${baseUrl}/get-user-content-list?UserId=${userId}&PageSize=${entries}&PageNumber=${page}${params}`
    )
    .then((res) => {
      result = {
        pages: JSON.parse(res.headers.pagination).totalPages,
        data: res.data,
      };
      return result;
    });
};

export const myFavorites = () => {
  return authAxios.get(`${baseUrl}/my-favorite-content-list`).then((res) => {
    return res.data;
  });
};

export const deleteContent = (contentId) => {
  return authAxios
    .delete(`${baseUrl}/delete-content/${contentId}`)
    .then((res) => {
      return res.data;
    });
};

export const updateContent = (contentId, data) => {
  // console.log(contentId);
  return authAxios
    .put(`${baseUrl}/update-content/${contentId}`, data)
    .then((res) => {
      // console.log(res);
      return res.data;
    });
};

export const reportContent = (data) => {
  return authAxios.post(`${baseUrl}/report-content`, data).then((res) => {
    // console.log(res);
    return res.data;
  });
};

export const myFavDashboard = () => {
  return authAxios.get(`${baseUrl}/my-favorites`).then((res) => {
    return res.data;
  });
};

export const hideContent = (data) => {
  return authAxios.put(`${baseUrl}/display-content`, data).then((res) => {
    return res.data;
  });
};

export const deleteUploadedFile = (fileName) => {
  return authAxios
    .delete(`${baseUrl}/delete-uploaded-file/${fileName}`)
    .then((res) => {
      return res.data;
    });
};

export const deleteContentFile = (directoryId) => {
  return authAxios
    .delete(`${baseUrl}/remove-content-file/${directoryId}`)
    .then((res) => {
      return res.data;
    });
};
