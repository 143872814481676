import {
  ClipboardListIcon,
  ExclamationIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";
import React, { useContext } from "react";
import { useQuery } from "react-query";
import { AuthContext } from "../../context/AuthContext";
import { dashboard } from "../../services/admin";
import AdminCards from "./AdminCards";

const AdminDashboard = () => {
  const { data } = useQuery("dashboard", dashboard, {
    refetchOnWindowFocus: false,
  });

  const overallData = [
    {
      name: "Approved Contents",
      count: data && data.totalContents,
      component: ClipboardListIcon,
      route: "/contents",
    },
    {
      name: "Registered Users",
      count: data && data.totalUsers,
      component: UserGroupIcon,
      route: "/users",
    },
    {
      name: "Reported Contents",
      count: data && data.totalReports,
      component: ExclamationIcon,
      route: "/contents",
    },
  ];

  const pendingApprovals = [
    {
      name: "Contents",
      count: data && data.pendingContents,
      component: ClipboardListIcon,
      route: "/contents",
    },
    {
      name: "Users",
      count: data && data.pendingUsers,
      component: UserGroupIcon,
      route: "/users",
    },
    {
      name: "Reported Contents",
      count: data && data.pendingReports,
      component: ExclamationIcon,
      route: "/contents",
    },
  ];

  return (
    <div className="z-0 lg:p-10 mb-20 p-4 w-full overflow-auto space-y-6">
      <div className="text-2xl font-bold text-dark-blue uppercase">
        Dashboard
      </div>
      <div className="">
        <div className="flex items-center w-full text-lg font-bold text-gray-500 mb-5 space-x-4 capitalize italic">
          <span>Total Approvals</span>
        </div>
        <AdminCards items={overallData} />
      </div>
      <div className="">
        <div className="flex items-center w-full text-lg font-bold text-gray-500 mb-5 space-x-4 capitalize italic">
          <span>Pending Approvals</span>
        </div>
        <AdminCards items={pendingApprovals} />
      </div>
    </div>
  );
};

export default AdminDashboard;
