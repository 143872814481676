import React, { useState, useRef, useContext, useEffect } from "react";
import { EpubViewer, ReactEpubViewer } from "react-epub-viewer";
import {
  ArrowsExpandIcon,
  CogIcon,
  SunIcon,
  MoonIcon,
  PlusIcon,
  BookOpenIcon,
  MenuAlt2Icon,
} from "@heroicons/react/outline";
import { Transition, Switch, Disclosure, Menu } from "@headlessui/react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  MinusIcon,
} from "@heroicons/react/solid";
import Loading from "../Others/Loading";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import { useLocation } from "react-router-dom";

var env = process.env.NODE_ENV || "development";
const server = `${
  env === "development"
    ? process.env.REACT_APP_DEV_SERVER
    : process.env.REACT_APP_PROD_SERVER
}`;

const TableOfContents = ({ toc, viewerRef }) => {
  return (
    <Menu as="div" className="ml-auto relative">
      <Menu.Button className="p-2 hover:bg-gray-200 text-gray-500 rounded-md">
        <BookOpenIcon className="w-6 h-6" />
      </Menu.Button>
      <Menu.Items>
        <div className="absolute  z-50 right-0 mt-2 rounded-md shadow-lg overflow-auto">
          <div className="font-bold text-white px-4 bg-Orange py-2">
            Table Of Contents
          </div>
          <div className="bg-white p-2 w-80  max-h-96 overflow-auto">
            {toc.map((item, key) => {
              return (
                <Menu.Item key={key}>
                  <button
                    onClick={() => {
                      viewerRef.current.setLocation(item.href);
                    }}
                    className="text-left px-2 py-1 hover:bg-Orange w-full rounded-md hover:text-white"
                  >
                    {item.label}
                  </button>
                </Menu.Item>
              );
            })}
          </div>
        </div>
      </Menu.Items>
    </Menu>
  );
};

const EpubReader = ({ path }) => {
  const [pageCount, setPageCount] = useState("");
  const [pageLocation, setPageLocation] = useState("");
  const [darkEnabled, setDarkEnabled] = useState(false);
  const [fontSize, setFontSize] = useState(14);
  const [viewSpread, setViewSpread] = useState("auto");
  const [toc, setToc] = useState([]);
  const [url, setUrl] = useState(null);
  const [node, setNode] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const ref = useRef(null);
  const viewerRef = useRef(null);
  const authContext = useContext(AuthContext);

  const viewerStyle = {
    lineHeight: 1.5,
    marginVertical: 20,
    marginHorizontal: 10,
  };

  useEffect(() => {
    if (viewerRef !== null) {
      setNode(viewerRef.current);
      viewerRef.current.style.height = "550px";
      viewerRef.current.style.width = "100%";

      // const epubView = document.querySelectorAll(".epub-view");
    }
  }, [viewerRef]);

  const onPageMove = (direction) => {
    if (direction == "next") node.nextPage();
    if (direction == "prev") node.prevPage();
  };
  const location = useLocation();

  const fullscreen = () => {
    const epubViewer = document.querySelector("#epubviewer");
    if (epubViewer.requestFullscreen) {
      epubViewer.requestFullscreen();
    } else if (epubViewer.webkitRequestFullscreen) {
      /* Safari */
      epubViewer.webkitRequestFullscreen();
    } else if (epubViewer.msRequestFullscreen) {
      /* IE11 */
      epubViewer.msRequestFullscreen();
    }
  };

  const exitFullscreen = () => {
    document.webkitExitFullscreen();
  };

  useEffect(() => {
    // setIsFullscreen(document.fullscreenElement !== null ? true : false);
    document.onfullscreenchange = (e) => {
      setIsFullscreen(document.fullscreenElement !== null ? true : false);
    };
  });

  useEffect(() => {
    // uri: `${server}/Admin/view-content/file/${path}?access_token=${authContext.authState.token}`,
    if (location.pathname.includes("/admin")) {
      axios
        .get(
          `${server}/Admin/view-content/file/${path}?access_token=${authContext.authState.token}`,
          { responseType: "blob" }
        )
        .then((res) => {
          setUrl(res.data);
        });
    } else {
      axios
        .get(`${server}/content/${path}`, { responseType: "blob" })
        .then((res) => {
          setUrl(res.data);
        });
    }
  }, [path]);

  //server + path; //sampleepub; //`${server}${path}`; //sampleepub; //`https://f447-203-189-118-168.ngrok.io/content/f0b14b37-40fc-4a5f-ab47-8519d7461b60`;

  return (
    <div
      className={`space-y-4 bg-black ${isFullscreen ? "py-0" : "py-10"}`}
      id="epubviewer"
    >
      <div
        className={`flex px-10 ${
          isFullscreen ? "absolute z-20 top-5 right-2" : ""
        }`}
      >
        <TableOfContents toc={toc} viewerRef={viewerRef} />
        <Menu as="div" className="relative">
          <Menu.Button className="p-2 hover:bg-gray-200 text-gray-500 rounded-md ">
            <CogIcon className="w-6 h-6" />
          </Menu.Button>
          <Menu.Items>
            <div className="z-10 p-3 absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="space-y-2 mb-3">
                <span className="font-semibold text-gray-500 text-sm">
                  Theme
                </span>
                <div className="flex items-center text-gray-700 w-full justify-center space-x-4">
                  <Switch
                    checked={darkEnabled}
                    onChange={setDarkEnabled}
                    className={`${
                      darkEnabled ? "bg-CSIBlue" : "bg-gray-200"
                    } relative inline-flex items-center transition h-8 rounded-full w-14`}
                  >
                    <span
                      className={`${
                        darkEnabled ? "translate-x-7" : "translate-x-1"
                      } inline-block p-1 transform transition bg-white rounded-full text-gray-500`}
                    >
                      {darkEnabled ? (
                        <MoonIcon className="h-4 w-4 text-CSIBlue" />
                      ) : (
                        <SunIcon className="h-4 w-4 " />
                      )}
                    </span>
                  </Switch>
                  <span className="font-bold">
                    {darkEnabled ? "Dark" : "Light"}
                  </span>
                </div>
              </div>
              <div className="space-y-2 py-2">
                <span className="font-semibold text-gray-500 text-sm">
                  Font Size
                </span>
                <div>
                  <div className="flex justify-center items-center gap-x-4">
                    <button
                      className="p-2 border rounded-full"
                      onClick={() => {
                        const newFontSize = fontSize - 1;
                        if (fontSize > 12) setFontSize(newFontSize);
                      }}
                    >
                      <MinusIcon className="h-3 w-3" />
                    </button>
                    <span
                      className="font-bold"
                      style={{ fontSize: fontSize + "px" }}
                    >
                      Aa
                    </span>
                    <button
                      className="p-2 border rounded-full"
                      onClick={() => {
                        const newFontSize = fontSize + 1;
                        setFontSize(newFontSize);
                      }}
                    >
                      <PlusIcon className="h-3 w-3" />
                    </button>
                  </div>
                  <div className="text-center text-xs text-gray-500 font-bold">
                    {fontSize}
                  </div>
                </div>
              </div>
              <div className="space-y-2 py-2">
                <span className="font-semibold text-gray-500 text-sm ">
                  View Spread
                </span>
                <div className="flex items-center space-x-4  justify-center">
                  <div className="flex flex-col items-center space-y-2">
                    <button
                      className={`border-2 p-1 rounded-full ${
                        viewSpread === "auto"
                          ? "border-CSIBlue text-white bg-CSIBlue "
                          : "border-gray-400 text-gray-500"
                      }`}
                      onClick={() => {
                        setViewSpread("auto");
                      }}
                    >
                      <BookOpenIcon className="h-5 w-5 " />
                    </button>
                    <span
                      className={`text-xs font-bold ${
                        viewSpread === "auto" ? "text-CSIBlue" : "text-gray-500"
                      } `}
                    >
                      Two Page View
                    </span>
                  </div>
                  <div className="flex flex-col items-center space-y-2">
                    <button
                      className={`border-2 p-1 rounded-full ${
                        viewSpread === "none"
                          ? "border-CSIBlue text-white bg-CSIBlue "
                          : "border-gray-400 text-gray-500"
                      }`}
                      onClick={() => {
                        setViewSpread("none");
                      }}
                    >
                      <MenuAlt2Icon className="h-5 w-5" />
                    </button>
                    <span
                      className={`text-xs font-bold ${
                        viewSpread === "none" ? "text-CSIBlue" : "text-gray-500"
                      } `}
                    >
                      One Page View
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Menu.Items>
        </Menu>
        {isFullscreen ? (
          <button
            className=" hover:bg-gray-200 text-gray-500 rounded-md"
            onClick={() => {
              exitFullscreen();
            }}
          >
            <i class="bx bx-exit-fullscreen bx-sm p-2"></i>
          </button>
        ) : (
          <button
            className="p-2 hover:bg-gray-200 text-gray-500 rounded-md"
            onClick={() => {
              fullscreen();
            }}
          >
            <ArrowsExpandIcon className="w-6 h-6" />
          </button>
        )}
      </div>

      <div className=" h-auto flex items-center bg-white p-0 justify-center">
        <div
          className={` ${
            darkEnabled ? "bg-gray-600 " : "bg-white"
          } relative items-center flex`}
        >
          <ReactEpubViewer
            className="absolute top-0 h-full object-fit "
            url={url}
            ref={viewerRef}
            onPageChange={(page) => {
              setPageCount(page.totalPage);
              setPageLocation(page.currentPage);
            }}
            loadingView={
              <div
                className="bg-none p-40 w-screen "
                style={{ height: "700px" }}
              >
                <Loading loading={true} />
              </div>
            }
            onTocChange={(e) => {
              // setTOC(e);
              if (toc.length === 0) {
                setToc(e);
              }
            }}
            viewerStyle={{ fontSize, ...viewerStyle }}
            // viewerOption={{ spread: viewSpread }}
          />
          {pageLocation !== "" && (
            <button
              className="opacity-10 z-20 hover:opacity-100 transition hover:text-Orange absolute -left-10"
              onClick={() => {
                onPageMove("prev");
              }}
            >
              <ChevronLeftIcon className="h-20 w-20" />
            </button>
          )}
          {pageLocation !== "" && (
            <button
              className="opacity-10  z-20 hover:opacity-100 transition hover:text-Orange absolute -right-10"
              onClick={() => {
                onPageMove("next");
              }}
            >
              <ChevronRightIcon className="h-20 w-20" />
            </button>
          )}
        </div>
      </div>

      {pageLocation !== "" && (
        <div
          className={`flex w-full justify-center font-bold text-lg ${
            isFullscreen
              ? "absolute bottom-5 text-black opacity-40 hover:opacity-70"
              : " text-white"
          }`}
        >
          {pageLocation} of {pageCount}
        </div>
      )}
    </div>
  );
};

export default EpubReader;
