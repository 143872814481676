import React, { useState, useEffect, useContext, createContext } from "react";
import { useQuery } from "react-query";
import { Link, withRouter } from "react-router-dom";
import { register, getCountryCode } from "../services/accounts";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import Textbox from "../components/Inputs/Textbox";
import Modal from "../components/Others/Modal";
import Loading from "../components/Others/Loading";
import { FlatButton } from "../components/Buttons/Buttons";
import ReactSelect from "../components/Inputs/ReactSelect";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const SignUp = () => {
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [MiddleName, setMiddleName] = useState("");
  const [Email, setEmail] = useState("");
  const [Program, setProgram] = useState("");
  const [UserName, setUsername] = useState("");
  const [Password, setPassword] = useState("");
  // const countryCodeContext = useContext(CountryCodeContext);

  const [CountryCode, setCountryCode] = useState("PH");
  const [DialCode, setDialCode] = useState("63");
  const [PhoneNumber, setPhoneNumber] = useState("");

  const [AgreeToTerms, setAgreeToTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const history = useHistory();

  const authContext = useContext(AuthContext);
  const [success, setSuccess] = useState(false);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState([]);
  const [phoneError, setPhoneError] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [countryCodeError, setCountryCodeError] = useState("");
  const [programError, setProgramError] = useState("");
  const { data } = useQuery("countryCodeOptionFilters", getCountryCode);

  if (authContext.isAuthenticated()) history.push("/");

  // useEffect(() => {
  //   setCodeAndPhone(CountryCode + PhoneNumber);
  //   console.log(CountryCode + PhoneNumber);
  // }, [CountryCode, PhoneNumber]);

  const onChangeSelectCountry = (items) => {
    // console.log(items);
    setCountryCode(items);
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    setNameError("");
    setPhoneError("");
    setEmailError("");
    setPasswordError([]);
    setUsernameError("");

    let signUpData = {
      FirstName,
      LastName,
      MiddleName,
      Email,
      UserName,
      Password,
      DialCode,
      CountryCode,
      PhoneNumber,
      Program,
      CodeId: null,
    };

    // console.log("signUpData", signUpData);
    register(signUpData)
      .then((result) => {
        setTimeout(() => {
          // console.log(result);
          if (result.status === 200) {
            setSuccess(true);
          } else {
            if (result.data) {
              // console.log(result);
              if (typeof result.data === "object") {
                if (result.data.errors)
                  // console.log(result.data.errors)
                  setPhoneError(result.data.errors.PhoneNumber[0]);
                else setPasswordError(result.data);
              } else {
                if (result.data.includes("Email")) setEmailError(result.data);

                if (result.data.includes("Username"))
                  setUsernameError(result.data);
              }
            }
          }
          setLoading(false);
        }, 1000);
      })
      .catch((e) => {
        // console.log(e);
        setLoading(false);
      });
  };

  return (
    <div className="flex justify-center items-center w-screen h-screen overflow-auto">
      <Modal
        title="Registration Pending"
        description="Please wait for your registration to be approved by the administrators, Thank you for signing up!"
        isOpen={success}
        onClose={() => {}}
      >
        <div className="flex items-center space-x-2 float-right">
          <FlatButton
            className="btn-Orange-secondary"
            onClick={() => history.push("/")}
          >
            Browse Contents as Guest
          </FlatButton>
          <FlatButton
            className="bg-gray-100 text-gray-600 "
            onClick={() => history.push("/signin")}
          >
            Login Page
          </FlatButton>
        </div>
      </Modal>
      <div className="absolute h-screen w-screen top-0 left-1/2 z-0 bg-orange transform rotate-45 shadow-xl"></div>
      <div className=" flex-1 md:flex hidden flex-col relative z-10 items-end  text-gray-600">
        <div className="w-6/12 float-right space-y-1">
          <div className="px-20 font-bold text-2xl">Register Your Account</div>
          <div className="px-20 text-left text-gray-400">
            Be a Member of Catholic Safeguarding Institute Now.
          </div>
        </div>
      </div>
      <div className="sm:w-full flex-1 px-10 xl:px-0 ">
        <div className="bg-white xl:w-8/12 w-full overflow-hidden p-6 shadow-xl sm:rounded-3xl lg:p-8  relative ">
          {loading && (
            <Loading loading={loading} text="Creating Your Account" />
          )}
          <h2 className="mb-4 text-left text-xl font-bold text-Orange">
            Personal Details
          </h2>
          <form
            className="space-y-4"
            autoComplete="off"
            onSubmit={(e) => handleRegister(e)}
          >
            <div className="sm:flex sm:space-x-3 w-full">
              <div className="w-full">
                <Textbox
                  name="FirstName"
                  label="First Name"
                  type="text"
                  value={FirstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>
              <div className="w-full">
                <Textbox
                  name="MiddleName"
                  label="Middle Name"
                  type="text"
                  value={MiddleName}
                  onChange={(e) => setMiddleName(e.target.value)}
                />
              </div>
              <div className="w-full">
                <Textbox
                  name="LastName"
                  label="Last Name"
                  type="text"
                  value={LastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>
            </div>
            <span className="text-xs text-red-500">{nameError}</span>

            <div>
              <Textbox
                name="Email"
                label="Email"
                type="email"
                value={Email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <span className="text-xs text-red-500">{emailError}</span>

            <div>
              <Textbox
                name="UserName"
                label="Username"
                type="text"
                value={UserName}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <span className="text-xs text-red-500">{usernameError}</span>

            <div>
              <Textbox
                name="Password"
                label="Password"
                type="password"
                value={Password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <div className="pt-1">
                <div class="pt-1">
                  <div class="text-xs text-gray-600">
                    Password must be at least 8 characters with one uppercase
                    letter, one lowercase letter, and one number or special
                    character.
                  </div>
                  {/* <div class="text-xs text-red-500">Passwords must have at least one non alphanumeric character.</div>
                  <div class="text-xs text-red-500">Passwords must have at least one lowercase ('a'-'z').</div>
                  <div class="text-xs text-red-500">Passwords must have at least one uppercase ('A'-'Z').</div> */}
                </div>
                {/* {passwordError.map((e, key) => {
                  return (
                    <div key={key} className="text-xs text-red-500">
                      {e.description}
                    </div>
                  );
                })} */}
              </div>
            </div>
            <div>
              <Textbox
                name="Program"
                label="Program"
                type="text"
                value={Program}
                onChange={(e) => setProgram(e.target.value)}
                required
              />
            </div>
            <div className="text-sm font-semibold text-gray-500 ">
              <label className="flex text-sm font-semibold text-gray-500 gap-x-1">
                Phone
                <span className="text-red-500">*</span>
              </label>
            </div>
            <div className="sm:flex sm:space-x-3 w-full">
              <div className="w-full">
                {/* <ReactSelect
                  onChange={onChangeSelectCountry}
                  options={countryCodeContext.countryCodeOptions}
                  value={CountryCode}
                  className="w-full"
                  defaultValue={{ label: "63" }}
                  isMulti={false}
                /> */}
                <PhoneInput
                  inputClass="appearance-none block w-full px-3 py-5 bg-gray-50 rounded-md shadow-inner shadow-md placeholder-gray-400 focus:outline-none focus:ring-offset-2 focus:ring-2 focus:outline-none focus:ring-LightOrange sm:text-sm flex-1"
                  containerClass="flex items-center"
                  country={CountryCode}
                  value={DialCode}
                  onChange={(phone, country) => {
                    setPhoneNumber(phone);
                    setDialCode(country.dialCode);
                    setCountryCode(country.countryCode);
                  }}
                  countryCodeEditable={true}
                  inputProps={{
                    required: true,
                  }}
                />
              </div>
              {/* <div className="w-full">
                <Textbox
                  name="PhoneNumber" 9123 91
                  type="text"
                  className="md:w-full"
                  value={PhoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="9876543210"
                  required
                />
              </div> */}
            </div>
            <span className="text-xs text-red-500">{phoneError}</span>

            <div className="flex items-center justify-center text-gray-600">
              <div className="flex items-center">
                <input
                  id="AgreeToTerms"
                  name="AgreeToTerms"
                  type="checkbox"
                  className="hidden"
                  value={AgreeToTerms}
                  onChange={(e) => setAgreeToTerms(e.currentTarget.checked)}
                />
                <label htmlFor="AgreeToTerms">
                  {AgreeToTerms ? (
                    <i className="bx bxs-check-square bx-sm py-2 px-0 m-0 text-Orange"></i>
                  ) : (
                    <i className="bx bx-checkbox bx-sm py-2 px-0 m-0"></i>
                  )}
                </label>

                <label
                  htmlFor="AgreeToTerms"
                  className="flex items-center space-x-1 block text-sm text-gray-600 font-semibold"
                >
                  I agree to the{" "}
                  <Link
                    to="/terms-of-service"
                    className="italic hover:underline hover:text-Orange"
                  >
                    Terms of Service
                  </Link>{" "}
                  <div>and</div>
                  <Link
                    to="/privacy-policy"
                    className="italic hover:underline  hover:text-Orange"
                  >
                    Privacy Policy
                  </Link>
                </label>
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={!AgreeToTerms}
                className={
                  AgreeToTerms
                    ? "w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-Orange hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-LightOrange"
                    : "w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-300 hover:bg-opacity-80"
                }
              >
                Register
              </button>
            </div>
            <div className="text-sm text-center text-gray-600 font-semibold">
              Already have an account?{" "}
              <Link to="/signin" className="hover:underline hover:text-Orange">
                Sign In here
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SignUp);
