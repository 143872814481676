import React, { useState, useContext, useEffect } from "react";
import { EyeIcon } from "@heroicons/react/outline";
import { useQuery } from "react-query";
import { FlatButton } from "../../Buttons/Buttons";
import ContentError from "../../Others/ContentError";
import Loading from "../../Others/Loading";
import { getReportedContents } from "../../../services/admin";
import moment from "moment";
import { useHistory } from "react-router-dom";
import DataTable from "../../Base/DataTable";
import { ReportsContext } from "../../../context/ReportsContext";

const Table = () => {
  const context = useContext(ReportsContext);
  const filters = context.filters;
  const entries = context.entries;
  const page = context.page;
  const history = useHistory();
  const { data, isError, isLoading } = useQuery(
    ["reports", { filters, entries, page }],
    getReportedContents
  );

  const headers = [
    "Content Name",
    "Author",
    "Downloads",
    "Reports",
    "Reported Date",
    "Actions",
  ];

  if (isLoading) {
    return (
      <div className="animate-pulse">
        <div className="w-full h-96 border rounded-md p-5 space-y-6 overflow-hidden relative flex justify-center">
          <Loading loading={true} />
          <span className="font-bold text-gray-500 text-CSIBlue">
            Loading Contents
          </span>
        </div>
      </div>
    );
  }

  if (isError) {
    return <ContentError />;
  }

  if (!isLoading) {
    context.setPageCount(data.pages);
  }

  return (
    <DataTable headers={headers} data={data.data}>
      {data.data.map(
        ({ author, contentName, reportDate, reportId, contentId }) => {
          return (
            <tr className="text-center" key={reportId}>
              <td className="p-2 w-3/12">{contentName}</td>
              <td className="p-2 w-3/12">{author}</td>
              <td className="p-2"></td>
              <td></td>
              <td className="p-2">
                {moment.utc(reportDate).local().format("lll")}
              </td>
              <td className="p-2 flex justify-center items-center">
                <FlatButton
                  onClick={() => {
                    history.push(`/content-view/${contentId}/admin`);
                  }}
                  className={`bg-CSIBlue hover:bg-opacity-70 hover:bg-CSIBlue text-white flex gap-x-2 `}
                >
                  <span>View</span>
                  <EyeIcon className="h-5 w-5" />
                </FlatButton>
              </td>
            </tr>
          );
        }
      )}
    </DataTable>
  );
};

export default Table;
