import React, { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { FlatButton } from "../components/Buttons/Buttons";
import Textbox from "../components/Inputs/Textbox";
import Loading from "../components/Others/Loading";
import { AuthContext } from "../context/AuthContext";
import queryString from "query-string";
import { resetPassword } from "../services/accounts";
import SignInError from "../components/Alerts/SignInError";
import Modal from "../components/Others/Modal";

const ResetPassword = () => {
  const history = useHistory();
  const { search } = useLocation();
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [modal, setModal] = useState(false);

  if (authContext.isAuthenticated()) history.push("/");

  const Submit = (e) => {
    e.preventDefault();
    var { email, token } = queryString.parse(search);
    setLoading(true);
    var token = token.split(" ").join("+");

    setTimeout(() => {
      if (confirmPassword !== newPassword) {
        setError("Password fields must match.");
        setLoading(false);
      } else {
        setError("");
        const data = { email, token, newPassword };
        // console.log(data);
        resetPassword(data).then((res) => {
          if (res.status === 200) {
            setModal(true);
          } else {
            setError(res.data[0].description);
          }
          // console.log(res);
          setLoading(false);
        });
      }
    }, 2000);
  };

  return (
    <div className="h-screen w-screen flex items-center justify-center">
      <Modal
        title="Password Changed"
        description="Your password has been changed, you can now login your account with your new password."
        isOpen={modal}
        onClose={() => {}}
      >
        <FlatButton
          className="btn-Orange-secondary float-right"
          onClick={() => {
            history.push("/signin");
          }}
        >
          Go to Login Page
        </FlatButton>
      </Modal>
      <div className="absolute h-screen w-screen top-0 right-1/2 z-0 bg-orange transform rotate-45 shadow-xl"></div>
      <div className="flex relative z-10 flex-col min-w-md items-center  space-y-4">
        <div className=" w-full p-4 space-y-2">
          <div className="font-bold text-2xl text-gray-600">
            Password Recovery
          </div>
          <div className="text-gray-600">
            Please enter your new password to recover your account.
          </div>
        </div>
        <div className="p-10 relative rounded-3xl w-full shadow-xl bg-white">
          {loading && <Loading loading={true} />}
          <form onSubmit={(e) => Submit(e)}>
            <div className="space-y-4">
              <div className="text-Orange font-bold text-xl">
                Reset Password
              </div>

              <div className="space-y-4">
                <Textbox
                  label="New Password"
                  type="password"
                  required={true}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <Textbox
                  label="Confirm Password"
                  type="password"
                  required={true}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
              {error && (
                <div>
                  <SignInError errors={error} />
                </div>
              )}
              <div>
                
                <FlatButton className="btn-Orange-primary w-full justify-center">
                  Change Password
                </FlatButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
