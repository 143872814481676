import React from "react";
import Loading from "../../../Others/Loading";

const TableLoading = () => {
  return (
    <div className="animate-pulse">
      <div className="w-full h-96 rounded-3xl shadow-xl p-5 space-y-6 overflow-hidden relative flex justify-center">
        <Loading loading={true} />
      </div>
    </div>
  );
};

export default TableLoading;
