import React, { useState, useRef, useContext } from "react";
import { getContent } from "../services/contents";
import { useQuery } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import { FlatButton } from "../components/Buttons/Buttons";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import ReactPlayer from "react-player";
import { PauseIcon, PlayIcon } from "@heroicons/react/solid";
import { AuthContext } from "../context/AuthContext";
import { ArrowNarrowLeftIcon } from "@heroicons/react/solid";
import moment from "moment";
import ContentReview from "../components/Contents/ContentReview";
import RatingToStar from "../components/Others/RatingToStar";
import ReportModal from "../components/Others/ReportModal";
import ReviewModal from "../components/Others/ReviewModal";
import Loading from "../components/Others/Loading";
import { DownloadIcon, ChatAlt2Icon } from "@heroicons/react/outline";

const ContentWatch = () => {
  let { contentId } = useParams();
  const [play, setPlay] = useState(false);
  const [progress, setProgress] = useState(0);
  const videoRef = useRef();
  const authContext = useContext(AuthContext);
  const [writeReviewModal, setWriteReviewModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [loading, setLoading] = useState(false);

  var env = process.env.NODE_ENV || "development";
  const server = `${
    env === "development"
      ? process.env.REACT_APP_DEV_SERVER
      : process.env.REACT_APP_PROD_SERVER
  }/content/`;

  const history = useHistory();
  const { data, isLoading, isError } = useQuery(
    ["content", contentId],
    getContent
  );

  if (isError) {
    return <div></div>;
  }

  if (isLoading) {
    return <div></div>;
  }

  if (data) {
    return (
      <div className="h-screen w-screen overflow-auto relative justify-center space-y-4">
        <ReportModal
          isOpen={reportModal}
          onClose={() => {
            setReportModal(false);
          }}
          setLoading={setLoading}
        />
        <ReviewModal
          isOpen={writeReviewModal}
          onClose={() => {
            setWriteReviewModal(false);
          }}
          contentId={data.contentId}
          setLoading={setLoading}
        />
        {loading && <Loading loading={true} />}
        <div
          className="flex justify-center w-full overflow-hidden bg-black relative bg-opacity-90 shadow-xl "
          style={{ height: "700px" }}
          onContextMenu={(e) => e.preventDefault()}
        >
          <FlatButton
            className="absolute top-5 left-5 z-40 transition opacity-50 hover:opacity-100 text-white hover:bg-opacity-20"
            onClick={() => {
              history.push(`/content-view/${data.contentId}`);
            }}
          >
            <ArrowNarrowLeftIcon className="h-5 w-5 mr-2" />
            Back to Content
          </FlatButton>
          <video
            controlsList="nodownload"
            controls
            disablePictureInPicture
            className="absolute top-0 h-full object-fit outline-none"
          >
            <source src={server + data.contentId} />
          </video>
        </div>
        <div className="flex justify-center w-full">
          <div className="w-7/12 flex flex-col space-y-2  pb-20 ">
            <div className="text-4xl font-bold text-Orange flex items-start">
              {data.name}
              {authContext.isAuthenticated() ? (
                <div className="ml-auto flex items-center space-x-2">
                  <FlatButton
                    className="btn-Gray"
                    onClick={() => {
                      setReportModal(true);
                    }}
                  >
                    Report Content
                  </FlatButton>
                  <FlatButton
                    className="btn-Orange-primary"
                    onClick={() => {
                      setWriteReviewModal(true);
                    }}
                  >
                    Write a Review
                  </FlatButton>
                </div>
              ) : (
                <div className="space-x-2 ml-auto flex items-center">
                  <FlatButton
                    className="btn-Orange-primary"
                    onClick={() => {
                      history.push("/signin");
                    }}
                  >
                    Sign in to submit your review
                  </FlatButton>
                </div>
              )}
            </div>
            <span className="text-gray-500 font-semibold">
              {data.description}
            </span>
            <div className="flex flex-col gap-y-1 items-start justify-start text-sm font-semibold">
              <div className="flex items-center">
                <RatingToStar rating={data.ratings} size={4} />
                <span className="ml-2">{data.ratings || 0} out of 5</span>
              </div>
              <div className="flex items-center gap-x-2">
                {data.contentReviews.length || 0} Reviews
                <ChatAlt2Icon className="h-4 w-4" />
              </div>
              <div className="flex items-center gap-x-2">
                {data.numberOfDownloads} Downloads
                <DownloadIcon className="h-4 w-4" />
              </div>
            </div>
            <div className="text-gray-500 text-sm flex flex-col">
              <span className="font-semibold">{data.submittedBy}</span>
              <span className="text-gray-400 text-xs">
                {moment(data.uploadedDate).format("lll")}
              </span>
            </div>
            <div className="text-Orange font-bold text-lg">Reviews</div>
            {data.contentReviews.length > 0 ? (
              <div className="space-y-2">
                {data.contentReviews.map((review, key) => {
                  return <ContentReview key={key} review={review} />;
                })}
              </div>
            ) : (
              <div className="flex justify-center w-full py-10 text-2xl font-bold text-gray-400">
                This content has no reviews yet.
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default ContentWatch;
