import React from "react";
import { getTrending } from "../../../services/contents";
import { useQuery } from "react-query";

import Carousel from "../../Others/Carousel";
import ContentSkeleton from "../../Others/ContentSkeleton";
import ContentError from "../../Others/ContentError";

const Trending = () => {
  const { data, isLoading, isError } = useQuery("trending", getTrending);

  if (isError) {
    return <ContentError />;
  }

  if (isLoading) {
    return <ContentSkeleton />;
  }

  if (data) {
    return <Carousel data={data.slice(0, 8)} name="Trending" />;
  }
};

export default Trending;
