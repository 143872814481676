import React from "react";

const TextArea = ({
  label,
  onChange,
  className,
  placeholder,
  value,
  disabled = false,
  style,
  textAreaClassName,
}) => {
  return (
    <div className={`w-full flex flex-col space-y-2 ${className}`}>
      <label className="text-sm font-semibold text-gray-500">{label}</label>
      <textarea
        placeholder={placeholder}
        style={style}
        value={value}
        disabled={disabled}
        className={`bg-gray-50 resize-none shadow-inner rounded-md shadow-md px-2 py-1 outline-none input h-40 max-h-40 min-h-full focus:ring-2 focus:ring-offset-2 focus:ring-LightOrange focus:ring-opacity-0 ${textAreaClassName}`}
        onChange={onChange}
      ></textarea>
    </div>
  );
};

export default TextArea;
