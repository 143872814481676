import {
  ArrowNarrowLeftIcon,
  AtSymbolIcon,
  BanIcon,
  BriefcaseIcon,
  CakeIcon,
  CheckIcon,
  ChevronLeftIcon,
  ClockIcon,
  IdentificationIcon,
  MailIcon,
  PencilAltIcon,
  PhoneIcon,
  SaveAsIcon,
} from "@heroicons/react/outline";
import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import AdminSidebar from "../components/Base/AdminSidebar";
import { FlatButton } from "../components/Buttons/Buttons";
import RadioButton from "../components/Inputs/RadioButton";
import { useQuery } from "react-query";
import { getUser } from "../services/admin";
import ContentError from "../components/Others/ContentError";
import { UsersContext, UsersProvider } from "../context/UsersContext";
import Loading from "../components/Others/Loading";
import moment from "moment";
import Modal from "../components/Others/Modal";
import { useToasts } from "react-toast-notifications";

var env = process.env.NODE_ENV || "development";
const server = `${
  env === "development"
    ? process.env.REACT_APP_DEV_SERVER
    : process.env.REACT_APP_PROD_SERVER
}`;

const Pill = ({ status, color, component: Component }) => {
  return (
    <div
      className={`text-sm font-semibold bg-${color}-100 text-${color}-600 px-5 rounded-full py-1 flex items-center gap-x-2`}
    >
      {status}
      {Component && <Component className="h-4 w-4" />}
    </div>
  );
};

const ChangeRole = ({ isOpen, onClose, role, userId }) => {
  const context = useContext(UsersContext);
  const [newRole, setNewRole] = useState(role);
  const { addToast } = useToasts();

  const memberId = "A4ED29C6-A3EF-4D75-B359-7473B7AAA776".toLowerCase();
  const adminId = "9456F739-84E0-426E-AED6-8DFD023ED343".toLowerCase();

  return (
    <Modal
      title="Change Role"
      description="Please select the role you want to set for this user."
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="space-y-4">
        <div className="flex bg-gray-100 p-2 rounded-full mx-20 space-x-2">
          <FlatButton
            className={`justify-center rounded-full flex-1 ${
              newRole === "Admin" ? "btn-Orange-primary " : "text-gray-600"
            }`}
            onClick={() => {
              setNewRole("Admin");
            }}
          >
            Admin
          </FlatButton>
          <FlatButton
            className={`justify-center rounded-full flex-1 ${
              newRole === "Member" ? "btn-Orange-primary " : "text-gray-600"
            }`}
            onClick={() => {
              setNewRole("Member");
            }}
          >
            Member
          </FlatButton>
        </div>
        <div className="flex justify-end space-x-2">
          <FlatButton className="btn-Gray" onClick={onClose}>
            Cancel
          </FlatButton>
          <FlatButton
            className="btn-Orange-primary px-5"
            onClick={() => {
              if (newRole === role) {
              } else {
                context.UpdateUserRole({
                  id: userId,
                  role: newRole === "Admin" ? adminId : memberId,
                });
              }
              onClose();
            }}
          >
            Save
          </FlatButton>
        </div>
      </div>
    </Modal>
  );
};

const ChangeStatus = ({ isOpen, onClose, status, userId }) => {
  const context = useContext(UsersContext);
  const [pendingStatus, setPendingStatus] = useState(status);
  const [activeStatus, setActiveStatus] = useState(status);

  if (status === "Active" || status === "Disabled") {
    return (
      <Modal
        title="Change Status"
        description="Please select the status you want to set for this user."
        isOpen={isOpen}
        onClose={onClose}
      >
        <div className="space-y-4">
          <div className="flex bg-gray-100 p-2 rounded-full mx-20 space-x-2">
            <FlatButton
              className={`justify-center rounded-full flex-1 ${
                activeStatus === "Active"
                  ? "btn-Orange-primary "
                  : "text-gray-600"
              }`}
              onClick={() => {
                setActiveStatus("Active");
              }}
            >
              Active
            </FlatButton>
            <FlatButton
              className={`justify-center rounded-full flex-1 ${
                activeStatus === "Disabled"
                  ? "btn-Orange-primary "
                  : "text-gray-600"
              }`}
              onClick={() => {
                setActiveStatus("Disabled");
              }}
            >
              Disabled
            </FlatButton>
          </div>
          <div className="flex justify-end space-x-2">
            <FlatButton className="btn-Gray" onClick={onClose}>
              Cancel
            </FlatButton>
            <FlatButton
              className="btn-Orange-primary px-5"
              onClick={() => {
                if (activeStatus === status) {
                } else {
                  context.SetUserStatus({
                    id: userId,
                    status: activeStatus === "Active" ? 2 : 3,
                  });
                }
                onClose();
              }}
            >
              Save
            </FlatButton>
          </div>
        </div>
      </Modal>
    );
  }

  if (status === "Pending") {
    return (
      <Modal
        title="Respond to Request"
        description="Please select the status you want to set for this user."
        isOpen={isOpen}
        onClose={onClose}
      >
        <div className="space-y-4">
          <div className="flex bg-gray-100 p-2 rounded-full mx-10 gap-x-2">
            <FlatButton
              className={`justify-center rounded-full flex-1 ${
                pendingStatus === "Pending"
                  ? "btn-Orange-primary "
                  : "text-gray-600"
              }`}
              onClick={() => {
                setPendingStatus("Pending");
              }}
            >
              Accept
            </FlatButton>
            <FlatButton
              className={`justify-center rounded-full flex-1 ${
                pendingStatus === "Decline"
                  ? "btn-Orange-primary "
                  : "text-gray-600"
              }`}
              onClick={() => {
                setPendingStatus("Decline");
              }}
            >
              Decline
            </FlatButton>
          </div>
          <div className="flex justify-end space-x-2">
            <FlatButton className="btn-Gray" onClick={onClose}>
              Cancel
            </FlatButton>
            <FlatButton
              className="btn-Orange-primary px-5"
              onClick={() => {
                onClose();
                context.SetUserStatus({
                  id: userId,
                  status: pendingStatus === "Pending" ? 2 : 4,
                });
              }}
            >
              Save
            </FlatButton>
          </div>
        </div>
      </Modal>
    );
  }

  return <div></div>;
};

const Content = () => {
  let userId = useParams();

  const context = useContext(UsersContext);

  const [edit, setEdit] = useState(false);
  const { data, isError, isLoading } = useQuery(["user", userId], getUser);
  const [status, setStatus] = useState("");
  const [value, setValue] = useState(null);
  const [changeStatus, setChangeStatus] = useState(false);
  const [changeRole, setChangeRole] = useState(false);
  const [role, setRole] = useState("");

  if (isLoading) {
    return (
      <div className="animate-pulse space-y-7">
        <div className=" space-y-7  bg-gray-300 rounded-bl-3xl  p-10">
          <div className="h-80"></div>
        </div>
        <div className="flex justify-center px-10">
          <div className="w-96 h-40 bg-gray-300 rounded-3xl"></div>
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="space-y-7">
        <div className=" space-y-7 flex justify-center items-center bg-gray-300 rounded-bl-3xl  p-10">
          <div className="h-80">
            <ContentError />
          </div>
        </div>
        <div className="flex justify-center px-10">
          <div className="w-96 h-40 bg-gray-300 rounded-3xl"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-7">
      <div className="rounded-bl-3xl bg-orange flex items-center justify-center sm:pt-12 pt-20 py-12 px-20 shadow-xl">
        <Loading loading={context.loading} />
        <ChangeStatus
          isOpen={changeStatus}
          onClose={() => {
            setChangeStatus(false);
          }}
          userId={data.userId}
          status={status ? status : data.userStatus}
        />
        <ChangeRole
          isOpen={changeRole}
          onClose={() => {
            setChangeRole(false);
          }}
          userId={data.userId}
          role={role ? role : data.roleName}
        />
        <div className="transform flex flex-col items-center w-10/12 gap-y-5 ">
          <div className="flex-none">
            <div className="h-56 w-56 bg-Orange rounded-full overflow-hidden ring-8 ring-Orange">
              <img
                src={
                  data.profilePictureUrl
                    ? server + data.profilePictureUrl
                    : "https://i.ibb.co/grJqb1t/Avatar-Sample-02.png"
                }
                className="h-full w-full object-cover"
              ></img>
            </div>
          </div>
          <div className="font-bold text-3xl flex flex-col items-center text-center text-white">
            <div>{data.fullName}</div>
            <div className="flex items-center capitalize font-semibold text-lg text-white opacity-70 ">
              <AtSymbolIcon className="h-5 w-5" /> {data.userName}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center sm:px-10 px-0">
        <div className=" bg-white sm:px-7 px-5 py-5 rounded-3xl ">
          <div className="text-xl font-bold text-Orange">User Information</div>
          <div className=" py-4 space-y-3 text-sm ">
            <div className="flex gap-x-2 text-gray-600 items-center font-semibold">
              <PhoneIcon className="h-5 w-5 text-gray-400" /> +
              {data.contactNumber}
            </div>
            <div className="flex gap-x-2 text-gray-600 items-center font-semibold">
              <MailIcon className="h-5 w-5 text-gray-400" />
              {data.email}
            </div>
            <div className="flex gap-x-2 text-gray-600 items-center font-semibold">
              <CakeIcon className="h-5 w-5 text-gray-400" />
              {moment(data.birthday).format("ll")}
            </div>
            <div className="flex gap-x-2 text-gray-600 items-center font-semibold">
              <IdentificationIcon className="h-5 w-5 text-gray-400" />
              <Pill
                status={data.userStatus}
                color={
                  data.userStatus === "Active"
                    ? "green"
                    : data.userStatus === "Pending"
                    ? "yellow"
                    : data.userStatus === "Declined"
                    ? "red"
                    : "gray"
                }
                component={
                  data.userStatus === "Active"
                    ? CheckIcon
                    : data.userStatus === "Pending"
                    ? ClockIcon
                    : BanIcon
                }
              />
              <button
                className="text-xs px-5 py-1 border rounded-md font-semibold hover:bg-Orange hover:text-white hover:border-Orange transition"
                onClick={() => {
                  setChangeStatus(true);
                  setStatus(data.userStatus);
                }}
              >
                {(data.userStatus === "Active" ||
                  data.userStatus === "Disabled") &&
                  "Change Status"}
                {data.userStatus === "Pending" && "Respond to Request"}
              </button>
            </div>
            <div className="flex gap-x-2 text-gray-600 items-center font-semibold">
              <BriefcaseIcon className="h-5 w-5 text-gray-400" />
              <div
                className={`px-5 py-1 rounded-full ${
                  data.roleName === "Admin"
                    ? "bg-dark-blue text-white"
                    : "bg-light-blue text-blue"
                }`}
              >
                {data.roleName}
              </div>

              <button
                className="text-xs px-5 py-1 border rounded-md font-semibold hover:bg-Orange hover:text-white hover:border-Orange transition"
                onClick={() => {
                  setRole(data.roleName);
                  setChangeRole(true);
                }}
              >
                Change Role
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="grid grid-cols-5 gap-y-4 m-4 ">
        <div>Name</div>
        <div className="col-span-4 font-bold">{data.fullName}</div>
        <div>Email Address</div>
        <div className="col-span-4 font-bold">{data.email}</div>
        <div>Contact Number</div>
        <div className="col-span-4 font-bold">{data.contactNumber}</div>
        <div>Username</div>
        <div className="col-span-4 font-bold">{data.userName}</div>
        <div>Status</div>
        {data.userStatus !== "Pending" && data.userStatus !== "Declined" && (
          <div
            className={`col-span-2 font-bold ${
              edit ? "opacity-100" : "opacity-70"
            }`}
          >
            <RadioButton
              text="Active"
              name="Status"
              radioClass="hidden"
              className={`p-2 ${
                status === "Active" &&
                "bg-green-400 text-white hover:bg-green-500"
              } `}
              checked={status === "Active"}
              disabled={!edit}
              onChange={() => {
                setValue(2);
                setStatus("Active");
              }}
            >
              {status === "Active" && (
                <div className="w-full flex justify-end px-2">
                  <CheckIcon className="h-5 w-5" />
                </div>
              )}
            </RadioButton>
            <RadioButton
              radioClass="hidden"
              text="Disabled"
              name="Status"
              checked={status === "Disabled"}
              className={`p-2 ${
                status === "Disabled"
                  ? "bg-red-400 text-white hover:bg-red-500"
                  : ""
              } `}
              disabled={!edit}
              onChange={() => {
                setValue(3);
                setStatus("Disabled");
              }}
            >
              {status === "Disabled" && (
                <div className="w-full flex justify-end px-2">
                  <CheckIcon className="h-5 w-5" />
                </div>
              )}
            </RadioButton>
          </div>
        )}
        {data.userStatus === "Pending" && (
          <div className="col-span-2 font-bold space-y-2">
            <div className="bg-yellow-500 text-white items-center flex px-5 py-2 rounded-md opacity-50">
              <div>Pending</div>
              <div className="w-full flex justify-end px-2">
                <CheckIcon className="h-5 w-5" />
              </div>
            </div>
            <div className="flex gap-x-2">
              <FlatButton
                className="btn-Green w-full"
                onClick={() => {
                  context.SetUserStatus({ id: userId, status: 2 });
                }}
              >
                Accept
              </FlatButton>
              <FlatButton
                className="btn-Red w-full"
                onClick={() => {
                  context.SetUserStatus({ id: userId, status: 4 });
                }}
              >
                Reject
              </FlatButton>
            </div>
          </div>
        )}
        {data.userStatus === "Declined" && (
          <div className="col-span-2 font-bold space-y-2">
            <div className="bg-red-500 text-white items-center flex px-5 py-2 rounded-md opacity-50">
              <div>Declined</div>
              <div className="w-full flex justify-end px-2">
                <CheckIcon className="h-5 w-5" />
              </div>
            </div>
          </div>
        )}
      </div> */}
    </div>
  );
};

const UserView = () => {
  const history = useHistory();
  return (
    <div className="h-full ">
      <div className="flex h-full w-full">
        <AdminSidebar />
        <UsersProvider>
          <div className="z-0 sm:ml-10 ml-0 w-full overflow-auto pb-40">
            <div className="relative">
              <FlatButton
                className="flex items-center justify-center absolute z-20 top-5 left-5 text-white hover:bg-black hover:bg-opacity-30"
                onClick={() => {
                  history.push("/users");
                }}
              >
                <ArrowNarrowLeftIcon className="h-5 w-5 mr-2" />
                Back to Users Table
              </FlatButton>
            </div>

            <Content />
          </div>
        </UsersProvider>
      </div>
    </div>
  );
};

export default UserView;
