import React, { useState, createContext, useContext, useEffect } from "react";
import * as tus from "tus-js-client";
import { newContent } from "../services/contents";
import { useHistory } from "react-router";
import { useToasts } from "react-toast-notifications";
import { AuthContext } from "../context/AuthContext";
import { deleteUploadedFile } from "../services/contents";
import { updateStatus } from "../services/admin";

const CreateContentContext = createContext();
const { Provider } = CreateContentContext;

const CreateContentProvider = ({ children }) => {
  const authContext = useContext(AuthContext);
  const [step, setStep] = useState(1);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [publisher, setPublisher] = useState("");
  const [categories, setCategories] = useState([]);
  const [contentType, setContentType] = useState("");
  const [language, setLanguage] = useState("");
  const [author, setAuthor] = useState("");
  const [files, setFiles] = useState([]);
  const [embed, setEmbed] = useState("");
  const [publishedDate, setPublishedDate] = useState("");
  const [releaseDate, setReleaseDate] = useState("");
  const [mainIndex, setMainIndex] = useState(0);
  const [isEmbeddedMain, setIsEmbeddedMain] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [existingFiles, setExistingFiles] = useState([]);
  const [mergedFiles, setMergedFiles] = useState([]);

  const [fileExist, setFileExist] = useState(false);
  const [keywords, setKeywords] = useState([]);
  const [uploadProgress, setUploadProgress] = useState([]);
  const [uploadStatus, setUploadStatus] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const [isUploading, setIsUploading] = useState(false);
  const { addToast } = useToasts();

  var env = process.env.NODE_ENV || "development";
  const server = `${
    env === "development"
      ? process.env.REACT_APP_DEV_SERVER
      : process.env.REACT_APP_PROD_SERVER
  }`;

  // console.warn(mainIndex);

  const UploadFile = (e) => {
    var file = e.target.files[0];

    if (files.filter((f) => f.name === file.name).length > 0) {
      setFileExist(true);
    } else {
      setFiles([...files, file]);
      // console.log(uploadProgress);
      var upload = new tus.Upload(file, {
        endpoint: `${server}/files/`,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: {
          name: file.name,
          contentType: file.type,
        },
        onError: function (error) {
          setUploadStatus(
            uploadStatus.filter((stat) => stat.name !== file.name)
          );
          setUploadProgress(
            uploadProgress.filter((prog) => prog.name !== file.name)
          );

          addToast(
            JSON.parse(error.originalResponse._xhr.responseText).message,
            { appearance: "error" }
          );
          setIsUploading(false);
          // console.log(error);

          // upload.abort();
          setFiles((files) => files.filter((f) => f.name !== file.name));
        },
        onProgress: function (bytesUploaded, bytesTotal) {
          var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);

          const fileProgress = [
            ...uploadProgress,
            {
              name: file.name,
              percentage: percentage,
            },
          ];

          setIsUploading(true);

          setUploadProgress(fileProgress);
        },
        onSuccess: function () {
          const fileStatus = [
            ...uploadStatus,
            {
              name: file.name,
              status: "success",
            },
          ];
          setIsUploading(false);
          setUploadStatus(fileStatus);
          // setStep(3);
        },
      });
      upload.findPreviousUploads().then(function (previousUploads) {
        // Found previous uploads so we select the first one.
        // if (previousUploads.length) {
        //   upload.resumeFromPreviousUpload(previousUploads[0]);
        // }

        // Start the upload
        upload.start();
      });
    }
  };

  useEffect(() => {
    if (embed !== "" && files.length === 0) {
      setIsEmbeddedMain(true);
    }
  }, [embed, files]);

  const RemoveFile = (newFile, fileId) => {
    if (newFile) {
      deleteUploadedFile(fileId)
        .then((res) => {
          // console.log(res);
          setFiles(files.filter((file) => file.name !== fileId));

          const removeStatus = uploadStatus.filter(
            (stat) => stat.name !== fileId
          );

          const removeProgress = uploadProgress.filter(
            (prog) => prog.name !== fileId
          );

          setUploadStatus(removeStatus);
          setUploadProgress(removeProgress);

          addToast("File has removed successfully", { appearance: "success" });
          setLoading(false);
        })
        .catch((e) => {
          addToast("Failed to delete file", { appearance: "error" });
          setLoading(false);
        });
    }
  };

  const SubmitContent = () => {
    setLoading(true);
    var data = new FormData();
    data.append("Title", title);
    data.append("Description", description);
    data.append("Publisher", publisher);
    data.append("Author", author);
    data.append("EmbeddedUrl", embed);
    categories.forEach((c, index) => {
      data.append(`CategoryId[${index}]`, JSON.stringify(c.categoryId));
    });
    data.append("ContentTypeId", contentType.id);
    data.append("PublishedDate", publishedDate);
    data.append("ReleaseDate", releaseDate);
    data.append("IsEmbeddedMain", isEmbeddedMain);
    data.append("Language", "English");
    data.append("Thumbnail", thumbnail);
    data.append("UserName", authContext.profile.userName);

    // console.log(files);
    // console.log(data);

    files.forEach((f, index) => {
      data.append(`Files[${index}].FileName`, f.name);
      data.append(
        `Files[${index}].IsMain`,
        mainIndex === index && mainIndex !== null ? true : false
      );
    });

    data.append("Keywords", keywords.join(";"));

    newContent(data)
      .then((res) => {
        // console.log(res);
        setLoading(false);
        history.push("/my-contents");
        addToast("Content Submitted", { appearance: "success" });
        ResetFields();
      })
      .catch((e) => {
        setLoading(false);
        // console.log(JSON.stringify(e.response.data));
        // console.warn(JSON.stringify(e));
        addToast(e, {
          appearance: "error",
        });
      });
  };

  const ResetFields = () => {
    setStep(1);
    setTitle("");
    setDescription("");
    setPublisher("");
    setCategories([]);
    setContentType();
    setLanguage("");
    setAuthor("");
    setFiles([]);
    setEmbed("");
    setPublishedDate("");
    setReleaseDate("");
    setMainIndex(0);
    setThumbnail(null);
    setKeywords([]);
  };

  return (
    <Provider
      value={{
        isUploading,
        keywords,
        setKeywords,
        fileExist,
        setFileExist,
        mergedFiles,
        RemoveFile,
        mainIndex,
        setMainIndex,
        isEmbeddedMain,
        setIsEmbeddedMain,
        uploadStatus,
        SubmitContent,
        thumbnailUrl,
        existingFiles,
        setThumbnailUrl,
        UploadFile,
        publishedDate,
        setPublishedDate,
        releaseDate,
        setReleaseDate,
        loading,
        uploadProgress,
        files,
        step,
        setStep,
        thumbnail,
        setThumbnail,
        title,
        setTitle,
        description,
        setDescription,
        publisher,
        setPublisher,
        categories,
        setCategories,
        contentType,
        setContentType,
        language,
        setLanguage,
        author,
        setAuthor,
        embed,
        setEmbed,
      }}
    >
      {children}
    </Provider>
  );
};

export { CreateContentProvider, CreateContentContext };
