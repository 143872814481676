import axios from "axios";
import { saveAs } from "file-saver";
import { authAxios } from "./interceptor";

var env = process.env.NODE_ENV || "development";
const baseUrl = `${
  env === "development"
    ? process.env.REACT_APP_DEV_SERVER
    : process.env.REACT_APP_PROD_SERVER
}/export`;

export const exportContents = (filters) => {
  var params = [];

  if (filters.contentName !== "")
    params.push("ContentName=" + filters.contentName);
  if (filters.author !== "") params.push("Author=" + filters.author);
  if (filters.dateRequestedFrom !== "")
    params.push("FromDate=" + filters.dateRequestedFrom);
  if (filters.dateRequestedTo !== "")
    params.push("ToDate=" + filters.dateRequestedTo);
  if (filters.status !== "") params.push("Status=" + filters.status);
  params = params.join("&");
  params = params.length > 0 ? "?" + params : "";

  return authAxios
    .get(`${baseUrl}/export-contents-to-excel${params}`, {
      responseType: "blob",
    })
    .then((res) => {
      // console.log(res);
      const filename = "Contents.xlsx";
      let url = window.URL.createObjectURL(new Blob([res.data]));
      saveAs(url, filename);

      return res;
    });
};

export const exportCategories = () => {
  return authAxios
    .get(`${baseUrl}/export-category-to-excel`, {
      responseType: "blob",
    })
    .then((res) => {
      // console.log(res);
      const filename = "Categories.xlsx";
      let url = window.URL.createObjectURL(new Blob([res.data]));
      saveAs(url, filename);

      return res;
    });
};

export const exportUsers = (filters) => {
  const { fullname, username, status } = filters;
  var params = [];
  if (fullname !== "") params.push("FullName=" + fullname);
  if (username !== "") params.push("UserName=" + username);
  if (status !== "") params.push("Status=" + status);

  // console.warn(status);

  params = params.join("&");
  params = params !== "" ? "?" + params : "";
  // console.warn(params);
  return authAxios
    .get(`${baseUrl}/export-users-to-excel${params}`, {
      responseType: "blob",
    })
    .then((res) => {
      const filename = "Users.xlsx";
      let url = window.URL.createObjectURL(new Blob([res.data]));
      saveAs(url, filename);
      return res;
    });
};

export const exportReportedContents = (filters) => {
  var params = [];

  if (filters.contentName !== "")
    params.push("ContentName=" + filters.contentName);
  if (filters.author !== "") params.push("Author=" + filters.author);
  if (filters.dateRequestedFrom !== "")
    params.push("FromDate=" + filters.dateRequestedFrom);
  if (filters.dateRequestedTo !== "")
    params.push("ToDate=" + filters.dateRequestedTo);
  if (filters.status !== "") params.push("Status=" + filters.status);
  params = params.join("&");
  params = params.length > 0 ? "?" + params : "";

  return authAxios
    .get(`${baseUrl}/export-report-issues-to-excel${params}`, {
      responseType: "blob",
    })
    .then((res) => {
      // console.log(res);
      const filename = "ReportedContents.xlsx";
      let url = window.URL.createObjectURL(new Blob([res.data]));
      saveAs(url, filename);

      return res;
    });
};

export const exportLogs = ({ type, action }) => {
  var params = [];
  if (type !== 0) params.push("type=" + type);
  if (action !== 0) params.push("action=" + action);
  params = params.join("&");
    params = params.length > 0 ? "?" + params : "";

  return authAxios
    .get(`${baseUrl}/export-audit${params}`, {
      responseType: "blob",
    })
    .then((res) => {
      // console.log(res);
      const filename = "Audit Logs.xlsx";
      let url = window.URL.createObjectURL(new Blob([res.data]));
      saveAs(url, filename);

      return res;
    });
};
