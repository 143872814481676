import React, { useState, useEffect, useContext } from "react";
import AdminSidebar from "../components/Base/AdminSidebar";
import { FlatButton } from "../components/Buttons/Buttons";
import {
  ArrowNarrowLeftIcon,
  ChevronLeftIcon,
  EyeIcon,
} from "@heroicons/react/outline";
import { useHistory } from "react-router-dom";
import ContentDetails from "../components/Admin/Contents/Single Content View/ContentDetails";
import ContentButtons from "../components/Admin/Contents/Single Content View/ContentButtons";
import { useParams } from "react-router-dom";
import { viewReportedContent } from "../services/admin";
import { useQuery } from "react-query";
import { ContentsContext, ContentsProvider } from "../context/ContentsContext";
import Loading from "../components/Others/Loading";
import ContentError from "../components/Others/ContentError";
import ViewModal from "../components/Others/ViewModal";
import ReactHtmlParser from "react-html-parser";

const ContentInfo = () => {
  const { reportId } = useParams();
  const [modal, setModal] = useState(false);
  const [path, setPath] = useState("");
  const [fileId, setFileId] = useState("");

  const { data, isLoading, isError } = useQuery(
    ["report", { reportId }],
    viewReportedContent
  );

  useEffect(() => {
    if (data) {
      const iframes = document.querySelectorAll("iframe");
      iframes.forEach((iframe) => {
        iframe.width = "100%";
        iframe.height = "100%";
      });
    }
  }, [data]);
  if (isLoading) {
    return (
      <div className="animate-pulse space-y-7">
        <div className=" space-y-7  bg-gray-300 rounded-bl-3xl  p-10">
          <div className="h-80"></div>
        </div>
        <div className="flex justify-center px-10">
          <div className="w-96 h-40 bg-gray-300 rounded-3xl"></div>
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="space-y-7">
        <div className=" space-y-7  bg-gray-300 rounded-bl-3xl  p-10">
          <div className="h-80">
            <ContentError />
          </div>
        </div>
        <div className="flex justify-center px-10">
          <div className="w-96 h-40 bg-gray-300 rounded-3xl"></div>
        </div>
      </div>
    );
  }

  if (data) {
    return (
      <div className="space-y-4 pb-40">
        <ViewModal
          path={path}
          contentId={data.contentId}
          fileId={fileId}
          isOpen={modal}
          onClose={() => {
            setModal(false);
          }}
        />
        <ContentDetails
          loading={false}
          type="report"
          thumbnailUrl={data.thumbnail}
          name={data.contentName}
          ratings={1}
          status={data.status}
          description={data.contentDescription}
        />
        <div className="w-full flex justify-center z-0">
          <div className="w-8/12 space-y-4">
            <div className="font-bold text-xl text-Orange mx-4">Files</div>
            {data.files.length > 0 && (
              <div className="p-6 text-sm rounded-3xl bg-white shadow-xl flex flex-col text-gray-600">
                {data.files.map((file, i) => {
                  return (
                    <div
                      key={file.fileName}
                      className="flex items-center pl-4 w-full"
                    >
                      <div className=" flex gap-x-4 font-semibold">
                        <div className="font-bold">{i + 1}.</div>
                        <div>{file.fileName}</div>
                      </div>
                      <button
                        className="gap-x-2 ml-auto flex text-xs font-semibold text-gray-500 hover:bg-gray-100 px-3 py-2 rounded-md"
                        onClick={() => {
                          setModal(true);
                          setPath(file);
                          setFileId(file.fileId);
                        }}
                      >
                        View Content <EyeIcon className="h-4 w-4" />
                      </button>
                    </div>
                  );
                })}
              </div>
            )}
            {data.embededUrl && (
              <div className=" w-full sm:mx-0 mx-2 space-y-4 ">
                <div className="font-bold text-xl text-Orange mx-4">Embed</div>
                <div className="sm:p-6 p-3 text-sm rounded-3xl bg-white shadow-xl flex flex-col text-gray-600">
                  <div className="flex flex-col items-start gap-y-2">
                    <div className="w-full font-semibold">
                      <div className="w-full h-96">
                        {ReactHtmlParser(data.embededUrl)}
                      </div>
                    </div>
                    <div className="gap-x-2 flex flex-col w-full font-semibold text-gray-500 gap-y-2">
                      <div>Embed Code</div>
                      <div className="bg-black space-y-2 bg-opacity-70 p-4 text-white text-sm rounded-md">
                        <code className="w-full">{data.embededUrl}</code>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="space-y-2 mx-3">
              <div>
                <div className="font-bold text-gray-600 ">Reporter</div>
                <div className="text-sm text-gray-500">{data.reporter}</div>
              </div>
              <div>
                <div className="font-bold text-gray-600 ">Report Type</div>
                <div className="text-sm text-gray-500">{data.reportType}</div>
              </div>
              <div>
                <div className="font-bold text-gray-600 ">Remarks</div>
                <div className="text-sm text-gray-500">
                  {data.reportContent}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <div></div>;
};

const Buttons = ({ status, reportId }) => {
  const context = useContext(ContentsContext);
  return (
    <ContentButtons
      contentStatus="Reported"
      reportStatus={status}
      contentId={reportId}
      context={context}
    />
  );
};

const Load = () => {
  const context = useContext(ContentsContext);
  if (context.loading)
    return (
      <div>
        <Loading
          loading={true}
          className="absolute top-0 bottom-0 right-0 left-0"
        />
      </div>
    );
  return <div></div>;
};

const ReportedContentView = () => {
  const history = useHistory();
  const { reportId } = useParams();
  const { data, isLoading, isError } = useQuery(
    ["report", { reportId }],
    viewReportedContent
  );

  return (
    <ContentsProvider>
      <div className="h-full ">
        <div className="flex h-full w-full">
          <AdminSidebar />
          <Load />
          <div className="ml-10 overflow-auto w-full relative">
            <FlatButton
              className="absolute top-5 left-5 z-40 transition text-white hover:bg-opacity-20"
              onClick={() => {
                history.push("/contents");
              }}
            >
              <ArrowNarrowLeftIcon className="h-5 w-5 mr-2" />
              Back to Contents
            </FlatButton>
            <ContentInfo />
          </div>
          {data && <Buttons status={data.status} reportId={reportId} />}
        </div>
      </div>
    </ContentsProvider>
  );
};

export default ReportedContentView;
