import React from "react";
import PendingTable from "./Tables/PendingTable";
import ReportedContents from "./Tables/ReportedContents";
import AllRequests from "./Tables/AllRequests";

const Table = () => {
  return (
    <div>
      <AllRequests />
      <PendingTable />
      <ReportedContents />
    </div>
  );
};

export default Table;
