import {
  ArrowNarrowRightIcon,
  ArrowRightIcon,
  DocumentAddIcon,
} from "@heroicons/react/outline";
import React, { useContext, useState, useEffect } from "react";
import DataTable from "../components/Base/DataTable";
import { FlatButton } from "../components/Buttons/Buttons";
// import Select as select from "../components/Inputs/Select";
import TextArea from "../components/Inputs/TextArea";
import Textbox from "../components/Inputs/Textbox";
import Select from "react-select";
import {
  CreateContentContext,
  CreateContentProvider,
} from "../context/CreateContentContext";
import moment from "moment";
import { convertSize } from "convert-size";
import { useQuery } from "react-query";
import { categoryOptions } from "../services/categories";
import Categories from "./Categories";
import Loader from "react-loader-spinner";
import Stepper from "../components/Others/Stepper";
import Details from "../components/Contents/Forms/Details";
import AddFile from "../components/Contents/Forms/AddFile";
import Summary from "../components/Contents/Forms/Summary";
import { useHistory } from "react-router-dom";

const Content = () => {
  const history = useHistory();
  const context = useContext(CreateContentContext);
  return (
    <div className="p-10 md:px-5 md:py-10 lg:w-8/12 w-full flex flex-col h-full space-y-4">
      <div className="w-full text-2xl font-bold text-gray-600 mb-5 flex">
        <span className="text-dark-blue uppercase">Create Content</span>
        <div className="flex ml-auto space-x-2">
          <FlatButton
            className="btn-Orange-primary gap-x-2"
            onClick={() => {
              history.push("/my-contents");
            }}
          >
            My Contents
          </FlatButton>
        </div>
      </div>
      <Stepper context={context} />
      {context.step === 1 && <Details context={context} />}
      {context.step === 2 && <AddFile context={context} />}
      {context.step === 3 && <Summary context={context} />}
    </div>
  );
};

const CreateContent = () => {
  return (
    <div className="h-full overflow-auto">
      <div className="flex h-full w-full justify-center">
        <Content />
      </div>
    </div>
  );
};

export default CreateContent;
