import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import Sidebar from "../components/Base/Sidebar";
import GuestDashboard from "../components/Dashboard/GuestDashboard";
import MemberDashboard from "../components/Dashboard/MemberDashboard";
import AdminDashboard from "../components/Dashboard/AdminDashboard";
import { AuthContext } from "../context/AuthContext";
import AdminSidebar from "../components/Base/AdminSidebar";

const Dashboard = () => {
  const authContext = useContext(AuthContext);
  if (authContext.isAdmin())
    return (
      <div className="h-full ">
        <div className="flex h-full w-full">
          <AdminSidebar />
          <AdminDashboard />
        </div>
      </div>
    );

  if (authContext.isMember()) {
    return (
      <div className="h-full ">
        <div className="flex h-full w-full">
          <Sidebar />
          <MemberDashboard />
        </div>
      </div>
    );
  }

  return (
    <div className="h-full ">
      <div className="flex h-full w-full">
        <Sidebar />
        <GuestDashboard />
      </div>
    </div>
  );
};

export default withRouter(Dashboard);
