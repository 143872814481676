import React from "react";
import { EmojiSadIcon } from "@heroicons/react/solid";
import { Link, withRouter } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="flex flex-col mb-4 justify-center text-gray-500 space-y-3">
          <span className="flex text-3xl font-extrabold justify-center">
            Page Not Found <EmojiSadIcon className="w-10 h-10 ml-3" />
          </span>
          <span className="text-center">
            The page you are accessing is not available.
          </span>
          <div className="flex justify-center">
            <Link
              to="/"
              className=" py-2 px-4 rounded-md text-sm font-medium text-gray-500 hover:bg-gray-200 focus:outline-none"
            >
              Go Back to Home Page
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(PageNotFound);
