import React, { useState, useContext } from "react";
import moment from "moment";
import {
  ThumbUpIcon,
  ThumbDownIcon,
  ReplyIcon,
} from "@heroicons/react/outline";
import RatingToStar from "../Others/RatingToStar";
import { FlatButton } from "../Buttons/Buttons";
import TextArea from "../Inputs/TextArea";
import { useQuery } from "react-query";
import { addComment, getReplies } from "../../services/review";
import { AuthContext } from "../../context/AuthContext";
import { useToasts } from "react-toast-notifications";
import { useQueryClient } from "react-query";
import Loader from "react-loader-spinner";

var env = process.env.NODE_ENV || "development";
const server = `${
  env === "development"
    ? process.env.REACT_APP_DEV_SERVER
    : process.env.REACT_APP_PROD_SERVER
}`;

const Replies = ({ reply }) => {
  return (
    <div className=" w-full flex items-end relative ">
      <div
        className={`flex flex-col p-4 text-sm bg-white relative z-40 w-full rounded-xl`}
      >
        <div className="flex items-start space-x-4">
          <div className="bg-Orange h-2 w-2 flex-none rounded-full transform translate-y-2"></div>
          <div className="font-normal space-y-1 w-full">
            <div className="text-gray-400 text-xs flex w-full  items-center">
              <div className="text-sm">{reply.fullname} Replied</div>
              <div className="ml-auto">
                {moment(reply.dateCommented).format("lll")}
              </div>
            </div>
            <div className="text-gray-600">{reply.comment}</div>
          </div>
        </div>
      </div>

      <div
        className="border-b-2 border-l-2 border-LightOrange w-full absolute z-0 bottom-full rounded-bl-3xl transform translate-y-7 right-11"
        style={{ height: "500px" }}
      ></div>
    </div>
  );
};

const ContentReview = ({ review }) => {
  const [reply, setReply] = useState(false);
  const authContext = useContext(AuthContext);
  const { addToast } = useToasts();
  const queryClient = useQueryClient();
  const [comment, setComment] = useState("");
  const [replyLoading, setReplyLoading] = useState(false);
  const { data, isLoading, isError } = useQuery(
    ["replies", review.reviewId],
    getReplies
  );

  const Reply = () => {
    if (comment !== "") {
      setReplyLoading(true);
      const data = {
        userId: authContext.authState.userInfo.userId,
        reviewId: review.reviewId,
        comment,
      };

      addComment(data)
        .then((res) => {
          console.log(res);
          queryClient.invalidateQueries("replies");
          setReplyLoading(false);
          setReply(false);
        })
        .catch((e) => {
          addToast("Error replying to this review", { appearance: "error" });
          setReplyLoading(false);
        });
    }
  };

  // console.log(review);

  return (
    <div className="reviews space-y-2 bg-white rounded-2xl shadow-xl p-6  rounded-md relative overflow-hidden ">
      <div className="flex gap-x-4 items-start bg-white relative">
        <div className="absolute bg-white h-10 w-full bottom-full right-0 z-20 "></div>
        <div className="bg-white ">
          <div className="h-10 w-10 ring-4 ring-LightOrange rounded-full relative z-20">
            <img
              src={
                review.profilePicture === null || review.profilePicture === ""
                  ? `https://www.w3schools.com/howto/img_avatar.png`
                  : server + review.profilePicture
              }
              className="rounded-full object-cover h-full w-full"
            />
          </div>
        </div>
        <div className="flex w-full bg-white ">
          <div className="flex flex-col space-y-3 font-semibold text-gray-600 w-full ">
            <div className="flex w-full relative z-20">
              <div className="flex flex-col space-y-1">
                <div>{review.fullName}</div>
                <div className="flex">
                  <RatingToStar rating={review.rating} size={6} />
                </div>
                <div className="font-normal text-sm">
                  <div>{review.review}</div>
                </div>
              </div>
              <div className="ml-auto text-gray-400 text-xs flex flex-col space-y-3 items-end bg-white">
                <div>{moment(review.reviewDate).format("lll")}</div>
                <div>
                  {authContext.isAuthenticated() && !reply && (
                    <FlatButton
                      className="btn-Orange-primary text-xs flex items-center px-3 py-2 font-semibold gap-x-2 rounded-md bg-Orange text-white"
                      onClick={() => {
                        setReply(true);
                      }}
                    >
                      Reply
                      <ReplyIcon className="h-4 w-4" />
                    </FlatButton>
                  )}
                </div>
              </div>
            </div>
            {reply && (
              <div className="relative z-30 px-2 rounded-2xl space-y-3">
                <TextArea
                  label={`Replying to ${review.fullName}'s Review`}
                  placeholder="Write your reply"
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                />
                <div className="flex justify-end space-x-2">
                  <FlatButton
                    className="btn-Gray"
                    onClick={() => {
                      setReply(false);
                    }}
                  >
                    Cancel
                  </FlatButton>
                  <FlatButton
                    className="btn-Orange-primary"
                    onClick={() => {
                      if (!replyLoading) Reply();
                    }}
                  >
                    {!replyLoading ? (
                      <div>Send Reply</div>
                    ) : (
                      <div className="flex gap-x-2 items-center">
                        Sending Reply
                        <Loader
                          type="TailSpin"
                          color="#FFF"
                          height={15}
                          width={15}
                        />
                      </div>
                    )}
                  </FlatButton>
                </div>
              </div>
            )}
            {data && data.length > 0 && (
              <div className="w-full flex justify-end">
                <div className="w-full  bg-gray-200 p-2 space-y-2 rounded-2xl ">
                  {data.map((reply, i) => {
                    return (
                      <Replies
                        key={i}
                        index={i}
                        count={data.length}
                        reply={reply}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentReview;
