import React, { createContext, useState, useEffect, useContext } from "react";
import * as signalR from "@microsoft/signalr";
import { AuthContext } from "./AuthContext";

const PresenceContext = createContext();
const { Provider } = PresenceContext;

const PresenceProvider = ({ children }) => {
  const authContext = useContext(AuthContext);
  const [notif, setNotif] = useState(false);
  const [notifItems, setNotifItems] = useState([]);
  const [connection, setConnection] = useState("");
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [msgNotif, setMsgNotif] = useState(false);
  const [msgNotifMsg, setMsgNotifMsg] = useState("");

  var env = process.env.NODE_ENV || "development";
  const server = `${
    env === "development"
      ? process.env.REACT_APP_DEV_SERVER
      : process.env.REACT_APP_PROD_SERVER
  }`;

  useEffect(() => {
    if (authContext.authState.token) {
      const connect = new signalR.HubConnectionBuilder()
        //.withUrl(`${process.env.REACT_APP_DEV_SERVER}/hubs/admin` , {} )
        .withUrl(
          server + "/hubs/admin?access_token=" + authContext.authState.token
        )
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Debug)
        .build();
      setConnection(connect);
    }
  }, [authContext.authState.token, server]);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on("initialize", (data) => {
            // console.log(data);
          });

          connection.on("NewMessageReceived", (data) => {
            // console.log(data);
            setMsgNotifMsg(data);
            setMsgNotif(true);
          });

          connection.on("GetOnlineUsers", (data) => {
            // console.log(data);
            setOnlineUsers(data);
          });

          connection.on("AddedToAdmin", (data) => {
            // console.log(data);
          });

          connection.on("AddedToMember", (data) => {
            // console.log(data);
          });

          connection
            .invoke("GetNotifications", authContext.authState.userInfo.userId)
            .catch((e) => {});

          connection.on("AllUserNotifications", (data) => {
            if (data.filter((item) => item.isRead === false).length > 0) {
              setNotif(true);
            }
            setNotifItems(data);
          });

          connection.on("AdminNotif", (data) => {
            // console.log(data, "AdminNotif");
            connection
              .invoke("GetNotifications", authContext.authState.userInfo.userId)
              .catch((e) => {});
          });

          connection.on("UserAdded", (message) => {
            setNotif(true);
          });
        })
        .catch((error) => {});
    }
  }, [connection, authContext.authState.userInfo.userId, onlineUsers]);

  const RemoveNotification = (id) => {
    connection.invoke("RemoveNotification", id).then((res) => {
      connection
        .invoke("GetNotifications", authContext.authState.userInfo.userId)
        .catch((e) => {});
    });
  };

  const ReadNotification = (id) => {
    connection.invoke("ReadNotification", id).then((res) => {
      connection
        .invoke("GetNotifications", authContext.authState.userInfo.userId)
        .catch((e) => {});
    });
  };

  return (
    <Provider
      value={{
        msgNotifMsg,
        msgNotif,
        setMsgNotif,
        notif,
        setNotif,
        notifItems,
        RemoveNotification,
        ReadNotification,
        onlineUsers,
        connection,
      }}
    >
      {children}
    </Provider>
  );
};

export { PresenceContext, PresenceProvider };
