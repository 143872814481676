import React, { useContext, Fragment } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Menu, Transition } from "@headlessui/react";
import MenuItemButton from "../Buttons/MenuItemButton";
import { Link, useHistory, useLocation } from "react-router-dom";
import { PresenceContext } from "../../context/PresenceContext";
import ImageNotFound from "../../assets/ImageNotFound.jpg";
import Tooltip from "react-simple-tooltip";

const NavbarUserMenu = () => {
  const authContext = useContext(AuthContext);
  const presenceContext = useContext(PresenceContext);
  const location = useLocation();
  const history = useHistory();
  var env = process.env.NODE_ENV || "development";
  const server = `${
    env === "development"
      ? process.env.REACT_APP_DEV_SERVER
      : process.env.REACT_APP_PROD_SERVER
  }`;
  const Logout = () => {
    presenceContext.connection.stop();
    authContext.logout();
  };

  if (authContext.isAuthenticated()) {
    return (
      <Menu as="div" className="relative">
        {/* <Tooltip
          content="Profile"
          placement="bottom"
          background="#6699CC"
          border="#6699CC"
          radius="10"
        > */}
        <div>
          <Menu.Button className="capitalize flex items-center overflow-hidden block bg-gray-300 rounded-full h-10 w-10 text-sm font-medium text-white hover:text-white hover:bg-black hover:bg-opacity-10 ring-2 ring-white ring-opacity-50 ">
            <img
              src={
                authContext.profile.profilePictureUrl
                  ? server + authContext.profile.profilePictureUrl
                  : ImageNotFound
              }
              className="h-full w-full object-cover rounded-full "
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = ImageNotFound;
              }}
            />
          </Menu.Button>
        </div>
        {/* </Tooltip> */}
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="z-10 overflow-hidden absolute right-0 w-56 mt-2 origin-top-right bg-white rounded-xl shadow-xl focus:outline-none">
            <div className="text-sm font-bold relative text-DarkOrange py-3 px-4 h-16 bg-light-blue rounded-t-lg flex justify-center ">
              <div className="text-xs font-normal absolute top-3 right-3 bg-LightBlue bg-opacity-20 px-2 py-1 rounded-full text-CSIBlue">
                {authContext.profile.role}
              </div>
              <div className="h-16 w-16 ring-4 bg-white ring-blue absolute -bottom-1/2 space-y-4  rounded-full ">
                <img
                  src={
                    authContext.profile.profilePictureUrl
                      ? server + authContext.profile.profilePictureUrl
                      : ImageNotFound
                  }
                  className="h-full w-full object-cover rounded-full "
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = ImageNotFound;
                  }}
                />
                <div className="flex justify-center capitalize relative text-blue">
                  {authContext.profile.userName}
                </div>
              </div>
            </div>

            <div className="p-2 mt-16">
              <Menu.Item>
                <MenuItemButton
                  className="flex justify-center my-2 bg-LightOrange bg-opacity-30 text-Orange font-semibold"
                  text="View Profile"
                  onClick={() => {
                    history.push("/my-profile");
                  }}
                />
              </Menu.Item>
              {/* <Menu.Item>
                <MenuItemButton text="My Favorites" />
              </Menu.Item> */}
              <Menu.Item>
                <MenuItemButton
                  text="My Contents"
                  onClick={() => {
                    history.push("/my-contents");
                  }}
                />
              </Menu.Item>
              <Menu.Item>
                <MenuItemButton
                  text="Create Content"
                  onClick={() => {
                    history.push("/create-content");
                  }}
                />
              </Menu.Item>
            </div>
            <hr className="mx-4" />
            <div className="p-2 ">
              <Menu.Item>
                <MenuItemButton
                  onClick={() => {
                    Logout();
                  }}
                  text="Logout"
                />
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  }

  if (location.pathname !== "/signin" || location.pathname !== "/register")
    return (
      <Link
        to="/signin"
        className="block px-3 py-2 rounded-md text-sm bg-blue font-medium text-white hover:text-white hover:bg-blue hover:bg-opacity-50 z-10"
      >
        Sign In
      </Link>
    );
};

export default NavbarUserMenu;
