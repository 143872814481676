import {
  CakeIcon,
  CameraIcon,
  CheckIcon,
  ExclamationIcon,
  MailIcon,
  PencilAltIcon,
  PhoneIcon,
} from "@heroicons/react/outline";
import { HeartIcon } from "@heroicons/react/solid";
import React, { useState, useEffect, useContext } from "react";
import { useQuery, useQueryClient } from "react-query";
import { FlatButton } from "../components/Buttons/Buttons";
import Textbox from "../components/Inputs/Textbox";
import { myFavorites } from "../services/contents";
import { myProfile } from "../services/users";
import { useHistory } from "react-router";
import Modal from "../components/Others/Modal";
import PhoneInput from "react-phone-input-2";
import {
  MyProfileContext,
  MyProfileProvider,
} from "../context/MyProfileContext";
import moment from "moment";
import LoadingNotRounded from "../components/Others/LoadingNotRounded";
import DatePicker from "../components/Inputs/DatePicker";
import DisplayItem from "../components/Others/DisplayItem";
import ImageNotFound from "../assets/ImageNotFound.jpg";
import { AuthContext } from "../context/AuthContext";

var env = process.env.NODE_ENV || "development";
const server = `${
  env === "development"
    ? process.env.REACT_APP_DEV_SERVER
    : process.env.REACT_APP_PROD_SERVER
}`;

const ChangePassword = ({ isOpen, onClose }) => {
  const context = useContext(MyProfileContext);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="relative z-0 overflow-hidden"
    >
      <Loading />
      <form className="space-y-4" onSubmit={(e) => context.ChangePassword(e)}>
        <div className="text-Orange font-bold text-lg">Change Password</div>

        <Textbox
          label="Old Password"
          type="password"
          onChange={(e) => {
            context.setOldPassword(e.target.value);
          }}
          required={true}
        />
        <Textbox
          label="New Password"
          type="password"
          onChange={(e) => {
            context.setNewPassword(e.target.value);
          }}
          required={true}
        />
        <Textbox
          label="Confirm Password"
          type="password"
          onChange={(e) => {
            context.setConfirmPassword(e.target.value);
          }}
          required={true}
        />
        {context.passwordError !== "" && (
          <div className="bg-red-100 bg-opacity-70 flex items-center text-red-600 p-3 rounded-md text-sm text-center">
            <ExclamationIcon className="h-5 w-5 mr-1" />{" "}
            <span> {context.passwordError}</span>
          </div>
        )}

        <div className="flex space-x-2 justify-end">
          <FlatButton onClick={onClose} className="btn-Gray" type="button">
            Cancel
          </FlatButton>
          <FlatButton type="submit" className="btn-Orange-primary px-5">
            Save
          </FlatButton>
        </div>
      </form>
    </Modal>
  );
};

const EditProfile = ({ isOpen, onClose, data }) => {
  const context = useContext(MyProfileContext);
  const [firstName, setFirstName] = useState(data.firstName);
  const [lastName, setLastName] = useState(data.lastName);
  const [middleName, setMiddleName] = useState(data.middleName);
  const [email, setEmail] = useState(data.email);
  const [dateOfBirth, setDateOfBirth] = useState(data.dateOfBirth);
  const [profilePicture, setProfilePicture] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(data.contactNumber);
  const [countryCode, setCountryCode] = useState(data.countryCode);
  const [dialCode, setDialCode] = useState(data.dialCode);
  const [changePassword, setChangePassword] = useState(false);
  useEffect(() => {
    if (context.passwordStatus === "success") {
      setChangePassword(false);
      context.setPasswordStatus("");
    }
  }, [context.passwordStatus]);
  const onChangeSelectCountry = (items) => {
    setCountryCode(items);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="px-0 py-0 overflow-hidden max-w-lg relative overflow-hidden"
    >
      <Loading />

      <form
        onSubmit={(e) =>
          context.UpdateProfile(e, {
            firstName,
            lastName,
            middleName,
            email,
            phoneNumber,
            dialCode,
            countryCode,
            dateOfBirth,
            profilePicture,
          })
        }
      >
        <div className="relative z-10 flex items-start bg-orange p-4 text-white font-bold text-lg">
          <div>Edit Profile</div>
          {/* <FlatButton className="ml-auto rounded-full btn-Orange-primary px-5">
            Save
          </FlatButton> */}
        </div>
        <div className="">
          <div className=" font-bold text-lg  flex justify-center relative p-2 ">
            <div className="bg-orange absolute h-full w-full z-0 -top-1/2"></div>
            <div className="h-32 w-32 rounded-full overflow-hidden bg-gray-200 relative z-0 ring-4 ring-white group ">
              <label className="absolute h-full w-full bg-gray-100 flex items-center justify-center opacity-0 group-hover:opacity-20 transition cursor-pointer">
                <input
                  type="file"
                  className="hidden"
                  accept="image/png, image/jpeg"
                  onChange={(e) => {
                    if (e.target.files.length !== 0)
                      setProfilePicture(e.target.files[0]);
                  }}
                />
                <CameraIcon className="h-7 w-7" />
              </label>
              <img
                src={
                  profilePicture
                    ? URL.createObjectURL(profilePicture)
                    : server + data.profilePictureUrl
                }
                className="h-full w-full object-cover"
              />
            </div>
          </div>

          <div className="space-y-3 px-6 max-h-96 pb-4 overflow-auto">
            <Textbox
              label="First Name"
              className="flex-1"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              required
            />

            <Textbox
              label="Middle Name"
              className="flex-1"
              value={middleName}
              onChange={(e) => {
                setMiddleName(e.target.value);
              }}
              required={false}
            />

            <Textbox
              label="Last Name"
              className="flex-1"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              required
            />

            <DatePicker
              label="Date of Birth"
              value={moment(dateOfBirth).format("YYYY-MM-DD")}
              onChange={(e) => setDateOfBirth(e.target.value)}
            />

            <Textbox
              label="Email Address"
              className="flex-1"
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              required
            />
            <div className="text-sm font-semibold text-gray-500 ">
              <label className="flex text-sm font-semibold text-gray-500 gap-x-1">
                Phone
                <span className="text-red-500">*</span>
              </label>
            </div>
            <div className="sm:flex sm:space-x-12 w-full">
              <div className="w-full">
                <PhoneInput
                  inputClass="appearance-none block w-full mb-20 px-3 py-2 bg-gray-50 rounded-md shadow-inner shadow-md placeholder-gray-400 focus:ring-offset-2 focus:ring-2 focus:outline-none focus:ring-LightOrange sm:text-sm flex-1"
                  containerClass="flex items-center"
                  buttonClass="block"
                  country={countryCode}
                  value={phoneNumber}
                  onChange={(phone, country) => {
                    setPhoneNumber(phone);
                    setDialCode(country.dialCode);
                    setCountryCode(country.countryCode);
                  }}
                  countryCodeEditable={true}
                  inputProps={{
                    required: true,
                  }}
                />
              </div>
            </div>
          </div>

          <div className="p-6 w-full flex ">
            <ChangePassword
              isOpen={changePassword}
              onClose={() => {
                setChangePassword(false);
              }}
            />

            <FlatButton
              type="button"
              className="btn-Orange-secondary"
              onClick={() => {
                setChangePassword(true);
              }}
            >
              Change Password
            </FlatButton>
            <FlatButton
              type="submit"
              className="btn-Orange-primary px-7 ml-auto"
            >
              Save
            </FlatButton>
          </div>
        </div>
      </form>
    </Modal>
  );
};

const BasicInfo = ({ info }) => {
  const { fullName, contactNumber, email, role, userName, dateOfBirth } =
    info.data;
  const { isAdmin } = useContext(AuthContext);

  const adminPermissions = [
    "Upload and download content files.",
    "Add content ratings and reviews (comments) on publications.",
    "Report any unauthorized publications.",
    "Enable/disable and approve/decline submitted contents or reports in the library.",
    "Send and receive messages from other users.",
    "Add, edit, and delete a category, as well as download a Category report.",
    "Access to audit logs.",
    "Process account registrations and download a User report.",
    "Set system restrictions per user.",
    "Change own password.",
  ];

  const memberPermissions = [
    "Upload and download content files.",
    "Add content ratings and reviews (comments) on publications.",
    "Report any unauthorized publications.",
    "Send and receive messages from other users.",
    "Change own password.",
  ];

  return (
    <div className="w-full relative flex justify-start px-10">
      <div className="space-y-2  ">
        <div className="font-semibold text-gray-600">
          {fullName}
          <div className="capitalize text-sm text-gray-500 font-normal">
            @{userName}
          </div>
        </div>
        <div className="text-sm text-gray-500 space-y-1">
          <div className="flex items-center gap-x-2">
            <MailIcon className="h-4 w-4 text-gray-400 flex-none" /> {email}
          </div>
          <div className="flex items-center gap-x-2">
            <PhoneIcon className="h-4 w-4 text-gray-400 flex-none" />
            {contactNumber}
          </div>
          <div className="flex items-center gap-x-2">
            <CakeIcon className="h-4 w-4 text-gray-400 flex-none" />
            {moment(dateOfBirth).format("LL")}
          </div>
        </div>
        {isAdmin() ? (
          <div class="text-sm space-y-2 py-4">
            <div className="font-semibold text-gray-600">Admin</div>
            <div className="text-gray-500">Users with this role can:</div>
            <div>
              <ul className="list-disc list-inside text-gray-600">
                {adminPermissions.map((x) => (
                  <li>{x}</li>
                ))}
              </ul>
            </div>
          </div>
        ) : (
          <div class="text-sm space-y-2 py-4">
            <div className="font-semibold text-gray-600">Member</div>
            <div className="text-gray-500">Users with this role can:</div>
            <div>
              <ul className="list-disc list-inside text-gray-600">
                {memberPermissions.map((x) => (
                  <li>{x}</li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const MyFavorites = () => {
  const { data, isLoading, isError } = useQuery(
    "profile-favorites",
    myFavorites
  );

  const history = useHistory();

  if (isLoading) {
    return (
      <div className="p-5 w-full rounded-3xl space-y-4 bg-gray-300 animate-pulse h-20"></div>
    );
  }

  if (isError) {
    return <div></div>;
  }

  return (
    <div className="bg-white  p-5 lg:w-6/12 w-full rounded-3xl space-y-4">
      <div className="flex items-center font-semibold text-Orange space-x-2">
        <HeartIcon className="h-5 w-5" />
        <div>My Favorites</div>
      </div>
      {data.length > 0 ? (
        <div className="p-2 grid grid-cols-2 gap-y-2 gap-x-2 w-full rounded-xl ">
          {data.map(({ contentName, contentId, thumbnail }) => {
            return (
              <div
                className="group flex flex-col w-full relative transition bg-white cursor-pointer shadow-lg hover:shadow-xl"
                onClick={() => {
                  history.push(`/content-view/${contentId}`);
                }}
              >
                <div className="image relative bg-black bg-opacity-30 h-40 min-w-full justify-center flex items-center transition overflow-hidden group-hover:shadow-xl">
                  <div className="absolute w-full h-full bg-black text-white  uppercase z-20 flex items-center justify-center bg-opacity-30 backdrop-filter backdrop-blur-sm opacity-0 group-hover:opacity-100 transition">
                    <button
                      className="text-white px-4 py-2 text-sm font-semibold rounded-md border-2 border-white hover:bg-Orange hover:border-Orange transition"
                      onClick={() => {
                        history.push(`/content-view/${contentId}`);
                      }}
                    >
                      View Content
                    </button>
                  </div>

                  <div className="w-full h-full flex items-center overflow-hidden shadow-xl">
                    <img
                      src={
                        //w-8/12 h-56  `https://media.istockphoto.com/vectors/no-thumbnail-image-vector-graphic-vector-id1147544806?k=6&m=1147544806&s=612x612&w=0&h=569pPmUNG4Ivtw9d4597Q71mTD5hDAZoOfRPmi1xzqg=`
                        !thumbnail ? ImageNotFound : server + thumbnail
                      }
                      className="object-cover h-full w-full relative z-10 duration-500 mx-auto transform transition"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = ImageNotFound;
                      }}
                    />
                  </div>
                </div>
                <div className="relative z-40 w-full font-semibold flex items-start flex-col flex-1 py-3 overflow-hidden rounded-3xl space-y-1">
                  <div className="px-3">
                    <div className="font-bold text-gray-600 w-full">
                      <div className="">{contentName}</div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="text-center text-sm font-semibold text-gray-500">
          No items to show.
        </div>
      )}
    </div>
  );
};

const Loading = () => {
  const context = useContext(MyProfileContext);

  if (context.loading) return <LoadingNotRounded />;

  return <div></div>;
};

const Content = () => {
  const context = useContext(MyProfileContext);
  const { data, isLoading, isError } = useQuery("my-profile", myProfile);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    if (context.profileStatus === "success") {
      setEdit(false);
      context.setProfileStatus("");
    }
  }, [context.profileStatus]);

  // console.log(data);

  if (isLoading) {
    return (
      <div className="animate-pulse h-96 rounded-3xl w-6/12 space-y-4 relative z-10 bg-gray-300"></div>
    );
  }

  if (isError) {
    return <div></div>;
  }
  return (
    <div className="lg:w-6/12 w-full flex-1 space-y-4 relative z-10">
      <EditProfile
        isOpen={edit}
        onClose={() => {
          setEdit(false);
        }}
        data={data}
      />

      <div className="bg-white rounded-3xl shadow-xl overflow-hidden">
        <div className="image pt-20 pb-3 flex justify-start  relative overflow-hidden ">
          <div className="h-full bg-orange absolute w-full z-0 bottom-1/3 ">
            {/* <button
              className="absolute top-full sm:block hidden right-5 btn-Orange-primary px-3 py-2 rounded-full transform translate-y-5"
              onClick={() => {
                setEdit(true);
              }}
            >
              Edit Profile
            </button> */}
          </div>
          <div className="h-48 w-48 relative mx-10 z-10 bg-gray-100 ring-8 ring-white rounded-full overflow-hidden">
            <img
              src={
                data.profilePicture === null || data.profilePicture === ""
                  ? "AvatarSample-02.png"
                  : server + data.profilePictureUrl
              }
              className="rounded-full w-full h-full object-cover"
            />
          </div>
        </div>
        <div className="w-full space-y-4 pt-1 pb-10">
          <BasicInfo info={{ data }} />
        </div>
        <div className="w-full mb-10 px-20 flex justify-center">
          <button
            className="bg-orange text-white font-semibold px-10 py-2 hover:bg-orange hover:bg-opacity-70 rounded-full"
            onClick={() => {
              setEdit(true);
            }}
          >
            Edit Profile
          </button>
        </div>
      </div>
    </div>
  );
};

const MyProfile = () => {
  return (
    <MyProfileProvider>
      <div className="w-screen h-screen flex flex-col items-center relative z-0 sm:p-10 px-5 py-10 overflow-auto space-y-5">
        <div className="flex flex-col justify-center w-full text-2xl font-bold text-dark-blue uppercase">
          <span>My Profile</span>
        </div>
        <div className="w-full flex lg:flex-row flex-col gap-y-10 items-start justify-start gap-x-10">
          <Content />
          <MyFavorites />
        </div>
      </div>
    </MyProfileProvider>
  );
};

export default MyProfile;
