import { isPattern } from "@babel/types";
import React from "react";

const passwordRegex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,}$";
const Textbox = ({
  name,
  label,
  type,
  value,
  onChange,
  placeholder,
  className,
  required,
  prepend,
  onKeyDown,
  onFocus,
  onBlur,
  pattern = type == "password" && passwordRegex
}) => {

  
  return (
    <div className="space-y-2">
      {label && (
        <label
          htmlFor={name}
          className="flex text-sm font-semibold text-gray-500 gap-x-1"
        >
          {label}
          {required && (
            <span className="text-red-500">
            *
            </span>
          )}
        </label>
      )}

      <div className="flex items-center">
        {prepend && { prepend }}
        <input
          id={name}
          name={name}
          type={type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          autoComplete="off"
          required={required}
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          onBlur={onBlur}
          pattern={pattern}
          className={`appearance-none block w-full px-3 py-2 bg-gray-50 rounded-md shadow-inner shadow-md placeholder-gray-400 focus:ring-offset-2 focus:ring-2 focus:outline-none focus:ring-LightOrange sm:text-sm ${className}`}
        />
      </div>
    </div>
  );
};

export default Textbox;
