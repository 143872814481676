import React, { useContext, useEffect, useState } from "react";
import { getPendingList } from "../../../../services/admin";
import { ContentsContext } from "../../../../context/ContentsContext";
import { useHistory } from "react-router";
import TableLoading from "./TableLoading";
import ContentError from "../../../Others/ContentError";
import DataTable from "../../../Base/DataTable";
import moment from "moment";
import { FlatButton } from "../../../Buttons/Buttons";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  EyeIcon,
} from "@heroicons/react/outline";
import { useQuery } from "react-query";

const PendingTable = () => {
  const context = useContext(ContentsContext);
  const filters = context.filters;
  const entries = context.entries;
  const page = context.page;
  const sortBy = context.sortBy;
  const history = useHistory();
  const { data, isError, isLoading } = useQuery(
    ["pendingContents", { filters, entries, page, sortBy }],
    getPendingList
  );

  const [width, setWidth] = useState(window.innerWidth);
  const [headers, setHeaders] = useState([]);

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  const SortBy = (type) => {
    const typeAsc = type + "Asc";
    const typeDesc = type + "Desc";
    context.sortBy === typeDesc
      ? context.setSortBy(typeAsc)
      : context.setSortBy(typeDesc);
  };

  useEffect(() => {
    if (context.table === "PendingRequests" && data) {
      context.setPageCount(data.pages);
    }
  }, [context.table, data]);

  useEffect(() => {
    if (width > 768) {
      setHeaders([
        {
          text: "Content Name",
          className: "text-left px-6 py-3",
          onClick: () => SortBy("name"),
          component:
            context.sortBy === "nameAsc"
              ? ChevronUpIcon
              : context.sortBy === "nameDesc"
              ? ChevronDownIcon
              : "",
        },
        {
          text: "Author",
          className: "text-left py-4",
          onClick: () => SortBy("author"),
          component:
            context.sortBy === "authorAsc"
              ? ChevronUpIcon
              : context.sortBy === "authorDesc"
              ? ChevronDownIcon
              : "",
        },
        { text: "Downloads", className: "", textAlign: "center" },
        { text: "Status", className: "", textAlign: "center" },
        {
          text: "Date Requested",
          className: "",
          textAlign: "center",
          onClick: () => SortBy("date"),
          component:
            context.sortBy === "dateAsc"
              ? ChevronUpIcon
              : context.sortBy === "dateDesc"
              ? ChevronDownIcon
              : "",
        },
        { text: "Actions", className: "", textAlign: "center" },
      ]);
    } else if (width > 639) {
      setHeaders([
        {
          text: "Content Name",
          className: "text-left px-6 py-3",
          onClick: () => SortBy("name"),
          component:
            context.sortBy === "nameAsc"
              ? ChevronUpIcon
              : context.sortBy === "nameDesc"
              ? ChevronDownIcon
              : "",
        },
        { text: "Status", className: "", textAlign: "center" },
        { text: "Actions", className: "", textAlign: "center" },
      ]);
    } else {
      setHeaders([
        {
          text: "Content Name",
          className: "text-left px-6 py-3",
          onClick: () => SortBy("name"),
          component:
            context.sortBy === "nameAsc"
              ? ChevronUpIcon
              : context.sortBy === "nameDesc"
              ? ChevronDownIcon
              : "",
        },
        { text: "Actions", className: "", textAlign: "center" },
      ]);
    }
  }, [width, context.sortBy]);

  if (context.table === "PendingRequests") {
    if (isLoading) {
      return <TableLoading />;
    }

    if (isError) {
      return <ContentError />;
    }

    return (
      <div className="space-y-2 ">
        <DataTable
          headers={headers}
          data={data.data}
          noDataText="No Pending Requests"
        >
          {data.data.map((content) => {
            return (
              <tr
                className="text-center text-sm text-gray-600 hover:bg-gray-100"
                key={content.contentId}
              >
                <td className="py-3 sm:px-6 px-3 w-3/12 text-left">
                  <div className="font-bold">{content.name}</div>
                  <div className="md:hidden block text-xs">
                    <div>
                      {content.authorName
                        ? content.authorName
                        : "Unknown Author"}
                    </div>
                    <div className="text-gray-400 mt-2">
                      {moment.utc(content.uploadedDate).local().format("lll")}
                    </div>
                  </div>
                </td>
                <td className="w-3/12 text-left md:table-cell hidden">
                  {content.authorName}
                </td>
                <td className="md:table-cell hidden">
                  {content.numberOfDownloads}
                </td>
                <td className={`text-xs sm:table-cell hidden`}>
                  <span
                    className={`${
                      content.status === "Approved"
                        ? "text-green-600 bg-green-200 px-3 py-1 rounded-3xl"
                        : content.status === "Pending"
                        ? "text-yellow-600 bg-yellow-200 px-3 py-1 rounded-3xl"
                        : content.status === "Declined"
                        ? "text-red-600 bg-red-200 px-3 py-1 rounded-3xl"
                        : "text-gray-600 bg-gray-200 px-3 py-1 rounded-3xl"
                    }`}
                  >
                    {content.status}
                  </span>
                </td>
                <td className="text-xs md:table-cell hidden">
                  {moment.utc(content.uploadedDate).local().format("lll")}
                </td>
                <td className="py-3 flex justify-center ">
                  <FlatButton
                    onClick={() => {
                      history.push(`/content-view/${content.contentId}/admin`);
                    }}
                    className={`bg-orange text-white items-center flex gap-x-2 px-5 hover:bg-orange hover:bg-opacity-50`}
                  >
                    <span className="text-xs">View</span>
                    <EyeIcon className="h-4 w-4" />
                  </FlatButton>
                </td>
              </tr>
            );
          })}
        </DataTable>
      </div>
    );
  }
  return <div></div>;
};

export default PendingTable;
