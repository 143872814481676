import {
  ChartSquareBarIcon,
  ClipboardListIcon,
  CollectionIcon,
  LibraryIcon,
  TagIcon,
  TemplateIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";
import React from "react";
import { useLocation, Link } from "react-router-dom";

const ItemButton = ({ text, path, component: Component }) => {
  const location = useLocation();

  return (
    <Link to={path}>
      <button
        className={`text-left w-full flex items-center lg:justify-start justify-center font-semibold py-2 sm:px-4 px-2  rounded-md gap-x-2 ${
          path === location.pathname
            ? "bg-light-blue text-orange ring-4 ring-Orange ring-opacity-30"
            : "text-white hover:bg-orange "
        }`}
      >
        <Component
          className={`h-5 w-5 ${
            path === location.pathname
              ? "text-orange opacity-80"
              : "text-white "
          }`}
        />
        <span className="lg:block hidden">{text}</span>
      </button>
    </Link>
  );
};

const AdminSidebar = () => {
  return (
    <div className="">
      <div className="top-0 h-full">
        <div className="bg-white my-10 md:ml-10 sm:ml-5 ml-0 sm:rounded-xl rounded-r-xl shadow-xl">
          <div className="lg:w-80 w-full  text-white bg-blue sm:p-5 p-3 rounded-xl ">
            <div className="p-3 flex items-center">
              <span className=" font-bold uppercase text-white flex w-full lg:justify-start justify-center items-center gap-x-2">
                <div className="lg:block hidden">Administrator</div>
                <LibraryIcon className="h-5 w-5" />
              </span>
            </div>
            <div className="flex flex-col space-y-2 rounded-lg">
              <ItemButton text="Dashboard" path="/" component={TemplateIcon} />
              <ItemButton
                text="Contents"
                path="/contents"
                component={CollectionIcon}
              />
              <ItemButton
                text="Categories"
                path="/categories"
                component={TagIcon}
              />
              <ItemButton
                text="Users"
                path="/users"
                component={UserGroupIcon}
              />
              <ItemButton
                text="Audit Logs"
                path="/logs"
                component={ClipboardListIcon}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSidebar;
