import React, { useEffect, useState, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import AccountsService from "../services/accounts";
import { useHistory } from "react-router-dom";
import Textbox from "../components/Inputs/Textbox";
import SignInError from "../components/Alerts/SignInError";
import BlockButton from "../components/Buttons/BlockButton";
import Loading from "../components/Others/Loading";
import { AuthContext } from "../context/AuthContext";
import { login } from "../services/accounts";
import Modal from "../components/Others/Modal";
import { FlatButton } from "../components/Buttons/Buttons";

const SignIn = () => {
  const authContext = useContext(AuthContext);
  const [UserName, setUserName] = useState("");
  const [Password, setPassword] = useState("");
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [pendingModal, setPendingModal] = useState(false);

  if (authContext.isAuthenticated()) history.push("/");

  const Login = async (e) => {
    e.preventDefault();

    setLoading(true);
    const data = {
      UserName,
      Password,
    };
    login(data)
      .then((result) => {
        // console.log(result);
        setTimeout(() => {
          if (result.status === 200) {
            setErrors("");
            authContext.setAuthState(result.data);
            // history.push("/");
            window.location.reload(false);
          } else {
            if (result.data) {
              if (result.status === 401)
                setErrors(
                  "Username or Password is incorrect, Please try again."
                );
              if (result.data === 1) {
                setPendingModal(true);
              }
            }
          }
          setLoading(false);
        }, 1000);
      })
      .catch(() => {
        setErrors("Username or Password is incorrect, Please try again.");
        setLoading(false);
      });
  };

  return (
    <div className="flex justify-center overflow-auto sm:py-0">
      <Modal
        title="Registration Pending"
        isOpen={pendingModal}
        description="Please wait for your registration to be approved by the administrators. Thank you!"
        onClose={() => setPendingModal(false)}
      >
        <div className="flex items-center space-x-2 float-right">
          <FlatButton
            className="btn-Orange-secondary"
            onClick={() => history.push("/")}
          >
            Browse Contents as Guest
          </FlatButton>
        </div>
      </Modal>
      <div className="absolute h-screen w-screen top-0 left-1/2 z-0 bg-orange transform rotate-45 shadow-xl"></div>
      <div className="relative z-10 xl:flex flex-col hidden items-center justify-center w-full space-y-2">
        <div className="w-40 h-40">
          <img src="CSI-Logo-02.png" className="w-full object-fit" />
        </div>
        <div className="text-black text-3xl font-bold ">
          Catholic Safeguarding Institute
        </div>
        <div className="text-xl font-semibold text-blue opacity-80">
          Online Library
        </div>
        <FlatButton
          className="bg-transparent text-black text-lg"
          onClick={() => history.push("/")}
        >
          Browse Contents as Guest
        </FlatButton>
      </div>
      <div className="w-full h-screen flex items-center">
        <div className="xl:w-9/12 lg:10/12 w-full relative">
          <div className="bg-white sm:p-8 py-10 p-5 shadow-xl relative rounded-3xl overflow-hidden sm:px-10 sm:mx-10 mx-2 space-y-3">
            <Loading loading={loading} text="Logging In" />
            <div className="flex flex-col xl:hidden items-center ">
              <div className="w-20 h-20">
                <img src="CSI-Logo-02.png" className="w-full object-fit" />
              </div>
              <div className="text-Orange text-lg font-bold ">
                Catholic Safeguarding Institute
              </div>
              <div className="text-xl font-semibold text-Orange opacity-80">
                Online Library
              </div>
              <FlatButton
                className="bg-transparent text-black text-blue my-2"
                onClick={() => history.push("/")}
              >
                Browse Contents as Guest
              </FlatButton>
            </div>
            <h2 className="md:block hidden mb-4 text-left text-4xl font-bold text-blue text-CSIBlue">
              Welcome Back!
            </h2>
            <form
              className="space-y-5"
              autoComplete="off"
              onSubmit={(e) => Login(e)}
            >
              <Textbox
                name="Username"
                label="Username"
                type="text"
                value={UserName}
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
                required
              />
              <Textbox
                name="Password"
                label="Password"
                type="password"
                value={Password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                required
                pattern={null}
              />
              <SignInError errors={errors} />

              {/* <BlockButton type="submit" text="Login" /> */}
              <div className="w-full flex justify-center">
                <FlatButton
                  className="bg-light-blue text-dark-blue font-semibold px-10"
                  type="submit"
                >
                  LOGIN
                </FlatButton>
              </div>
              <div className="w-full flex flex-col items-center justify-center font-semibold text-gray-600">
                <Link
                  to="/forgot-password"
                  className="text-sm hover:underline  hover:text-Orange"
                >
                  Forgot Password?
                </Link>
                <Link
                  to="/register"
                  className="text-sm hover:underline hover:text-Orange "
                >
                  Create Account
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SignIn);
