import React, { useContext } from "react";
import Trending from "./Carousel/Trending";
import NewReleases from "./Carousel/NewReleases";
import GuestContents from "./GuestContents";
import { FlatButton } from "../Buttons/Buttons";
import { useHistory } from "react-router-dom";
import { FilterContext } from "../../context/FilterContext";

const GuestDashboard = () => {
  const history = useHistory();
  const filterContext = useContext(FilterContext);

  if (filterContext.filterState.filter) {
    return (
      <div
        id="content-scroll"
        className="z-0 lg:p-10 mb-20 p-4 w-full overflow-auto flex flex-col space-y-4"
      >
        <div>
          <div className="flex items-center w-full text-xl font-bold text-dark-blue uppercase mb-5">
            <span>All Contents</span>
          </div>
          <GuestContents />
        </div>
      </div>
    );
  }

  return (
    <div className="z-0 lg:p-10 mb-20 p-4 w-full overflow-auto flex flex-col space-y-4">
      <div>
        <div>
          <div className="flex px-10 md:pt-10 lg:pt-0 pt-10 items-center w-full text-xl font-bold text-gray-600">
            <span>Trending Now</span>
            {/* <FlatButton
              className="ml-auto"
              onClick={() => {
                history.push("/trending");
              }}
            >
              See More
            </FlatButton> */}
          </div>
          <Trending />
        </div>
        <div>
          <div className="flex items-center w-full text-xl px-10 md:pt-10 lg:pt-0 pt-10 font-bold text-gray-600">
            <span>New Releases</span>
            {/* <FlatButton
              className="ml-auto"
              onClick={() => {
                history.push("/new-releases");
              }}
            >
              See More
            </FlatButton> */}
          </div>
          <NewReleases />
        </div>
      </div>
    </div>
  );
};

export default GuestDashboard;
