import React, { useContext } from "react";
import { ContentsContext } from "../../../context/ContentsContext";
import { FlatButton } from "../../Buttons/Buttons";
import Select from "../../Inputs/Select";
import { useHistory } from "react-router";
import { DocumentDownloadIcon } from "@heroicons/react/outline";
import {
  exportContents,
  exportReportedContents,
} from "../../../services/export";

const Tabs = () => {
  const context = useContext(ContentsContext);
  const history = useHistory();

  const Export = () => {
    if (context.table === "ReportedContents") {
      exportReportedContents(context.filters);
    } else {
      exportContents(context.filters);
    }
  };
  return (
    <div>
      <div className="flex items-center md:flex-row flex-col gap-y-4">
        <div className="w-full flex space-x-5 sm:flex-row flex-col gap-y-4 items-center">
          <div className="bg-gray-200 rounded-lg p-1 flex gap-x-1 w-auto">
            <FlatButton
              className={`${
                context.table === "AllRequests"
                  ? "btn-Orange-primary"
                  : "text-gray-400 hover:bg-gray-300 hover:text-gray-500"
              }`}
              onClick={() => {
                context.setTable("AllRequests");
                context.setPage(1);
              }}
            >
              All Requests
            </FlatButton>
            <FlatButton
              className={`${
                context.table === "PendingRequests"
                  ? "btn-Orange-primary"
                  : "text-gray-400 hover:bg-gray-300 hover:text-gray-500"
              }`}
              onClick={() => {
                context.setTable("PendingRequests");
                context.setPage(1);
              }}
            >
              Pending Requests
            </FlatButton>
            <FlatButton
              className={`${
                context.table === "ReportedContents"
                  ? "btn-Orange-primary"
                  : "text-gray-400 hover:bg-gray-300 hover:text-gray-500"
              }`}
              onClick={() => {
                context.setTable("ReportedContents");
                context.setPage(1);
              }}
            >
              Reported Contents
            </FlatButton>
          </div>
          <div className="flex items-center space-x-2 text-sm font-semibold text-gray-600">
            <div className="flex items-center">Show</div>
            <div className="w-16 ">
              <Select
                value={context.entries}
                className="bg-gray-200 rounded-md"
                onChange={(e) => {
                  context.setEntries(e.target.value);
                  context.setPage(1);
                }}
              >
                {[...Array(6)].map((x, i) => {
                  return <option key={i}>{i + 5}</option>;
                })}
              </Select>
            </div>
            <div>Entries</div>
          </div>
        </div>

        <div className="flex sm:flex-row flex-col items-center justify-end w-full gap-x-3 gap-y-2">
          <FlatButton
            onClick={() => {
              history.push("/all-contents");
            }}
            className="btn-Gray text-gray-600 md:w-auto w-full justify-center"
          >
            View All Content
          </FlatButton>
          <FlatButton
            className="bg-dark-orange text-white hover:bg-dark-orange hover:bg-opacity-50 space-x-2 md:w-auto w-full justify-center"
            onClick={() => {
              Export();
            }}
          >
            <span>Export File </span>
            <DocumentDownloadIcon className="h-5 w-5" />
          </FlatButton>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
