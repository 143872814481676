import React from "react";

const DatePicker = ({ onChange, label, value }) => {
  return (
    <div className="space-y-2">
      <label
        htmlFor={label}
        className="block text-sm font-medium text-gray-500"
      >
        {label}
      </label>
      <input
        name={label}
        className="outline-none rounded-md p-2 w-full text-sm focus:ring-2 focus:outline-none focus:ring-LightOrange focus:ring-offset-2 bg-gray-50 shadow-inner shadow-md"
        type="date"
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

export default DatePicker;
