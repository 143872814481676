import React from "react";
import { ExclamationIcon } from "@heroicons/react/outline";

const SignInError = ({ errors }) => {
  return (
    <div>
      {errors !== "" ? (
        <div className="bg-red-100 bg-opacity-70 flex items-center text-red-600 p-3 rounded-md text-sm text-center">
          <ExclamationIcon className="h-5 w-5 mr-1" /> <span> {errors}</span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default SignInError;
