import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router";
import Textbox from "../../Inputs/Textbox";
import { DocumentDownloadIcon } from "@heroicons/react/outline";
import { FlatButton } from "../../Buttons/Buttons";
import { ContentsContext } from "../../../context/ContentsContext";
import DatePicker from "../../Inputs/DatePicker";
import Select from "../../Inputs/Select";
import { exportToExcel } from "../../../services/contents";

const Filters = ({ ExportFileFunction }) => {
  const context = useContext(ContentsContext);

  return (
    <div className="">
      <div className="bg-white sm:p-8 p-5 rounded-3xl shadow-xl space-y-4">
        <div className="font-bold text-Orange text-lg">Filters</div>
        <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-x-5 gap-y-5 items-end">
          <div className="">
            <Textbox
              label="Content Name"
              value={context.contentName}
              onChange={(e) => {
                context.setContentName(e.target.value);
              }}
            />
          </div>
          <div className="">
            <Textbox
              value={context.author}
              label="Author"
              onChange={(e) => {
                context.setAuthor(e.target.value);
              }}
            />
          </div>
          {context.table === "AllRequests" && (
            <div className="">
              <Select
                value={context.status}
                label="Status"
                onChange={(e) => {
                  context.setStatus(e.target.value);
                }}
              >
                <option>Any</option>
                <option>Approved</option>
                <option>Pending</option>
                <option>Deleted</option>
                <option>Declined</option>
                <option>Disabled</option>
              </Select>
            </div>
          )}
          <div className="">
            <DatePicker
              value={context.dateRequestedFrom}
              label="Date Requested (From)"
              onChange={(e) => {
                context.setDateRequestedFrom(e.target.value);
              }}
            />
          </div>
          <div className="">
            <DatePicker
              value={context.dateRequestedTo}
              label="Date Requested (To)"
              onChange={(e) => {
                context.setDateRequestedTo(e.target.value);
              }}
            />
          </div>
          <div className="flex space-x-4">
            <FlatButton
              className="w-full justify-center bg-blue hover:bg-blue text-white hover:bg-opacity-70"
              onClick={() => {
                context.ApplyFilters();
              }}
            >
              Search
            </FlatButton>
            <FlatButton
              className="w-full justify-center btn-Orange-secondary"
              onClick={() => {
                context.ResetFilters();
              }}
            >
              Reset
            </FlatButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filters;
