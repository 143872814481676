import React from "react";
import Content from "../components/Admin/Contents/Content";
import { ContentsProvider } from "../context/ContentsContext";
import AdminSidebar from "../components/Base/AdminSidebar";

const Contents = () => {
  return (
    <div className="h-full ">
      <div className="flex h-full w-full">
        <AdminSidebar />
        <ContentsProvider>
          <Content />
        </ContentsProvider>
      </div>
    </div>
  );
};

export default Contents;
