import React, { createContext, useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useToasts } from "react-toast-notifications";
import * as tus from "tus-js-client";
import { deleteContent, updateContent } from "../services/contents";
import { useQueryClient } from "react-query";
import { deleteContentFile } from "../services/contents";
import { deleteUploadedFile } from "../services/contents";
import Modal from "../components/Others/Modal";
import { FlatButton } from "../components/Buttons/Buttons";
import { findAllByTestId } from "@testing-library/dom";
const EditContentContext = createContext();
const { Provider } = EditContentContext;

const EditContentProvider = ({ children }) => {
  const [data, setData] = useState(null);
  const [step, setStep] = useState(1);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [publisher, setPublisher] = useState("");
  const [categories, setCategories] = useState([]);
  const [contentType, setContentType] = useState("");
  const [language, setLanguage] = useState("");
  const [author, setAuthor] = useState("");
  const [files, setFiles] = useState([]);
  const [existingFiles, setExistingFiles] = useState([]);
  const [embed, setEmbed] = useState("");
  const [publishedDate, setPublishedDate] = useState("");
  const [releaseDate, setReleaseDate] = useState("");
  const [mainIndex, setMainIndex] = useState(0);
  const [isEmbeddedMain, setIsEmbeddedMain] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [mergedFiles, setMergedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState([]);
  const [uploadStatus, setUploadStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [fileExist, setFileExist] = useState(false);
  const [contentId, setContentId] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const history = useHistory();
  const { addToast } = useToasts();
  const queryClient = useQueryClient();

  var env = process.env.NODE_ENV || "development";
  const server = `${
    env === "development"
      ? process.env.REACT_APP_DEV_SERVER
      : process.env.REACT_APP_PROD_SERVER
  }`;

  useEffect(() => {
    if (embed !== "" && files.length === 0) {
      setIsEmbeddedMain(true);
    }
  }, [embed, files]);

  useEffect(() => {
    merge();
  }, [existingFiles, files]);

  const DeleteContent = () => {
    setDeleteLoading(true);
    deleteContent(contentId)
      .then((res) => {
        // console.log(res);
        addToast("Your Content has been Deleted", { appearance: "success" });
        setDeleteLoading(false);
        queryClient.invalidateQueries("my-content");
        history.push("/my-contents");
      })
      .catch((e) => {
        // console.log(e);
        addToast("Error deleting content", { appearance: "error" });
        setDeleteLoading(false);
      });
  };

  const merge = () => {
    var tempFile = [];

    existingFiles.map((f, i) => {
      tempFile.push({ fileName: f.fileName, fileId: f.fileId });
      if (f.isMain) {
        setMainIndex(i);
      }
    });

    files.map((f) => {
      tempFile.push({ fileName: f.name, fileId: "" });
    });

    setMergedFiles(tempFile);
  };

  const Reset = () => {
    setStep(1);
    setTitle("");
    setDescription("");
    setAuthor("");
    setPublisher("");
    setPublishedDate("");
    setReleaseDate("");
    setEmbed("");
    setExistingFiles([]);
    setCategories([]);
    setContentType();
    setFiles([]);
    setMainIndex(0);
    setThumbnail(null);
    setKeywords([]);
  };

  const UploadFile = (e) => {
    try {
      var file = e.target.files[0];

      if (files.filter((f) => f.name === file.name).length > 0) {
        setFileExist(true);
      } else {
        setFiles([...files, file]);

        var upload = new tus.Upload(file, {
          endpoint: `${server}/files/`,
          retryDelays: [0, 3000, 5000, 10000, 20000],
          metadata: {
            name: file.name,
            contentType: file.type,
          },
          onError: function (error) {
            // if (error.indexOf("400")) console.log("400");
            addToast("File already exist", { appearance: "error" });
            // console.log(error);
            setIsUploading(false);

            setFiles((files) => files.filter((f) => f.name !== file.name));
            setUploadStatus(
              uploadStatus.filter((stat) => stat.name !== file.name)
            );
            setUploadProgress(
              uploadProgress.filter((prog) => prog.name !== file.name)
            );
            // setUploadProgress((x) => x.filter((y) => y.name !== file.name));
            // setUploadStatus((x) => x.filter((y) => y.name !== file.name));
          },
          onProgress: function (bytesUploaded, bytesTotal) {
            var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);

            const fileProgress = [
              ...uploadProgress,
              {
                name: file.name,
                percentage: percentage,
              },
            ];
            // console.warn(percentage);
            setIsUploading(true);
            setUploadProgress(fileProgress);
          },
          onSuccess: function () {
            const fileStatus = [
              ...uploadStatus,
              {
                name: file.name,
                status: "success",
              },
            ];
            setIsUploading(false);
            setUploadStatus(fileStatus);
          },
        });
        upload.findPreviousUploads().then(function (previousUploads) {
          // Found previous uploads so we select the first one.
          // if (previousUploads.length) {
          //   upload.resumeFromPreviousUpload(previousUploads[0]);
          // }
          // Start the upload
          upload.start();

          // setFileExist(true);
        });
      }
    } catch (e) {
      // console.log(e);
    }
  };

  const RemoveFile = (newFile, fileId) => {
    if (newFile) {
      const temp = files;

      // temp.splice(fileId, 1);

      deleteUploadedFile(fileId)
        .then((res) => {
          addToast("File has been deleted successfully", {
            appearance: "success",
          });
          const removeStatus = uploadStatus.filter(
            (stat) => stat.name !== fileId
          );
          // console.warn(temp);
          const removeProgress = uploadProgress.filter(
            (prog) => prog.name !== fileId
          );

          setUploadStatus(removeStatus);
          setUploadProgress(removeProgress);
          setLoading(false);
          setFiles(temp.filter((x) => x.name !== fileId));
        })
        .catch((e) => {
          // console.log(e);
          addToast("Failed to delete file", { appearance: "error" });
          setLoading(false);
        });
    } else {
      const temp = existingFiles;
      temp.map((file) => {
        if (file.fileId === fileId) {
          if (file.isMain) setMainIndex(0);
        }
      });
      // const index = temp.findIndex((file) => file.fileId === fileId);
      //  temp.splice(index, 1);
      // console.warn(index);
      // console.log(uploadStatus.filter((stat, i) => stat[i] !== temp[index]));
      deleteContentFile(fileId)
        .then((res) => {
          addToast("File has been deleted successfully", {
            appearance: "success",
          });
          setLoading(false);

          setExistingFiles(temp.filter((x) => x.fileId !== fileId));
          // setExistingFiles(temp);
        })
        .catch((e) => {
          // console.log(e);
          addToast("Failed to delete file", { appearance: "error" });
          setLoading(false);
        });
    }
    merge();
  };
  // console.warn(uploadStatus);
  // console.error(uploadProgress);
  const UpdateContent = () => {
    setLoading(true);
    var formData = new FormData();
    formData.append("Title", title);
    formData.append("Description", description);
    formData.append("Publisher", publisher);
    formData.append("Author", author);
    formData.append("EmbeddedUrl", embed);
    categories.forEach((c, index) => {
      formData.append(`CategoryIds[${index}]`, JSON.stringify(c.categoryId));
    });
    formData.append("ContentTypeId", contentType.typeId);
    formData.append("PublishedDate", publishedDate);
    formData.append("ReleaseDate", releaseDate);
    formData.append("IsEmbeddedMain", isEmbeddedMain);
    formData.append("Language", "English");
    if (thumbnail) formData.append("Thumbnail", thumbnail);

    mergedFiles.forEach((f, index) => {
      formData.append(`Files[${index}].FileName`, f.fileName);
      formData.append(
        `Files[${index}].IsMain`,
        mainIndex === index ? true : false
      );
      formData.append(`Files[${index}].Id`, f.fileId);
    });

    formData.append("Keywords", keywords.join(";"));

    updateContent(contentId, formData)
      .then((res) => {
        setUploadProgress([]);
        setUploadStatus([]);
        Reset();
        addToast("Content Updated", { appearance: "success" });
        history.push("/my-contents");
        setLoading(false);
      })
      .catch((e) => {
        // console.log(e.response);

        // if (e.response.status === 500)
        //   addToast(e.response.data.message, { appearance: "error" });
        addToast("Error Updating Content", { appearance: "error" });
        setLoading(false);
      });
  };

  return (
    <Provider
      value={{
        isEmbeddedMain,
        setIsEmbeddedMain,
        isUploading,
        keywords,
        setKeywords,
        contentId,
        setContentId,
        fileExist,
        setFileExist,
        Reset,
        UpdateContent,
        loading,
        RemoveFile,
        UploadFile,
        thumbnailUrl,
        setThumbnailUrl,
        deleteLoading,
        DeleteContent,
        publishedDate,
        setPublishedDate,
        releaseDate,
        setReleaseDate,
        loading,
        uploadProgress,
        uploadStatus,
        files,
        step,
        mainIndex,
        mergedFiles,
        setMainIndex,
        setStep,
        thumbnail,
        setThumbnail,
        title,
        setTitle,
        description,
        setDescription,
        existingFiles,
        setExistingFiles,
        publisher,
        setPublisher,
        categories,
        setCategories,
        contentType,
        setContentType,
        language,
        setLanguage,
        author,
        setAuthor,
        embed,
        setEmbed,
        data,
        setData,
      }}
    >
      {children}
    </Provider>
  );
};

export { EditContentProvider, EditContentContext };
