import axios from "axios";
import { authAxios } from "./interceptor";

var env = process.env.NODE_ENV || "development";
const baseUrl = `${
  env === "development"
    ? process.env.REACT_APP_DEV_SERVER
    : process.env.REACT_APP_PROD_SERVER
}/users`;

export const messageMemberList = (page, queryKey) => {
  var params = "";
  const [_key, { name }] = queryKey;
  if (name !== "") params = "&Fullname=" + name;
  return authAxios
    .get(`${baseUrl}/user-connections?PageSize=10&PageNumber=${page}${params}`)
    .then((res) => {
      return res.data;
    });
};

export const chatMemberDetails = ({ queryKey }) => {
  const [_key, { userName }] = queryKey;
  return authAxios
    .get(`${baseUrl}/get-connection-details/${userName}`)
    .then((res) => {
      return res.data;
    });
};
