import React, { useContext, useState, useEffect } from "react";
import { LogsContext } from "../../../context/LogsContext";
import { useQuery } from "react-query";
import { getLogs } from "../../../services/admin";
import ContentError from "../../Others/ContentError";
import Loading from "../../Others/Loading";
import {
  ArrowNarrowRightIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/outline";
import DataTable from "../../Base/DataTable";
import moment from "moment";

const Table = () => {
  const context = useContext(LogsContext);
  const filters = context.filters;
  const page = context.page;
  const entries = context.entries;
  const sortBy = context.sortBy;
  const { data, isError, isLoading } = useQuery(
    ["categories", { filters, entries, page, sortBy }],
    getLogs
  );

  const [headers, setHeaders] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [sort, setSort] = useState(false);

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  useEffect(() => {
    if (sort) context.setSortBy("asc");
    else context.setSortBy("desc");
  }, [sort, context]);

  useEffect(() => {
    if (data) context.setPageCount(data.pages);
  }, [data, context]);

  useEffect(() => {
    if (width > 639) {
      setHeaders([
        {
          text: "Username",
          className: "text-left px-6 py-4 ",
        },
        {
          text: "Action",
          className: "cursor-pointer",
          textAlign: "center",
        },
        { text: "Module", className: "", textAlign: "center" },
        { text: "Affected Record", className: "", textAlign: "center" },
        { text: "Status", className: "", textAlign: "center" },
        {
          text: "Date",
          className: " cursor-pointer",
          textAlign: "center",
          onClick: () => {
            setSort((sort) => !sort);
          },
          component: context.sortBy === "asc" ? ChevronUpIcon : ChevronDownIcon,
        },
      ]);
    } else {
      setHeaders([
        {
          text: "Details",
          className: "text-left cursor-pointer py-4 px-6",
          onClick: () => setSort((sort) => !sort),
          component:
            context.sortBy === "asc"
              ? ChevronUpIcon
              : context.sortBy === "desc"
              ? ChevronDownIcon
              : "",
        },
      ]);
    }
  }, [width, context.sortBy]);

  if (isLoading) {
    return (
      <div className="animate-pulse">
        <div className="w-full h-96 bg-white shadow-xl rounded-3xl p-5 space-y-6 overflow-hidden relative flex justify-center">
          <Loading loading={true} />
        </div>
      </div>
    );
  }

  if (isError) {
    return <ContentError />;
  }

  return (
    <DataTable headers={headers} data={data.data} noDataText="Data Not Found.">
      {data.data.map((log, i) => {
 
        return (
          <tr
            key={i}
            className="text-center text-gray-600 text-sm hover:bg-gray-100"
          >
            <td className="text-left px-6 py-3">
              <div className="font-semibold">{log.updatedBy}</div>
              <div className="sm:hidden block space-y-1">
                <div>{log.action}</div>
                <div className="flex items-center gap-x-2 ">
                  <div
                    className={`flex items-center text-xs px-2 py-1 justify-center rounded-full ${
                      JSON.parse(log.oldValue).Status === "Approved" ||
                      JSON.parse(log.oldValue).Name !== "" ||
                      JSON.parse(log.oldValue).Status === 2
                        ? "text-green-600 bg-green-200"
                        : JSON.parse(log.oldValue).Status === "Pending" ||
                          JSON.parse(log.oldValue).Status === 1
                        ? "text-yellow-600 bg-yellow-200"
                        : JSON.parse(log.oldValue).Status === "Declined" ||
                          JSON.parse(log.oldValue).Status === 4 ||
                          JSON.parse(log.oldValue).Status === "Deleted" ||
                          JSON.parse(log.oldValue).Name
                        ? "text-red-600 bg-red-200"
                        : "text-gray-600 bg-gray-200"
                    }`}
                  >
                    {JSON.parse(log.oldValue).Status === 1
                      ? "Pending"
                      : JSON.parse(log.oldValue).Status === 2
                      ? "Active"
                      : JSON.parse(log.oldValue).Status === 3
                      ? "Disabled"
                      : JSON.parse(log.oldValue).Status === 4
                      ? "Declined"
                      : JSON.parse(log.oldValue).Status ||
                        JSON.parse(log.oldValue).Name}
                  </div>
                  <ArrowNarrowRightIcon className="h-4 w-4" />
                  <div
                    className={`flex items-center text-xs px-2 py-1 justify-center rounded-full ${
                      JSON.parse(log.newValue).Status === "Approved" ||
                      JSON.parse(log.newValue).Name ||
                      JSON.parse(log.newValue).Status === 2
                        ? "text-green-600 bg-green-200"
                        : JSON.parse(log.newValue).Status === "Pending" ||
                          JSON.parse(log.newValue).Name !== "" ||
                          JSON.parse(log.newValue).Status === 1
                        ? "text-yellow-600 bg-yellow-200"
                        : JSON.parse(log.newValue).Status === "Declined" ||
                          JSON.parse(log.newValue).Status === "Deleted" ||
                          JSON.parse(log.newValue).Status === 4
                        ? "text-red-600 bg-red-200"
                        : "text-gray-600 bg-gray-200"
                    }`}
                  >
                    {JSON.parse(log.newValue).Status === 1
                      ? "Pending"
                      : JSON.parse(log.newValue).Status === 2
                      ? "Active"
                      : JSON.parse(log.newValue).Status === 3
                      ? "Disabled"
                      : JSON.parse(log.newValue).Status === 4
                      ? "Declined"
                      : JSON.parse(log.newValue).Status}
                  </div>
                </div>
                <div>{log.type}</div>
                <div className="text-xs">
                  {moment.utc(log.createOn).local().format("lll")}
                </div>
              </div>
            </td>
            <td className="sm:table-cell hidden">{log.action}</td>

            <td className="sm:table-cell hidden">{log.type}</td>
            <td className="sm:table-cell hidden">{log.updatedRecord}</td>
            <td className="items-center justify-center gap-x-2 py-3 sm:flex hidden">
              <div
                className={`flex items-center text-xs px-2 py-1 justify-center rounded-full ${
                  JSON.parse(log.oldValue).Status === "Approved" ||
                  JSON.parse(log.oldValue).Status === 2
                    ? "text-green-600 bg-green-200"
                    : JSON.parse(log.oldValue).Status === "Pending" ||
                      JSON.parse(log.oldValue).Status === 1
                    ? "text-yellow-600 bg-yellow-200"
                    : JSON.parse(log.oldValue).Status === "Declined" ||
                      JSON.parse(log.oldValue).Status === 4
                    ? "text-red-600 bg-red-200"
                    : "text-gray-600 bg-gray-200"
                }`}
              >
                {JSON.parse(log.oldValue).Status === 1
                  ? "Pending"
                  : JSON.parse(log.oldValue).Status === 2
                  ? "Active"
                  : JSON.parse(log.oldValue).Status === 3
                  ? "Disabled"
                  : JSON.parse(log.oldValue).Status === 4
                  ? "Declined"
                  : JSON.parse(log.oldValue).Status}
              </div>
              <ArrowNarrowRightIcon className="h-4 w-4" />
              <div
                className={`flex items-center text-xs px-2 py-1 justify-center rounded-full ${
                  JSON.parse(log.newValue).Status === "Approved" ||
                  JSON.parse(log.newValue).Status === 2
                    ? "text-green-600 bg-green-200"
                    : JSON.parse(log.newValue).Status === "Pending" ||
                      JSON.parse(log.newValue).Status === 1
                    ? "text-yellow-600 bg-yellow-200"
                    : JSON.parse(log.newValue).Status === "Declined" ||
                      JSON.parse(log.newValue).Status === 4
                    ? "text-red-600 bg-red-200"
                    : "text-gray-600 bg-gray-200"
                }`}
              >
                {JSON.parse(log.newValue).Status === 1
                  ? "Pending"
                  : JSON.parse(log.newValue).Status === 2
                  ? "Active"
                  : JSON.parse(log.newValue).Status === 3
                  ? "Disabled"
                  : JSON.parse(log.newValue).Status === 4
                  ? "Declined"
                  : JSON.parse(log.newValue).Status}
              </div>
            </td>
            <td className="text-xs sm:table-cell hidden">
              {moment.utc(log.createOn).local().format("lll")}
            </td>
          </tr>
        );
      })}
    </DataTable>
  );
};

export default Table;
