import React from "react";
import ContentNotFound from "../Others/ContentNotFound";

const DataTable = ({ children, headers, data, noDataText }) => {
  return (
    <div className="w-full rounded-3xl shadow-xl bg-white">
      {data.length > 0 ? (
        <table className="rounded-3xl w-full table-auto overflow-hidden">
          <thead className="">
            <tr className="bg-xdark-blue text-white uppercase text-sm ">
              {headers.map(
                (
                  { text, className, onClick, component: Component, textAlign },
                  key
                ) => {
                  return (
                    <th key={key} className={className} onClick={onClick}>
                      <div
                        className={`flex items-center w-full justify-${textAlign}`}
                      >
                        {text}{" "}
                        {Component && <Component className="h-4 w-4 ml-2" />}
                      </div>
                    </th>
                  );
                }
              )}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">{children}</tbody>
        </table>
      ) : (
        <div className="w-full  rounded-3xl shadow-xl bg-white p-3">
          <ContentNotFound text={noDataText} />
        </div>
      )}
    </div>
  );
};

export default DataTable;
