import React, { useEffect, useState } from "react";
import { FlatButton } from "../../Buttons/Buttons";
import Loader from "react-loader-spinner";
import DataTable from "../../Base/DataTable";
import moment from "moment";
import { useLocation } from "react-router";
import ImageNotFound from "../../../assets/ImageNotFound.jpg";
import ReactHtmlParser from "react-html-parser";

var env = process.env.NODE_ENV || "development";
const server = `${
  env === "development"
    ? process.env.REACT_APP_DEV_SERVER
    : process.env.REACT_APP_PROD_SERVER
}`;

const Summary = ({ context }) => {
  const location = useLocation();
  const [iframeLoading, setIframeLoading] = useState(true);
  const headers = [
    { text: "Filename", className: "py-4 px-6 text-left" },
    { text: "Type", className: "" },
    { text: "Size", className: "" },
    { text: "Date", className: "" },
    { text: "Action", className: "" },
  ];

  useEffect(() => {
    if (context.embed) {
      const iframes = document.querySelectorAll("iframe");
      iframes.forEach((iframe) => {
        iframe.width = "100%";
        iframe.height = "100%";
      });
    }
  }, [context.embed]);

  const embedHeader = [
    {
      text: "Embed",
      className: "py-4 px-6 text-left",
    },
    { text: "Action", className: "" },
  ];
  return (
    <div className="space-y-7 pb-20">
      <div className=" rounded-3xl shadow-xl bg-white p-8 space-y-4">
        <div className="font-bold text-Orange text-xl flex items-center">
          <span>Summary</span>
          <div className="ml-auto sm:block hidden">
            {!context.loading ? (
              <FlatButton
                className="btn-Orange-primary w-48 justify-center gap-x-2"
                onClick={() => {
                  location.pathname === "/create-content"
                    ? context.SubmitContent()
                    : context.UpdateContent();
                }}
              >
                Submit
              </FlatButton>
            ) : (
              <FlatButton className="flex btn-Orange-primary w-48 justify-center gap-x-2">
                Submitting Content
                <Loader type="TailSpin" color="#FFF" height={20} width={20} />
              </FlatButton>
            )}
          </div>
        </div>
        <div className="space-y-6">
          <div className="flex md:flex-row gap-y-4 flex-col items-center  flex-center gap-x-4">
            <div className="md:w-96 flex-none w-full h-50 flex justtify-center border rounded-xl shadow-xl overflow-hidden">
              <img
                src={
                  context.thumbnailUrl !== ""
                    ? server + context.thumbnailUrl
                    : context.thumbnail !== null
                    ? URL.createObjectURL(context.thumbnail)
                    : ImageNotFound
                }
                className="h-full w-full object-cover"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = ImageNotFound;
                }}
              />
            </div>
            <div className="flex flex-col gap-y-4 flex-auto break-all text-gray-600">
              <div className="w-full">
                <div className="title flex items-center">
                  <span className="text-3xl font-bold">{context.title}</span>
                </div>
                <div className="text-gray-500 font-semibold">
                  {context.author}
                </div>
                <div className="text-xs ">
                  <div>{context.publisher}</div>
                  <div>
                    {context.publishedDate &&
                      moment(context.publishedDate).format("ll")}
                  </div>
                </div>
              </div>
              <div className="description">{context.description}</div>
              <div className="tags flex-auto flex flex-col justify-end space-y-2">
                <span className="text-gray-500  text-xs">Categories</span>
                <div className="flex space-x-2 rounded-md">
                  {context.categories.map((c, key) => {
                    return (
                      <div
                        key={key}
                        className="text-xs font-semibold bg-gray-200 py-2 px-3 rounded-md "
                      >
                        {c.categoryName}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="sm:hidden block w-full">
              {!context.loading ? (
                <FlatButton
                  className="btn-Orange-primary w-full justify-center gap-x-2"
                  onClick={() => {
                    location.pathname === "/create-content"
                      ? context.SubmitContent()
                      : context.UpdateContent();
                  }}
                >
                  Submit
                </FlatButton>
              ) : (
                <FlatButton className="flex btn-Orange-primary w-48 justify-center gap-x-2">
                  Submitting Content
                  <Loader type="TailSpin" color="#FFF" height={20} width={20} />
                </FlatButton>
              )}
            </div>
          </div>
          {/* {context.embed && (
            <div className="">
              <div className="text-sm font-semibold text-gray-500">
                Embedded Link
              </div>
              <div className="text-sm text-gray-600">{context.embed}</div>
            </div>
          )} */}
        </div>
      </div>

      {/* {location.pathname === "/create-content" ||
        (location.pathname === `/my-contents/${context.contentId}/manage` &&
          context.embed !== "" && (
            <DataTable headers={embedHeader} data={context.embed}>
              <tr className="ttext-center text-sm text-gray-600">
                <td className="text-left px-6">{context.embed}</td>
                <td className="flex text-left py-3 px-3">
                  {context.isEmbeddedMain ? (
                    <div className="text-xs font-semibold px-3 py-1 bg-Orange text-white rounded-md">
                      Main File
                    </div>
                  ) : (
                    <button
                      className="text-xs font-semibold px-3 py-1 border rounded-md hover:bg-gray"
                      onClick={() => {
                        context.setMainIndex(null);
                        context.setIsEmbeddedMain(true);
                      }}
                    >
                      Set as Main File
                    </button>
                  )}
                </td>
              </tr>
              );
            </DataTable>
          ))}

      {location.pathname === "/create-content" && (
        <DataTable headers={headers} data={context.files}>
          {context.files.map((file, key) => {
            return (
              <tr key={key} className="text-center text-sm text-gray-600">
                <td className="text-left px-6">{file.name}</td>
                <td className="text-left px-6">{file.type}</td>
                <td>{file.size}</td>
                <td>{moment(file.lastModified).format("lll")}</td>
                <td className="flex justify-center py-3 px-3">
                  {context.isEmbeddedMain ? (
                    context.mainIndex === key ? (
                      <div className="text-xs font-semibold px-3 py-1 bg-Orange text-white rounded-md">
                        Main File
                      </div>
                    ) : (
                      <button
                        className="text-xs font-semibold px-3 py-1 border rounded-md hover:bg-gray"
                        onClick={() => {
                          context.setMainIndex(key);
                          context.setIsEmbeddedMain(false);
                        }}
                      >
                        Set as Main File
                      </button>
                    )
                  ) : (
                    <button
                      className="text-xs font-semibold px-3 py-1 border rounded-md hover:bg-gray"
                      onClick={() => {
                        context.setMainIndex(key);
                        context.setIsEmbeddedMain(false);
                      }}
                    >
                      Set as Main File
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
        </DataTable>
      )} */}

      {/* {context.mergedFiles.length > 0 && (
        <div className="p-6 text-sm rounded-3xl bg-white shadow-xl flex flex-col text-gray-600">
          {context.embed !== "" && (
            <div>
              <div className="font-bold text-lg px-3">Embed</div>
              <div className="py-2 px-5">
                <div className="flex gap-x-4 items-center">
                  <div className="w-full h-96">
                    {ReactHtmlParser(context.embed)}
                  </div>
                  <div className="ml-auto flex-shrink-0">
                    {context.isEmbeddedMain ? (
                      <div className="text-xs font-semibold px-3 py-1 bg-Orange text-white rounded-md">
                        Main File
                      </div>
                    ) : (
                      <button
                        className="gap-x-2 ml-auto flex text-xs font-semibold text-gray-500 hover:bg-Orange hover:text-white transition px-3 py-1 rounded-md"
                        onClick={() => {
                          context.setIsEmbeddedMain(true);
                        }}
                      >
                        Set as Main File
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="my-5 border-t border-gray "></div>
            </div>
          )}

          <div className="font-bold text-lg px-3">Files</div>
          {context.mergedFiles.map((file, key) => {
            return (
              <div
                key={key}
                className="flex items-center pl-4 w-full py-2 px-5 hover:bg-gray-100 rounded-md"
              >
                <div className=" flex gap-x-4">
                  <div className="font-bold">{key + 1}.</div>
                  <div>{file.fileName}</div>
                </div>
                <div className="ml-auto">
                  {context.isEmbeddedMain ? (
                    <button
                      className="gap-x-2 ml-auto flex text-xs font-semibold text-gray-500 hover:bg-Orange hover:text-white transition px-3 py-1 rounded-md"
                      onClick={() => {
                        context.setIsEmbeddedMain(false);
                        context.setMainIndex(key);
                      }}
                    >
                      Set as Main File
                    </button>
                  ) : context.mainIndex !== key ? (
                    <button
                      className="gap-x-2 ml-auto flex text-xs font-semibold text-gray-500 hover:bg-Orange hover:text-white transition px-3 py-1 rounded-md"
                      onClick={() => {
                        context.setMainIndex(key);
                      }}
                    >
                      Set as Main File
                    </button>
                  ) : (
                    <div className="text-xs font-semibold px-3 py-1 bg-Orange text-white rounded-md">
                      Main File
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )} */}

      <div className="p-6 text-sm rounded-3xl bg-white shadow-xl flex flex-col text-gray-600">
        {context.embed !== "" && (
          <div>
            <div className="font-bold text-lg px-3">Embed</div>
            <div className="py-2 px-5">
              <div className="flex gap-x-4 items-center">
                <div className="w-full h-96">
                  {ReactHtmlParser(context.embed)}
                </div>
                <div className="ml-auto flex-shrink-0">
                  {context.isEmbeddedMain ? (
                    <div className="text-xs font-semibold px-3 py-1 bg-Orange text-white rounded-md">
                      Main File
                    </div>
                  ) : (
                    <button
                      className="gap-x-2 ml-auto flex text-xs font-semibold text-gray-500 hover:bg-Orange hover:text-white transition px-3 py-1 rounded-md"
                      onClick={() => {
                        context.setIsEmbeddedMain(true);
                      }}
                    >
                      Set as Main File
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="my-5 border-t border-gray "></div>
          </div>
        )}
        {context.mergedFiles.length > 0 && (
          <div>
            <div className="font-bold text-lg px-3">Files</div>
            {context.mergedFiles.map((file, key) => {
              return (
                <div
                  key={key}
                  className="flex items-center pl-4 w-full py-2 px-5 hover:bg-gray-100 rounded-md"
                >
                  <div className=" flex gap-x-4">
                    <div className="font-bold">{key + 1}.</div>
                    <div>{file.fileName}</div>
                  </div>
                  <div className="ml-auto">
                    {context.isEmbeddedMain ? (
                      <button
                        className="gap-x-2 ml-auto flex text-xs font-semibold text-gray-500 hover:bg-Orange hover:text-white transition px-3 py-1 rounded-md"
                        onClick={() => {
                          context.setIsEmbeddedMain(false);
                          context.setMainIndex(key);
                        }}
                      >
                        Set as Main File
                      </button>
                    ) : context.mainIndex !== key ? (
                      <button
                        className="gap-x-2 ml-auto flex text-xs font-semibold text-gray-500 hover:bg-Orange hover:text-white transition px-3 py-1 rounded-md"
                        onClick={() => {
                          context.setMainIndex(key);
                        }}
                      >
                        Set as Main File
                      </button>
                    ) : (
                      <div className="text-xs font-semibold px-3 py-1 bg-Orange text-white rounded-md">
                        Main File
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {context.files.length > 0 && location.pathname === "/create-content" && (
          <div>
            <div className="font-bold text-lg px-3">Files</div>
            {context.files.map((file, key) => {
              return (
                <div
                  key={key}
                  className="flex items-center pl-4 w-full py-2 px-5 hover:bg-gray-100 rounded-md"
                >
                  <div className=" flex gap-x-4">
                    <div className="font-bold">{key + 1}.</div>
                    <div>{file.name}</div>
                  </div>
                  <div className="ml-auto">
                    {context.isEmbeddedMain ? (
                      <button
                        className="gap-x-2 ml-auto flex text-xs font-semibold text-gray-500 hover:bg-Orange hover:text-white transition px-3 py-1 rounded-md"
                        onClick={() => {
                          context.setIsEmbeddedMain(false);
                          context.setMainIndex(key);
                        }}
                      >
                        Set as Main File
                      </button>
                    ) : context.mainIndex !== key ? (
                      <button
                        className="gap-x-2 ml-auto flex text-xs font-semibold text-gray-500 hover:bg-Orange hover:text-white transition px-3 py-1 rounded-md"
                        onClick={() => {
                          context.setMainIndex(key);
                        }}
                      >
                        Set as Main File
                      </button>
                    ) : (
                      <div className="text-xs font-semibold px-3 py-1 bg-Orange text-white rounded-md">
                        Main File
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Summary;
