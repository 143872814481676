import React from "react";

const SidebarRadio = ({
  text,
  name,
  onChange,
  checked,
  value,
  disabled,
  className,
  children,
}) => {
  return (
    <label
      className={`flex items-center space-x-2 hover:bg-orange rounded-md ${className}`}
    >
      <input
        type="radio"
        value={value}
        name={name}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        className="hidden"
      />
      {children}
      <span>{text}</span>
    </label>
  );
};

export default SidebarRadio;
