import React from "react";
import { EmojiSadIcon } from "@heroicons/react/outline";

const ContentError = () => {
  return (
    <div className="flex flex-col items-center h-48 justify-center text-2xl font-bold text-gray-400 ">
      <span>Error Loading Data</span>
      <EmojiSadIcon className="h-10 w-10" />
    </div>
  );
};

export default ContentError;
