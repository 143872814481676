import React, { useState, useEffect, useContext } from "react";
import { getContent } from "../services/contents";
import { useQuery } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import { FlatButton } from "../components/Buttons/Buttons";
import { ArrowNarrowLeftIcon, ChevronLeftIcon } from "@heroicons/react/solid";
import EpubReader from "../components/Contents/EpubReader";
import DocsReader from "../components/Contents/DocsReader";
import RatingToStar from "../components/Others/RatingToStar";
import moment from "moment";
import ContentReview from "../components/Contents/ContentReview";
import ReportModal from "../components/Others/ReportModal";
import ReviewModal from "../components/Others/ReviewModal";
import Loading from "../components/Others/Loading";
import { useQueryClient } from "react-query";
import { AuthContext } from "../context/AuthContext";
import ReactHtmlParser from "react-html-parser";
import { DownloadIcon, ChatAlt2Icon } from "@heroicons/react/outline";

const ContentViewEmbed = () => {
  let { contentId } = useParams();
  const [writeReviewModal, setWriteReviewModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState("");
  const queryClient = useQueryClient();
  const authContext = useContext(AuthContext);
  const history = useHistory();
  const [fileExtension, setFileExtension] = useState("");

  const { data, isLoading, isError } = useQuery(
    ["content", contentId],
    getContent,
    { staleTime: Infinity }
  );

  useEffect(() => {
    if (data) {
      const iframes = document.querySelectorAll("iframe");
      iframes.forEach((iframe) => {
        iframe.width = "100%";
      });
    }
  }, [data]);

  if (isLoading) {
    return <div>Loading</div>;
  }

  if (isError) {
    return <div>Error</div>;
  }

  return (
    <div className="h-screen w-screen relative overflow-auto overflow-x-hidden space-y-4">
      <ReportModal
        isOpen={reportModal}
        onClose={() => {
          setReportModal(false);
        }}
        setLoading={setLoading}
      />
      <ReviewModal
        isOpen={writeReviewModal}
        onClose={() => {
          setWriteReviewModal(false);
        }}
        contentId={data.contentId}
        setLoading={setLoading}
      />
      {loading && <Loading loading={true} />}
      <div>
        <FlatButton
          className="absolute top-5 left-5 z-40 transition opacity-50 hover:opacity-100 text-white hover:bg-opacity-20"
          onClick={() => {
            history.push(`/content-view/${data.contentId}`);
          }}
        >
          <ArrowNarrowLeftIcon className="h-5 w-5 mr-2" />
          Back to Content
        </FlatButton>
        <div className="w-full">{ReactHtmlParser(data.embededUrl)}</div>
      </div>
      <div className="flex justify-center w-full p-10 sm:px-5 px-5">
        <div className="md:w-8/12 lg:10/12 w-full md:w-10/12 xl:w-6/12 flex flex-col space-y-2 pb-20 ">
          <div className="text-4xl font-bold text-Orange flex items-start">
            {data.name}
            {authContext.isAuthenticated() && (
              <div className="ml-auto sm:flex hidden items-center space-x-2">
                <FlatButton
                  className="btn-Gray"
                  onClick={() => {
                    setReportModal(true);
                  }}
                >
                  Report Content
                </FlatButton>
                <FlatButton
                  className="btn-Orange-primary"
                  onClick={() => {
                    setWriteReviewModal(true);
                  }}
                >
                  Write a Review
                </FlatButton>
              </div>
            )}
          </div>
          <span className="text-gray-500 font-semibold">
            {data.description}
          </span>
          <div className="flex flex-col gap-y-1 items-start text-sm font-semibold text-gray-500">
            <div className="flex items-center">
              <RatingToStar rating={data.ratings} size={5} />
              <span className="ml-2">{data.ratings || 0} out of 5</span>
            </div>
            <div className="flex items-center gap-x-2">
              {data.contentReviews.length || 0} Reviews
              <ChatAlt2Icon className="h-4 w-4" />
            </div>
            <div className="flex items-center gap-x-2">
              {data.numberOfDownloads} Downloads
              <DownloadIcon className="h-4 w-4" />
            </div>
          </div>
          <div className="text-gray-500 text-sm flex flex-col">
            <span className="font-semibold">{data.submittedBy}</span>
            <span className="text-gray-400 text-xs">
              {moment(data.uploadedDate).format("lll")}
            </span>
          </div>
          {authContext.isAuthenticated() && (
            <div className="w-full sm:hidden flex items-center space-x-2">
              <FlatButton
                className="btn-Gray w-full justify-center"
                onClick={() => {
                  setReportModal(true);
                }}
              >
                Report Content
              </FlatButton>
              <FlatButton
                className="btn-Orange-primary w-full justify-center"
                onClick={() => {
                  setWriteReviewModal(true);
                }}
              >
                Write a Review
              </FlatButton>
            </div>
          )}
          <div className="text-Orange font-bold text-lg">Reviews</div>
          {data.contentReviews.length > 0 ? (
            <div className="space-y-2">
              {data.contentReviews.map((review, key) => {
                return <ContentReview key={key} review={review} />;
              })}
            </div>
          ) : (
            <div className="flex justify-center w-full py-10 text-2xl font-bold text-gray-400">
              This content has no reviews yet.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContentViewEmbed;
