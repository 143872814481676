import React, { useContext } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  ChatAlt2Icon,
  ChatAltIcon,
  HomeIcon,
  MenuIcon,
  XIcon,
} from "@heroicons/react/solid";
import { Link, useHistory } from "react-router-dom";
import NavbarUserMenu from "../Navbar/NavbarUserMenu";
import NavbarSearchBar from "../Navbar/NavbarSearchBar";
import NavbarMobileMenu from "../Navbar/NavbarMobileMenu";
import Notification from "../Navbar/Notification";
import { useLocation } from "react-router";
import { AuthContext } from "../../context/AuthContext";
import MessageNotif from "../Navbar/MessageNotif";
import CSILogo from "../../assets/CSI-Logo-02.png";
import Tooltip from "react-simple-tooltip";
const Navbar = () => {
  const location = useLocation();
  const history = useHistory();
  const authContext = useContext(AuthContext);

  if (
    location.pathname !== "/signin" &&
    location.pathname !== "/register" &&
    location.pathname !== "/forgot-password" &&
    location.pathname !== "/reset-password"
  ) {
    return (
      <div>
        <Disclosure
          as="nav"
          className="bg-white bg-opacity-60 relative z-50 backdrop-filter backdrop-blur-lg w-full shadow-lg"
        >
          {({ open }) => (
            <>
              <div className="px-0 sm:px-6 lg:px-8">
                <div className="flex items-center h-16 ">
                  <div className="flex sm:items-center justify-center space-x-4 w-full z-10 absolute sm:w-auto">
                    <div className="h-10 w-10">
                      <img
                        src={CSILogo}
                        className="w-full object-fit"
                        onClick={() => {
                          history.push("/");
                        }}
                      />
                    </div>
                    <div className="hidden sm:block">
                      <Link
                        to="/"
                        className="font-bold text-xl text-dark-orange "
                      >
                        Catholic Safeguarding Institute
                        <span className="flex subpixel-antialiased font-semibold text-base italic text-orange">
                          Alumni Online Library
                        </span>
                      </Link>
                    </div>
                  </div>

                  <div className="w-full absolute z-0">
                    <NavbarSearchBar />
                  </div>
                  <div className="sm:flex items-center h-full hidden sm:ml-auto">
                    {authContext.isAuthenticated() ? (
                      <div className="flex items-center space-x-4">
                        <MessageNotif />

                        <Tooltip
                          content="Home"
                          placement="bottom"
                          background="#F7941E"
                          border="#F7941E"
                          radius="10"
                        >
                          <Link
                            to="/"
                            className="h-10 w-10 text-dark-orange hover:bg-orange hover:bg-opacity-20 flex justify-center items-center rounded-full"
                          >
                            <HomeIcon className="h-6 w-6" />
                          </Link>
                        </Tooltip>
                      </div>
                    ) : (
                      ""
                    )}
                    <Notification />
                    <div className="w-1 h-10 border-r border-gray-300 mr-5" />
                    <NavbarUserMenu />
                  </div>

                  <div className="mr-2 flex sm:hidden ml-auto">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center relative z-10 justify-center p-2 rounded-md text-orange hover:bg-orange hover:bg-opacity-30 focus:outline-none">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>
              <NavbarMobileMenu />
            </>
          )}
        </Disclosure>
      </div>
    );
  }
  return <div></div>;
};

export default Navbar;
