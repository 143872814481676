import { ChatAlt2Icon, ChatAltIcon } from "@heroicons/react/solid";
import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { PresenceContext } from "../../context/PresenceContext";
import Tooltip from "react-simple-tooltip";
const MessageNotif = () => {
  const presenceContext = useContext(PresenceContext);
  const location = useLocation();
  const [lastLocation, setLastLocation] = useState("");

  useEffect(() => {
    if (location.pathname) {
      if (lastLocation.includes("/messages")) {
        presenceContext.setMsgNotif(false);
      }
      setLastLocation(location.pathname);
    }
  }, [location]);

  return (
    <Tooltip
      content="Messages"
      placement="bottom"
      background="#F7941E"
      border="#F7941E"
      radius="10"
    >
      <Link
        to="/messages"
        className="h-10 w-10 text-dark-orange hover:bg-orange hover:bg-opacity-20 flex justify-center items-center rounded-full relative"
        onClick={() => {
          presenceContext.setMsgNotif(false);
        }}
      >
        {presenceContext.msgNotif &&
          !location.pathname.includes("/messages") && (
            <span className="animate-ping absolute h-full w-full bg-white rounded-full opacity-75"></span>
          )}
        <ChatAlt2Icon className={`h-6 w-6 `} />
        {presenceContext.msgNotif && !location.pathname.includes("/messages") && (
          <div className=" text-center absolute z-20 top-full mt-1 bg-white -right-1/2 w-48 rounded-xl shadow-xl p-4 text-orange font-semibold text-sm flex flex-col items-center justify-center ring-2 ring-LightOrange">
            <div>{presenceContext.msgNotifMsg}</div>
            <ChatAltIcon className="h-5 w-5 mt-2" />
          </div>
        )}
      </Link>
    </Tooltip>
  );
};

export default MessageNotif;
