import React from "react";
import { useHistory } from "react-router-dom";

const Card = ({ name, count, children, onClick, component: Component }) => {
  return (
    <button
      onClick={onClick}
      className="group h-52 flex w-full relative items-center focus:ring-4 focus:ring-LightOrange cursor-pointer bg-white transition overflow-hidden text-gray-600 px-5 py-4 rounded-2xl hover:shadow-xl "
    >
      <div className="flex flex-col w-full justify-center ">
        <div className="text-7xl font-bold text-blue">{count}</div>
        <div className="flex text-blue w-full gap-x-5 font-bold pt-3 items-end">
          <div className="flex-1 w-full rounded-lg py-2 bg-light-blue">
            {name}
          </div>
          <Component className="text-orange h-20 w-20" />
        </div>
      </div>
    </button>
  );
};

const AdminCards = ({ items = [] }) => {
  const history = useHistory();

  return (
    <div className="grid xl:grid-cols-3 grid-cols-1 gap-x-7 gap-y-7">
      {items.map((item, key) => {
        return (
          <Card
            key={key}
            name={item.name}
            count={item.count}
            onClick={() => {
              history.push(item.route);
            }}
            component={item.component}
          />
        );
      })}
      {/* <Card
        name="New Contents"
        count={allContents}
        onClick={() => {
          history.push("/contents");
        }}
        component={ClipboardListIcon}
      ></Card>
      <Card
        name="Registered User/s"
        count={totalUsers}
        onClick={() => {
          history.push("/users");
        }}
        component={UserGroupIcon}
      ></Card>

      <Card
        name="Reported Contents"
        count={reportedContents}
        onClick={() => {
          history.push("/contents");
        }}
        component={ExclamationIcon}
      ></Card>
      <Card
        name="Pending Content Request/s"
        count={allPending}
        onClick={() => {
          history.push("/contents");
        }}
        component={ClockIcon}
      ></Card> */}
    </div>
  );
};

export default AdminCards;
