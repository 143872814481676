import React, { createContext, useState, useEffect } from "react";
import { useHistory } from "react-router";
import { myFavorites } from "../services/contents";
import { useQuery } from "react-query";
import { myProfile } from "../services/users";

const AuthContext = createContext();
const { Provider } = AuthContext;

// const getFavorites = () => {
//   const { data } = useQuery("favArray", myFavorites);

//   useEffect(() => {
//     if (data) {
//       return data;
//     }
//   }, [data]);
// };

const AuthProvider = ({ children }) => {
  const history = useHistory();
  const token = localStorage.getItem("token");
  const userInfo = localStorage.getItem("userInfo");
  const [authState, setAuthState] = useState({
    token,
    userInfo: userInfo ? JSON.parse(userInfo) : {},
  });
  const [favorites, setFavorites] = useState([]);
  const [profile, setProfile] = useState({});

  const isAuthenticated = () => {
    if (!authState.token) return false;
    else return true;
  };

  var favArray = useQuery("favArray", myFavorites, {
    enabled: isAuthenticated(),
  }).data;
  var userProfile = useQuery("userProfile", myProfile, {
    enabled: isAuthenticated(),
  }).data;

  useEffect(() => {
    if (isAuthenticated()) if (favArray) setFavorites(favArray);
  }, [favArray, isAuthenticated()]);

  useEffect(() => {
    if (isAuthenticated()) if (userProfile) setProfile(userProfile);
  }, [userProfile, , isAuthenticated()]);

  const setAuthInfo = ({
    token,
    profilePicture,
    role,
    status,
    username,
    userId,
  }) => {
    const userInfo = {
      userId,
      profilePicture,
      role,
      status,
      username,
    };

    localStorage.setItem("token", token);
    localStorage.setItem("userInfo", JSON.stringify(userInfo));

    setAuthState({
      token,
      userInfo,
    });
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
    setAuthState({
      token: null,
      userInfo: {},
    });
    history.push("/signin");
    window.location.reload(false);
  };

  const isAdmin = () => {
    if (authState.userInfo.role === "Admin") return true;
    else return false;
  };

  const isMember = () => {
    if (authState.userInfo.role === "Member") return true;
    else return false;
  };

  return (
    <Provider
      value={{
        profile,
        authState,
        favorites,
        setAuthState: (authInfo) => setAuthInfo(authInfo),
        isAuthenticated,
        logout,
        isAdmin,
        isMember,
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };
