import React, { useContext } from "react";
import { Disclosure } from "@headlessui/react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

const NavbarMobileMenu = () => {
  const authContext = useContext(AuthContext);
  return (
    <Disclosure.Panel className="sm:hidden">
      <div className="pb-2">
        <div className="border-t " />
        <div className=" px-2 space-y-1 ">
          {authContext.isAuthenticated() ? (
            <div className="capitalize p-2 font-semibold text-dark-blue space-y-3">
              <div className="text-center">
                Hi, {authContext.profile.userName}
              </div>
              <div>
                <Link
                  to="/my-profile"
                  className="block px-3 py-2 rounded-full text-center text-sm font-medium border bg-blue text-white hover:bg-opacity-50 "
                >
                  My Profile
                </Link>
              </div>
              <div className="rounded-md bg-opacity-50 space-y-2">
                <Link
                  to="/my-contents"
                  className="block px-3 py-2 rounded-md text-sm font-medium text-gray-600 hover:text-blue hover:bg-light-blue "
                >
                  My Contents
                </Link>
                <Link
                  to="/create-content"
                  className="block px-3 py-2 rounded-md text-sm font-medium text-gray-600 hover:text-blue hover:bg-light-blue "
                >
                  Create Content
                </Link>
                <div className="border-t " />
                <button
                  onClick={() => {
                    authContext.logout();
                  }}
                  className="w-full px-3 py-2 rounded-md text-sm font-medium text-gray-600 hover:text-blue hover:bg-light-blue "
                >
                  Logout
                </button>
              </div>
            </div>
          ) : (
            <Link
              to="/signin"
              className="block px-3 py-2 mt-2 rounded-md text-sm font-medium text-gray-600 hover:text-blue hover:bg-light-blue "
            >
              Sign In
            </Link>
          )}
        </div>
      </div>
    </Disclosure.Panel>
  );
};

export default NavbarMobileMenu;
