import React from "react";
import Content from "../components/Admin/Users/Content";
import AdminSidebar from "../components/Base/AdminSidebar";
import { UsersProvider } from "../context/UsersContext";

const Users = () => {
  return (
    <div className="h-full ">
      <div className="flex h-full w-full">
        <AdminSidebar />
        <UsersProvider>
          <Content />
        </UsersProvider>
      </div>
    </div>
  );
};

export default Users;
