import { ClipboardListIcon } from "@heroicons/react/outline";
import React from "react";

const ContentNotFound = ({ text }) => {
  return (
    <div className="flex flex-col items-center h-48 justify-center text-2xl font-bold text-gray-400 space-y-4">
      <span>{text || `Content Not Found`}</span>
      <ClipboardListIcon className="h-10 w-10" />
    </div>
  );
};

export default ContentNotFound;
