import ForgotPassword from "../pages/ForgotPassword";
import PageNotFound from "../pages/PageNotFound";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import Trending from "../pages/Trending";
import ContentView from "../pages/ContentView";
import ContentRead from "../pages/ContentRead";
import ContentWatch from "../pages/ContentWatch";
import Dashboard from "../pages/Dashboard";
import Categories from "../pages/Categories";
import Users from "../pages/Users";
import UserView from "../pages/UserView";
import Contents from "../pages/Contents";
import ContentViewAdmin from "../pages/ContentViewAdmin";
import MyProfile from "../pages/MyProfile";
import MyContents from "../pages/MyContents";
import ReportedIssues from "../pages/ReportedIssues";
import CreateContent from "../pages/CreateContent";
import ViewAllContents from "../components/Admin/Contents/ViewAllContents";
import ReportedContentView from "../pages/ReportedContentView";
import ResetPassword from "../pages/ResetPassword";
import MyContentManage from "../pages/MyContentManage";
import Messages from "../pages/Messages";
import Logs from "../pages/Logs";
import ContentViewEmbed from "../pages/ContentViewEmbed";

const routes = [
  { path: "/", component: Dashboard },
  { path: "/register", component: SignUp },
  { path: "/signin", component: SignIn },
  { path: "/trending", component: Trending },
  { path: "/content-view/:contentId", component: ContentView },
  { path: "/content-view-embed/:contentId", component: ContentViewEmbed },
  { path: "/content-read/:contentId", component: ContentRead },
  { path: "/content-watch/:contentId", component: ContentWatch },
  { path: "/forgot-password", component: ForgotPassword },
  { path: "/reset-password", component: ResetPassword },
  { path: "*", component: PageNotFound },
];

const authRoutes = [
  { path: "/my-profile", component: MyProfile },
  { path: "/my-contents/:contentId/manage", component: MyContentManage },
  { path: "/my-contents", component: MyContents },
  { path: "/create-content", component: CreateContent },
  { path: "/messages", component: Messages },
];

const adminRoutes = [
  { path: "/user-view/:userId", component: UserView },
  { path: "/reported-issues", component: ReportedIssues },
  { path: "/categories", component: Categories },
  { path: "/users", component: Users },
  { path: "/logs", component: Logs },
  { path: "/contents", component: Contents },
  { path: "/content-view/:contentId/admin", component: ContentViewAdmin },
  { path: "/all-contents", component: ViewAllContents },
  { path: "/reported-view/:reportId", component: ReportedContentView },
];

export { routes, adminRoutes, authRoutes };
