import React, { useContext } from "react";
import Filters from "./Filters";
import Table from "./Table";
import Pagination from "../../Others/Pagination";
import { ReportsContext } from "../../../context/ReportsContext";

const Content = () => {
  const context = useContext(ReportsContext);
  return (
    <div className="z-0 lg:p-10 mb-20 p-4 w-full overflow-auto space-y-7">
      <div className="flex items-center w-full text-2xl font-bold text-gray-600 mb-5 space-x-4">
        <span>Reported Issues</span>
      </div>
      <Filters />
      <div className="space-y-4">
        <Table />
        <Pagination context={context} className="float-right" />
      </div>
    </div>
  );
};

export default Content;
