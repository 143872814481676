import React from "react";
import Select from "react-select";

const ReactSelect = ({
  getOptionLabel,
  getOptionValue,
  isMulti = true,
  options,
  value,
  onChange,
  label,
  defaultValue,
  required = false,
}) => {
  return (
    <div className="text-sm font-semibold text-gray-500 space-y-2">
      {label && (
        <label
          htmlFor={label}
          className="flex text-sm font-semibold text-gray-500 gap-x-1"
        >
          {label}
          {required && <span className="text-red-500">*</span>}
        </label>
      )}
      <Select
        options={options}
        value={value}
        defaultValue={defaultValue}
        isMulti={isMulti}
        isSearchable
        getOptionLabel={getOptionLabel} //(option) => `${option.categoryName}`
        getOptionValue={getOptionValue} //(option) => `${option.categoryId}`
        styles={{
          control: (styles) => ({
            ...styles,
            backgroundColor: "#F9FAFB",
            boxShadow: "inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);",
            border: "none",
            fontSize: "0.875rem",
            fontWeight: "normal",
            borderRadius: "0.375rem",
          }),
          option: (styles) => ({
            ...styles,
            fontWeight: "normal",
            fontSize: "0.875rem",
          }),
        }}
        onChange={onChange}
      />
    </div>
  );
};

export default ReactSelect;
