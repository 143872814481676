import React from "react";
import { useQuery } from "react-query";
import { getTrending } from "../services/contents";
import Loading from "../components/Others/Loading";

const Trending = () => {
  const { data, error, isLoading, isError } = useQuery("trending", getTrending);
  return (
    <div>
      {isLoading ? (
        <Loading loading={isLoading} />
      ) : (
        <div>
          {data.map((item) => {
            return <div>{item.name}</div>;
          })}
        </div>
      )}
    </div>
  );
};

export default Trending;
