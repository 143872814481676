import React from "react";
import { getNewReleases, myFavDashboard } from "../../../services/contents";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import Carousel from "../../Others/Carousel";
import ContentSkeleton from "../../Others/ContentSkeleton";
import ContentError from "../../Others/ContentError";

const MyFavorites = () => {
  const { data, isLoading, isError } = useQuery(
    "myFavDashboard",
    myFavDashboard
  );

  if (isError) {
    return <ContentError />;
  }

  if (isLoading) {
    return <ContentSkeleton />;
  }

  if (data) {
    return <Carousel data={data.slice(0, 8)} name="MyFavorites" />;
  }
};

export default MyFavorites;
