import React from "react";

const ContentSkeleton = () => {
  return (
    <div className="flex grid  xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-1 2xl:grid-cols-4 gap-y-7 gap-x-4 my-4 sm:mx-0 mx-10 ">
      {[...Array(4)].map((skeleton, key) => {
        return (
          <div className="rounded-md max-w-sm w-full mx-auto" key={key}>
            <div className="animate-pulse">
              <div className="rounded-3xl bg-gray-300 h-64 w-full w-12"></div>
              <div className="flex-1 space-y-4 py-4 px-5">
                <div className="space-y-2">
                  <div className="h-4 bg-gray-300 rounded"></div>
                  <div className="h-4 bg-gray-300 rounded w-5/6"></div>
                </div>
              </div>
              <div className="h-14 w-full rounded-xl bg-gray-300"></div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ContentSkeleton;
