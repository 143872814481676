import React from "react";
import { StarIcon } from "@heroicons/react/solid";

const RatingToStar = ({ rating, size }) => {
  var stars = [];
  var deadstars = 5 - Math.floor(rating);
  for (var x = 1; x <= rating; x++) {
    stars.push(
      <StarIcon
        key={rating + "." + x + ".yellow"}
        className={`w-${size} h-${size} text-yellow-400`}
      />
    );
  }

  if (deadstars > 0) {
    for (var y = 1; y <= deadstars; y++) {
      stars.push(
        <StarIcon
          key={rating + "." + y + ".gray"}
          className={`w-${size} h-${size} text-gray-300`}
        />
      );
    }
  }
  return stars;
};

export default RatingToStar;
