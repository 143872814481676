import React, { useContext, useEffect, useState } from "react";
import { FlatButton } from "../components/Buttons/Buttons";
import Select from "../components/Inputs/Select";
import {
  MyContentsContext,
  MyContentsProvider,
} from "../context/MyContentsContext";
import Pagination from "../components/Others/Pagination";
import { useHistory } from "react-router";
import { myContent } from "../services/contents";
import { useQuery } from "react-query";
import { AuthContext } from "../context/AuthContext";
import moment from "moment";
import RatingToStar from "../components/Others/RatingToStar";
import FastAverageColor from "fast-average-color";
import { BanIcon, CheckIcon, ClockIcon } from "@heroicons/react/outline";
import { EditContentContext } from "../context/EditContentContext";
import { useQueryClient } from "react-query";
import Textbox from "../components/Inputs/Textbox";
import ContentError from "../components/Others/ContentError";
import Modal from "../components/Others/Modal";
import * as NoImagePlaceHolder from "../assets/no-image.png";
import ReactSelect from "../components/Inputs/ReactSelect";
import { categoryOptions } from "../services/categories";
import ImageNotFound from "../assets/ImageNotFound.jpg";

var env = process.env.NODE_ENV || "development";
const server = `${
  env === "development"
    ? process.env.REACT_APP_DEV_SERVER
    : process.env.REACT_APP_PROD_SERVER
}`;

const Filters = () => {
  const history = useHistory();
  const context = useContext(MyContentsContext);
  const { data } = useQuery("categoryOptionFilters", categoryOptions);

  return (
    <div className="w-full hidden sm:block space-y-4 px-5">
      <div className="justify-center px-10 rounded-3xl space-y-2 bg-white py-6 shadow-xl w-full ">
        <div className="text-Orange text-xl font-bold ">Filters</div>
        <div className="grid sm:grid-cols-4 gap-y-5 items-end gap-x-5">
          <div className="flex-1">
            <Textbox
              label="Title"
              placeholder="Search Title"
              value={context.titleSearch}
              onChange={(e) => {
                context.setTitleSearch(e.target.value);
              }}
            />
          </div>
          <div className="flex-1">
            <Textbox
              label="Author"
              placeholder="Search Author"
              value={context.author}
              onChange={(e) => {
                context.setAuthor(e.target.value);
              }}
            />
          </div>
          <div className="flex-1">
            <Textbox
              label="Keyword"
              placeholder="Search Keyword"
              value={context.keyword}
              onChange={(e) => {
                context.setKeyword(e.target.value);
              }}
            />
          </div>

          <ReactSelect
            label="Categories"
            options={data}
            value={context.categories}
            getOptionLabel={(option) => option.categoryName}
            getOptionValue={(option) => option.categoryId}
            onChange={(e) => {
              context.setCategories(e);
            }}
          />
          <Select
            label="Sort by"
            className="flex-1"
            value={context.sortBy}
            onChange={(e) => {
              context.setSortBy(e.target.value);
            }}
          >
            <option>Latest</option>
            <option>Oldest</option>
            <option>Title (A - Z)</option>
            <option>Title (Z - A)</option>
          </Select>
          <Select
            label="Status"
            className="flex-1"
            value={context.status}
            onChange={(e) => {
              context.setStatus(e.target.value);
            }}
          >
            <option>Any</option>
            <option>Approved</option>
            <option>Pending</option>
            <option>Declined</option>
          </Select>
          <FlatButton
            className="btn-Orange-secondary justify-center"
            onClick={() => {
              context.ResetFilters();
            }}
          >
            Reset Filters
          </FlatButton>
        </div>
      </div>
      <div className="flex items-center w-full ">
        <div className="flex items-center space-x-2 text-sm font-semibold text-gray-600">
          <span>Show</span>
          <div className="w-16">
            <Select
              value={context.entries}
              onChange={(e) => {
                context.setEntries(e.target.value);
              }}
            >
              {[...Array(6)].map((x, i) => {
                return <option key={i}>{i + 5}</option>;
              })}
            </Select>
          </div>
          <span>Entries</span>
        </div>
        <div className="ml-auto">
          <FlatButton
            className="bg-dark-orange text-white hover:bg-dark-orange hover:bg-opacity-50"
            onClick={() => {
              history.push("/create-content");
            }}
          >
            Create Content
          </FlatButton>
        </div>
      </div>
    </div>
  );
};

const StatusIndicator = ({ bg, children }) => {
  return (
    <div
      className={`rounded-full w-5 h-5 flex items-center justify-center text-white bg-${bg}-600`}
    >
      {children}
    </div>
  );
};

const StatusBadge = ({ status, color, component: Component }) => {
  return (
    <div
      className={`flex items-center gap-x-2 bg-${color}-200 p-2 rounded-full `}
    >
      <span className={`font-semibold text-sm text-${color}-600`}>
        {status}
      </span>
      <StatusIndicator bg={color}>
        <Component className="h-4 w-4" />
      </StatusIndicator>
    </div>
  );
};

const Item = ({ data }) => {
  const context = useContext(EditContentContext);
  const myContentContext = useContext(MyContentsContext);
  const {
    name,
    thumbnailUrl,
    authorName,
    contentId,
    description,
    uploadedDate,
    ratings,
    status,
    type,
    isHidden,
  } = data;

  // console.log(data);
  const [color, setColor] = useState("");
  const history = useHistory();
  const fac = new FastAverageColor();
  const queryClient = useQueryClient();
  const [hide, setHide] = useState(false);
  useEffect(() => {
    if (data.thumbnailUrl) {
      fac
        .getColorAsync(server + data.thumbnailUrl)
        .then((color) => {
          setColor(color.hex);
        })
        .catch((e) => {
          // console.log(e);
        });
    }
  }, [data.thumbnailUrl]);

  return (
    <div className="group h-full p-5 flex md:flex-row gap-y-4 flex-col w-full bg-white rounded-3xl gap-x-4 hover:shadow-xl transition ">
      <Modal
        isOpen={hide}
        onClose={() => {
          setHide(false);
        }}
        title={isHidden ? "Unhide" : "Hide" + " Content"}
        description={`Are you sure you want to ${
          isHidden ? "Unhide" : "Hide"
        } this content?`}
      >
        <div className="flex justify-end space-x-2">
          <FlatButton
            onClick={() => {
              setHide(false);
            }}
            className="btn-Gray"
          >
            Cancel
          </FlatButton>
          <FlatButton
            className="btn-Orange-primary"
            onClick={() => {
              myContentContext.HideContent({ contentId, isHidden: !isHidden });
              setHide(false);
            }}
          >
            Confirm
          </FlatButton>
        </div>
      </Modal>
      <div className="image h-full flex-1 md:rounded-l-2xl rounded-tl-2xl rounded-2xl md:rounded-tr-none flex justify-center overflow-hidden ">
        <img
          src={thumbnailUrl ? server + thumbnailUrl : ImageNotFound}
          className="h-full w-full object-cover rounded-2xl shadow-xl"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = ImageNotFound;
          }}
        />
      </div>
      <div className="w-full flex flex-1 flex-col space-y-1 overflow-ellipsis overflow-hidden ">
        <div className="flex gap-x-2">
          <div className="flex flex-col space-y-1">
            <span className="font-bold text-xl text-gray-600">{name}</span>
            <div className="flex">
              <RatingToStar rating={ratings} size={4} />{" "}
            </div>
          </div>
          <div className="ml-auto text-sm">
            {status === "Approved" && (
              <StatusBadge
                status={status}
                color="green"
                component={CheckIcon}
              />
            )}
            {status === "Pending" && (
              <StatusBadge
                status={status}
                color="yellow"
                component={ClockIcon}
              />
            )}
            {status === "Declined" && (
              <StatusBadge status={status} color="red" component={BanIcon} />
            )}
            {status === "Disabled" && (
              <StatusBadge status={status} color="gray" component={BanIcon} />
            )}
          </div>
        </div>
        <div className="text-xs text-gray-600 flex flex-col">
          <span>
            Author: <b>{authorName}</b>
          </span>
          <span>
            Uploaded Date:{" "}
            <b>{moment.utc(uploadedDate).local().format("LL")} </b>
          </span>
          <span className="text-xs text-gray-600">Description: </span>
        </div>
        <p className="text-sm h-32 overflow-ellipsis ">{description}</p>
        <div className="md:opacity-0 gap-x-4 opacity-100 flex-auto items-end group-hover:opacity-100 transition flex justify-end ">
          <FlatButton
            className="md:rounded-full btn-Gray md:w-auto w-full md:mb-0 mb-4 justify-center"
            onClick={() => {
              setHide(true);
            }}
          >
            {isHidden ? "Unhide" : "Hide"} Content
          </FlatButton>
          <FlatButton
            className="md:rounded-full btn-Orange-primary md:w-auto w-full md:mb-0 mb-4 justify-center"
            onClick={() => {
              history.push(`/my-contents/${contentId}/manage`);
            }}
          >
            Manage Content
          </FlatButton>
        </div>
      </div>
    </div>
  );
};

const Content = () => {
  const authcontext = useContext(AuthContext);
  const context = useContext(MyContentsContext);

  const { data, isLoading, isError } = useQuery(
    [
      "my-content",
      {
        userId: authcontext.authState.userInfo.userId,
        filters: context.filters,
        entries: context.entries,
        page: context.page,
      },
    ],
    myContent
  );

  useEffect(() => {
    if (data) context.setPageCount(data.pages);
  }, [data]);

  if (isLoading) {
    return (
      <div className="animate-pulse flex-auto px-6 flex flex-col space-y-4  py-6">
        {[...Array(5)].map((s, key) => {
          return (
            <div key={key} className="flex gap-x-4">
              <div className="w-6/12 h-52 space-y-4 flex flex-col bg-gray-300 rounded-3xl"></div>
              <div className="w-full h-52 space-y-4 justify-center flex flex-col rounded-3xl">
                <div className="h-10 w-3/12 bg-gray-300 rounded-xl"></div>
                <div className="h-5 w-5/12 bg-gray-300 rounded-xl"></div>
                <div className="h-20 w-full bg-gray-300 rounded-xl"></div>
                <div className="h-10 w-6/12 bg-gray-300 rounded-xl"></div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  if (isError) {
    return (
      <div>
        <ContentError />
      </div>
    );
  }

  return (
    <div className="flex-auto px-6 flex flex-col space-y-4 ">
      {data.data.length > 0 ? (
        data.data.map((item, i) => {
          return <Item data={item} key={i} />;
        })
      ) : (
        <div className="w-full flex-auto flex items-center justify-center text-gray-400 font-bold text-xl">
          No Contents to Show.
        </div>
      )}
    </div>
  );
};

const Paginate = () => {
  const context = useContext(MyContentsContext);
  return (
    <div className="px-4">
      <Pagination context={context} />
    </div>
  );
};

const MyContents = () => {
  return (
    <div className="h-full ">
      <div className="flex h-full w-full">
        <MyContentsProvider>
          <div
            className="w-full flex justify-center py-10 overflow-auto"
            style={{ height: "91%" }}
          >
            {/* <div className="space-y-5 w-full border flex-auto flex justify-center ">
        <div className="border h w-8/12 space-y-5"> */}

            <div className="lg:w-8/12 w-full space-y-4 flex flex-col  pb-16">
              <div className="w-full px-5 text-2xl font-bold text-gray-600 uppercase text-dark-blue">
                <span>My Contents</span>
              </div>
              <Filters />
              <Content />
              <Paginate />
            </div>
          </div>
        </MyContentsProvider>
      </div>
    </div>
  );
};

export default MyContents;
