import React, { useState, Fragment, useContext } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, SearchIcon } from "@heroicons/react/outline";
import MenuItemButton from "../Buttons/MenuItemButton";
import { FilterContext } from "../../context/FilterContext";
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

const NavbarSearchBar = () => {
  const history = useHistory();
  const location = useLocation();
  const filterContext = useContext(FilterContext);
  const authContext = useContext(AuthContext);
  const SearchContent = () => {
    if (searchString !== "") {
      const data = {
        category: searchByCategory,
        string: searchString,
      };

      filterContext.setNavbarFilter(data);
      if (authContext.isAdmin()) history.push("/all-contents");
      else history.push("/");
    }
  };

  const [searchByCategory, setSearchByCategory] = useState("Search All");
  const [searchString, setSearchString] = useState("");

  const EmptySearch = (e) => {
    if (e === "") {
      const data = {
        category: "Search All",
        string: e,
      };

      filterContext.setNavbarFilter(data);
    }
  };

  if (
    location.pathname !== "/signin" &&
    location.pathname !== "/register" &&
    location.pathname !== "/forgot-password"
  ) {
    return (
      <form
        as="div"
        className="items-center w-2/5 mx-auto hidden xl:flex ring-2 ring-blue rounded-lg"
        onSubmit={(e) => {
          e.preventDefault();
          SearchContent();
        }}
      >
        <Menu as="div" className="relative z-50">
          <div>
            <Menu.Button className="px-3 pl-5 py-2 flex items-center font-medium text-white rounded-l-md bg-blue font-bold text-white text-sm ">
              <span>{searchByCategory}</span>
              <ChevronDownIcon className=" ml-2 h-4 w-4" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="z-60 absolute left-0 w-56 mt-2 origin-top-right bg-white rounded-xl shadow-lg focus:outline-none">
              <div className="p-2  space-y-2">
                <Menu.Item>
                  <MenuItemButton
                    text="Search All"
                    onClick={() => {
                      setSearchByCategory("Search All");
                    }}
                  />
                </Menu.Item>
                <Menu.Item>
                  <MenuItemButton
                    text="Search By Title"
                    onClick={() => {
                      setSearchByCategory("Search By Title");
                    }}
                  />
                </Menu.Item>
                <Menu.Item>
                  <MenuItemButton
                    text="Search By Author"
                    onClick={() => {
                      setSearchByCategory("Search By Author");
                    }}
                  />
                </Menu.Item>
                <Menu.Item>
                  <MenuItemButton
                    text="Search By Keyword"
                    onClick={() => {
                      setSearchByCategory("Search By Keyword");
                    }}
                  />
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>

        <input
          value={searchString}
          placeholder="Some CSI Content..."
          onChange={(e) => {
            setSearchString(e.target.value);
            EmptySearch(e.target.value);
          }}
          className="appearance-none block flex-auto px-3 py-2 bg-gray-100  shadow-sm placeholder-gray-400 focus:outline-none focus:ring-white focus:border-white sm:text-sm"
        ></input>
        <button className="px-3 py-2 font-semibold  rounded-r-md bg-blue text-white hover:bg-opacity-50">
          <SearchIcon className="h-5 w-5" />
        </button>
      </form>
    );
  }
  return <div className="items-center w-2/5 mx-auto hidden lg:flex"></div>;
};

export default NavbarSearchBar;
