import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { authAxios } from "../../services/interceptor";
import { FlatButton } from "../Buttons/Buttons";
import Modal from "./Modal";

const SessionExpired = () => {
  const [expired, setExpired] = useState(false);
  const authContext = useContext(AuthContext);
  const token = localStorage.getItem("token");

  useEffect(() => {
    authAxios.interceptors.response.use(
      (res) => res,
      (err) => {
        if (err.response.status === 401 && token) {
          setExpired(true);
        } else {
          throw new Error(err);
        }
      }
    );
  }, [token]);

  return (
    <Modal
      isOpen={expired}
      title="Session Expired"
      description="Please Sign in your account again to continue"
      onClose={() => {}}
    >
      <FlatButton
        className="w-full justify-center btn-Orange-primary"
        onClick={() => {
          authContext.logout();
          setExpired(false);
        }}
      >
        Go to Sign in page
      </FlatButton>
    </Modal>
  );
};

export default SessionExpired;
