import React, { useState, useEffect, useRef } from "react";
import { StarIcon } from "@heroicons/react/solid";

const StarRating = (props, { defaultChecked, onChange }) => {
  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);

  useEffect(() => {
    if (props.reset) {
      setRating(null);
    }
  }, [props.reset]);

  return (
    <div className="flex items-center">
      {[...Array(5)].map((star, i) => {
        const ratingValue = i + 1;
        return (
          <label key={i}>
            <input
              type="radio"
              name="starRating"
              className="hidden"
              // value={ratingValue}
              onChange={() => {
                setRating(ratingValue);
                props.setRating(ratingValue);
              }}
              {...(props.reset && { checked: false })}
            />
            <StarIcon
              onMouseEnter={() => setHover(ratingValue)}
              onMouseLeave={() => setHover(null)}
              className={`h-8 w-8  ${
                ratingValue <= (hover || rating)
                  ? `text-orange`
                  : `text-gray-300`
              }`}
            />
          </label>
        );
      })}
    </div>
  );
};

export default StarRating;
