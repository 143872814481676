import Navbar from "./components/Base/Navbar";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { routes, adminRoutes, authRoutes } from "./routes";
import { AuthProvider } from "./context/AuthContext";
import { FilterProvider } from "./context/FilterContext";
import { ToastProvider } from "react-toast-notifications";
import CustomToast from "./components/Alerts/CustomToast";
import { AdminRoute, AuthRoute } from "./routes/RouteGuards";
import { CreateContentProvider } from "./context/CreateContentContext";
import { EditContentProvider } from "./context/EditContentContext";

import { PresenceContext, PresenceProvider } from "./context/PresenceContext";
import { ReactQueryDevtools } from "react-query/devtools";
import SessionExpired from "./components/Others/SessionExpired";

const queryClient = new QueryClient();

const App = () => {
  const routeComponents = routes.map(({ path, component }, key) => {
    return <Route exact path={path} component={component} key={key} />;
  });

  const adminRouteComponents = adminRoutes.map(({ path, component }, key) => {
    return (
      <AdminRoute path={path} key={key} component={component}></AdminRoute>
    );
  });

  const authRouteComponents = authRoutes.map(({ path, component }, key) => {
    return <AuthRoute path={path} key={key} component={component}></AuthRoute>;
  });

  useEffect(() => {
    document.title = "Catholic Safeguarding Institute";
  }, []);

  // Render App
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AuthProvider>
          <ToastProvider
            components={{ Toast: CustomToast }}
            autoDismiss={true}
            newestOnTop={true}
          >
            <PresenceProvider>
              <CreateContentProvider>
                <EditContentProvider>
                  <FilterProvider>
                    <div className="App h-screen flex relative flex-col bg-gray-100 overflow-hidden">
                      <SessionExpired />
                      <Navbar />
                      <Switch>
                        {adminRouteComponents}
                        {authRouteComponents}
                        {routeComponents}
                      </Switch>
                    </div>
                  </FilterProvider>
                </EditContentProvider>
              </CreateContentProvider>
            </PresenceProvider>
          </ToastProvider>
        </AuthProvider>
      </Router>
    </QueryClientProvider>
  );
};

export default App;
