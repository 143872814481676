import React, { useState, useContext } from "react";
import TextArea from "../Inputs/TextArea";
import { FlatButton } from "../Buttons/Buttons";
import { useQueryClient } from "react-query";
import { useToasts } from "react-toast-notifications";
import Modal from "./Modal";
import StarRating from "./StarRating";
import { AuthContext } from "../../context/AuthContext";
import { writeReview } from "../../services/review";

const ReviewModal = ({ isOpen, onClose, setLoading, contentId }) => {
  const authContext = useContext(AuthContext);
  const [rating, setRating] = useState(null);
  const [comment, setComment] = useState("");
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  const SubmitReview = () => {
    setComment("");
    onClose();
    setLoading(true);
    const reviewData = {
      userId: authContext.authState.userInfo.userId,
      contentId,
      reviews: comment,
      rate: rating,
    };

    writeReview(reviewData)
      .then((res) => {
        queryClient.invalidateQueries("content");
        // console.log(res);
        addToast("Review Submitted", {
          appearance: "success",
        });
        setLoading(false);
      })
      .catch((e) => {
        addToast("Error Submitting Review", {
          appearance: "error",
        });
        setLoading(false);
        // console.log(e);
      });
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Write a Review"
      description="Submit your feedback by rating the content and writing comment."
    >
      <div className="space-y-2 mb-5">
        <div className="">
          <label className="text-sm font-semibold text-gray-500">Rating</label>
          <StarRating setRating={setRating} />
        </div>

        <TextArea
          label="Comment"
          value={comment}
          onChange={(e) => {
            setComment(e.target.value);
          }}
          placeholder="Write Something ..."
        />
      </div>
      <div className="float-right flex gap-x-2">
        <FlatButton
          className="btn-Gray"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </FlatButton>
        <FlatButton
          className="btn-Orange-primary"
          onClick={() => {
            SubmitReview();
          }}
        >
          Submit Review
        </FlatButton>
      </div>
    </Modal>
  );
};

export default ReviewModal;
