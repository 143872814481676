import React from "react";
import AdminSidebar from "../components/Base/AdminSidebar";
import { CategoriesProvider } from "../context/CategoriesContext";
import { ToastProvider } from "react-toast-notifications";
import CustomToast from "../components/Alerts/CustomToast";
import { LogsProvider } from "../context/LogsContext";
import Content from "../components/Admin/Logs/Content";

const Logs = () => {
  return (
    <div className="h-full ">
      <div className="flex h-full w-full">
        <AdminSidebar />
        <LogsProvider>
          <Content />
        </LogsProvider>
      </div>
    </div>
  );
};

export default Logs;
