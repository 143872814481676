import React, { useContext, useEffect, useState } from "react";
import { ContentsContext } from "../../../../context/ContentsContext";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { getReportedContents } from "../../../../services/admin";
import TableLoading from "./TableLoading";
import ContentError from "../../../Others/ContentError";
import DataTable from "../../../Base/DataTable";
import moment from "moment";
import { FlatButton } from "../../../Buttons/Buttons";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  EyeIcon,
} from "@heroicons/react/outline";

const ReportedContents = () => {
  const context = useContext(ContentsContext);
  const filters = context.filters;
  const entries = context.entries;
  const page = context.page;
  const sortBy = context.sortBy;
  const history = useHistory();
  const { data, isError, isLoading } = useQuery(
    ["reports", { filters, entries, page, sortBy }],
    getReportedContents
  );
  const [width, setWidth] = useState(window.innerWidth);
  const [headers, setHeaders] = useState([]);

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  useEffect(() => {
    if (context.table === "ReportedContents" && data) {
      context.setPageCount(data.pages);
    }
  }, [context.table, data]);

  useEffect(() => {
    if (width > 768) {
      setHeaders([
        {
          text: "Content Name",
          className: "px-6 py-4 text-left cursor-pointer",
          onClick: () => SortBy("name"),
          component:
            context.sortBy === "nameAsc"
              ? ChevronUpIcon
              : context.sortBy === "nameDesc"
              ? ChevronDownIcon
              : "",
        },
        {
          text: "Author",
          className: "text-left cursor-pointer",
          onClick: () => SortBy("author"),
          component:
            context.sortBy === "authorAsc"
              ? ChevronUpIcon
              : context.sortBy === "authorDesc"
              ? ChevronDownIcon
              : "",
        },
        {
          text: "Reporter",
          className: "text-left cursor-pointer",
          onClick: () => SortBy("reporter"),
          component:
            context.sortBy === "reporterAsc"
              ? ChevronUpIcon
              : context.sortBy === "reporterDesc"
              ? ChevronDownIcon
              : "",
        },
        { text: "Remarks", className: "text-left" },
        {
          text: "Status",
          textAlign: "center",
          className: "cursor-pointer",
          onClick: () => SortBy("status"),
          component:
            context.sortBy === "statusAsc"
              ? ChevronUpIcon
              : context.sortBy === "statusDesc"
              ? ChevronDownIcon
              : "",
        },
        {
          text: "Reported Date",
          className: "cursor-pointer",
          textAlign: "center",
          onClick: () => SortBy("date"),
          component:
            context.sortBy === "dateAsc"
              ? ChevronUpIcon
              : context.sortBy === "dateDesc"
              ? ChevronDownIcon
              : "",
        },
        { text: "Actions", className: "", textAlign: "center" },
      ]);
    } else if (width > 639) {
      setHeaders([
        {
          text: "Content Name",
          className: "text-left px-6 py-3",
          onClick: () => SortBy("name"),
          component:
            context.sortBy === "nameAsc"
              ? ChevronUpIcon
              : context.sortBy === "nameDesc"
              ? ChevronDownIcon
              : "",
        },
        { text: "Status", className: "", textAlign: "center" },
        { text: "Actions", className: "", textAlign: "center" },
      ]);
    } else {
      setHeaders([
        {
          text: "Content Name",
          className: "text-left px-6 py-3",
          onClick: () => SortBy("name"),
          component:
            context.sortBy === "nameAsc"
              ? ChevronUpIcon
              : context.sortBy === "nameDesc"
              ? ChevronDownIcon
              : "",
        },
        { text: "Actions", className: "", textAlign: "center" },
      ]);
    }
  }, [width, context.sortBy]);

  const SortBy = (type) => {
    const typeAsc = type + "Asc";
    const typeDesc = type + "Desc";
    context.sortBy === typeDesc
      ? context.setSortBy(typeAsc)
      : context.setSortBy(typeDesc);
  };

  if (context.table === "ReportedContents") {
    if (isLoading) {
      return <TableLoading />;
    }

    if (isError) {
      return <ContentError />;
    }

    return (
      <div className="space-y-2 ">
        <DataTable
          headers={headers}
          data={data.data}
          noDataText="No Reported Contents"
        >
          {data.data.map(
            ({
              author,
              contentName,
              reportDate,
              reportId,
              remarks,
              reporter,
              contentId,
              status,
            }) => {
              return (
                <tr
                  className="text-center text-sm text-gray-600 hover:bg-gray-100 "
                  key={reportId}
                >
                  <td className="sm:px-6 px-3 py-3 md:w-3/12 w-8/12 text-left">
                    <div className="font-bold">{contentName}</div>
                    <div className="md:hidden block text-xs">
                      <div>{author ? author : "Unknown Author"}</div>
                      <span
                        className={`sm:hidden block ${
                          status === "Approved"
                            ? "text-green-600 rounded-3xl"
                            : status === "Pending"
                            ? "text-yellow-600 rounded-3xl"
                            : status === "Declined"
                            ? "text-red-600 rounded-3xl"
                            : "text-gray-600 rounded-3xl"
                        }`}
                      >
                        {status}
                      </span>
                      <div>{reporter}</div>
                      <div className="text-gray-400 mt-2">
                        {moment.utc(reportDate).local().format("lll")}
                      </div>
                    </div>
                  </td>
                  <td className="w-2/12 text-left md:table-cell hidden">
                    {author}
                  </td>
                  <td className="w-2/12 text-left md:table-cell hidden">
                    {reporter}
                  </td>
                  <td className="w-2/12 overflow-ellipsis text-left md:table-cell hidden">
                    {remarks}
                  </td>
                  <td className="text-xs sm:table-cell hidden">
                    <span
                      className={`${
                        status === "Approved"
                          ? "text-green-600 bg-green-200 px-3 py-1 rounded-3xl"
                          : status === "Pending"
                          ? "text-yellow-600 bg-yellow-200 px-3 py-1 rounded-3xl"
                          : status === "Declined"
                          ? "text-red-600 bg-red-200 px-3 py-1 rounded-3xl"
                          : "text-gray-600 bg-gray-200 px-3 py-1 rounded-3xl"
                      }`}
                    >
                      {status}
                    </span>
                  </td>
                  <td className="text-xs md:table-cell hidden">
                    {moment.utc(reportDate).local().format("lll")}
                  </td>
                  <td className="flex justify-center items-center py-3">
                    <FlatButton
                      onClick={() => {
                        history.push(`/reported-view/${reportId}`);
                      }}
                      className={`bg-orange text-white items-center flex gap-x-2 px-5 hover:bg-orange hover:bg-opacity-50`}
                    >
                      <span className="text-xs">View</span>
                      <EyeIcon className="h-4 w-4" />
                    </FlatButton>
                  </td>
                </tr>
              );
            }
          )}
        </DataTable>
      </div>
    );
  }
  return <div></div>;
};

export default ReportedContents;
