import React, { useState, useContext, useEffect } from "react";
import Textbox from "../../Inputs/Textbox";
import { DocumentDownloadIcon } from "@heroicons/react/outline";
import { FlatButton } from "../../Buttons/Buttons";
import Select from "../../Inputs/Select";
import { UsersContext } from "../../../context/UsersContext";
import { exportUsers } from "../../../services/export";

const Filters = ({ ExportFileFunction }) => {
  const context = useContext(UsersContext);

  return (
    <div className="space-y-7">
      <div className=" bg-white sm:p-8 p-5  rounded-3xl shadow-xl space-y-4 ">
        <div className="font-bold text-Orange text-lg ">Filters</div>
        <div className="grid xl:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-x-5 items-end gap-y-4">
          <div className="">
            <Textbox
              label="Full Name"
              placeholder=""
              value={context.fullname}
              onChange={(e) => {
                context.setFullname(e.target.value);
              }}
            />
          </div>
          <div className="">
            <Textbox
              label="Username"
              placeholder=""
              value={context.username}
              onChange={(e) => {
                context.setUsername(e.target.value);
              }}
            />
          </div>
          <div className="">
            <Select
              label="Status"
              value={context.status}
              onChange={(e) => {
                context.setStatus(e.target.value);
              }}
            >
              <option>Any</option>
              <option>Active</option>
              <option>Pending</option>
              <option>Declined</option>
              <option>Disabled</option>
            </Select>
          </div>
          <div className="flex sm:flex-row flex-col gap-x-4 gap-y-2">
            <FlatButton
              className="w-full justify-center bg-blue hover:bg-blue text-white hover:bg-opacity-70"
              onClick={() => {
                context.ApplyFilters();
              }}
            >
              Search
            </FlatButton>
            <FlatButton
              className="w-full justify-center btn-Orange-secondary"
              onClick={() => {
                context.ResetFilters();
              }}
            >
              Reset
            </FlatButton>
          </div>
        </div>
      </div>

      <div className="flex sm:flex-row flex-col gap-y-2">
        <div className="flex items-center sm:justify-start justify-center space-x-2 text-sm font-semibold text-gray-600">
          <div className="flex items-center">Show</div>
          <div className="w-16 ">
            <Select
              value={context.entries}
              className="bg-gray-200 rounded-md"
              onChange={(e) => {
                context.setEntries(e.target.value);
                context.setPage(1);
              }}
            >
              {[...Array(6)].map((x, i) => {
                return <option key={i}>{i + 5}</option>;
              })}
            </Select>
          </div>
          <div>Entries</div>
        </div>

        <div className="ml-auto flex items-center sm:w-auto w-full space-x-3">
          <FlatButton
            className="bg-dark-orange text-white hover:bg-dark-orange hover:bg-opacity-50 space-x-2 md:w-auto w-full justify-center"
            onClick={() => {
              exportUsers(context.filters);
            }}
          >
            <span>Export File </span>
            <DocumentDownloadIcon className="h-5 w-5" />
          </FlatButton>
        </div>
      </div>
    </div>
  );
};

export default Filters;
