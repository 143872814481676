import Carousel from "../Others/Carousel";
import React, { useContext } from "react";
import { FilterContext } from "../../context/FilterContext";
import AllContents from "../Dashboard/AllContents";
import { FlatButton } from "../Buttons/Buttons";
import Trending from "./Carousel/Trending";
import { AuthContext } from "../../context/AuthContext";
import MyFavorites from "./Carousel/MyFavorites";

const MemberDashboard = () => {
  const filterContext = useContext(FilterContext);
  const authContext = useContext(AuthContext);

  // console.log(authContext.favorites);

  if (filterContext.filterState.filter) {
    return (
      <div
        id="content-scroll"
        className="z-0 lg:p-10 mb-20 p-4 w-full overflow-auto flex flex-col space-y-4"
      >
        <div>
          <div className="flex items-center w-full text-xl font-bold text-dark-blue uppercase mb-5">
            <span>All Contents</span>
          </div>
          <AllContents />
        </div>
      </div>
    );
  }

  return (
    <div
      id="content-scroll"
      className="z-0 lg:p-10 mb-10 p-4 w-full overflow-auto text-gray-600 flex-col space-y-4"
    >
      {/* <div>
        <div className="flex items-center w-full text-2xl font-bold text-gray-600">
          <span>Last Visited</span>
          <FlatButton text="See More" />
        </div>
        <Carousel name="LastVisited" />
      </div> */}

      <div>
        <div className="flex md:pt-10 lg:pt-0 pt-10 items-center w-full text-xl font-bold ">
          <span>Favorites</span>
          <FlatButton text="See More" />
        </div>
        <MyFavorites />
      </div>

      <div>
        <div className="flex  md:pt-10 lg:pt-0 pt-10 items-center w-full text-xl font-bold  mb-5">
          <span>All Contents</span>
        </div>
        <AllContents />
      </div>
    </div>
  );
};

export default MemberDashboard;
