import React, { useState, useEffect, useContext } from "react";
import SidebarCheckbox from "../Inputs/SidebarCheckbox";
import { FilterIcon } from "@heroicons/react/outline";
import StarRating from "../Others/StarRating";
import SidebarRadio from "../Inputs/SidebarRadio";
import { FlatButton } from "../Buttons/Buttons";
import { FilterContext } from "../../context/FilterContext";
import { categoryOptions } from "../../services/categories";
import { useQuery } from "react-query";
import Select from "react-select";
import { contentTypeOptions } from "../../services/content-type";

const contentTypes = [
  { label: "Videos", value: 0 },
  { label: "E-Book", value: 1 },
];
const contentDays = [
  { label: "Last 30 Days", value: 30 },
  { label: "Last 15 Days", value: 15 },
  { label: "Last 10 Days", value: 10 },
];

const Sidebar = () => {
  const { data: contentTypeOption } = useQuery(
    "contentTypeOptions",
    contentTypeOptions
  );
  const { data: categoryTypeOption } = useQuery(
    "categoryOptions",
    categoryOptions
  );

  const filterContext = useContext(FilterContext);

  const [filters, setFilters] = useState({
    days: null,
    rating: null,
    categories: null,
    contentType: [],
  });

  const [days, setDays] = useState(null);
  const [rating, setRating] = useState(null);
  const [reset, setReset] = useState(true);
  const [categories, setCategories] = useState([]);
  const [contentType, setContentType] = useState([]);

  // const handleTypes = (e) => {
  //   if (e.currentTarget.checked) {
  //     setTypes((types) => [...types, e.target.value]);
  //   } else {
  //     setTypes(types.filter((item) => item !== e.target.value));
  //   }
  // };

  const handleDays = (e) => {
    if (e.currentTarget.checked) {
      setDays(e.target.value);
    }
  };

  useEffect(() => {
    setFilters({ days, rating, categories, contentType });
    setReset(false);
  }, [days, rating, categories, contentType]);

  useEffect(() => {
    filterContext.setSidebarFilter(filters);
  }, [filters]);

  const ResetFilters = () => {
    setDays(null);
    setCategories([]);
    setContentType([]);
    setRating(null);
    setReset(true);
  };

  return (
    <div className="h-full pb-20">
      <div className="sm:block top-0 hidden h-full p-10 overflow-y-auto overflow-x-hidden ">
        <div className="text-white rounded-2xl h-full">
          <div className="sm:w-80 w-full shadow-xl rounded-2xl bg-blue p-3">
            <div className="p-3 flex items-center">
              <span className="font-bold uppercase flex items-center">
                Filters
                <FilterIcon className="h-4 w-4 ml-2 " />
              </span>

              <FlatButton
                className="ml-auto font-semibold bg-white rounded-full text-blue"
                onClick={() => {
                  ResetFilters();
                }}
              >
                Reset Filters
              </FlatButton>
            </div>
            {/* <div className="text-left font-semibold w-full text-gray-600 py-2 px-3">
              <span className="text-gray-600 opacity-70  font-bold text-sm uppercase">
                Types
              </span>
              <div className="py-2 text-gray-400 ">
                {contentTypes.map((type, key) => {
                  return (
                    <SidebarCheckbox
                      key={key}
                      text={type.label}
                      value={type.value}
                      onChange={(e) => {
                        handleTypes(e);
                      }}
                      checked={
                        types.filter((item) => item === type.value.toString())
                          .length > 0
                          ? true
                          : false
                      }
                    />
                  );
                })}
              </div>
            </div> */}
            <div className="text-left w-full font-semibold py-2 px-3">
              <span className="opacity-70 font-bold text-sm uppercase">
                New Releases
              </span>
              <div className="py-2 text-white">
                {contentDays.map((day, key) => {
                  const value = key;
                  return (
                    <SidebarRadio
                      key={key}
                      text={day.label}
                      onChange={(e) => {
                        handleDays(e);
                      }}
                      name="days"
                      value={day.value}
                      {...(days === null && { checked: false })}
                    >
                      {days == day.value ? (
                        <i className="flex text-white items-center bx bx-checkbox-square bx-sm py-2 "></i>
                      ) : (
                        <i className="flex text-gray-300 items-center bx bx-checkbox bx-sm py-2"></i>
                      )}
                    </SidebarRadio>
                  );
                })}
              </div>
            </div>
            <div className="text-left w-full font-bold py-2 px-3">
              <span className="opacity-70 font-bold text-sm uppercase">
                Average Customer Review
              </span>
              <div className="flex items-center mt-2 ">
                <StarRating setRating={setRating} reset={reset} />
                {/* <span className="text-gray-600 opacity-70 font-bold text-sm uppercase">
                  {" "}
                  & Up
                </span> */}
              </div>
            </div>

            <div className="text-left w-full font-bold py-2 px-3 space-y-3">
              <span className="opacity-70 font-bold text-sm uppercase">
                Content Type
              </span>
              <Select
                options={contentTypeOption}
                value={contentType}
                isMulti
                isSearchable
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => `${option.id}`}
                styles={{
                  control: (styles) => ({
                    ...styles,
                    backgroundColor: "#F9FAFB",
                    boxShadow: "inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);",
                    border: "none",
                    fontSize: "0.875rem",
                    fontWeight: "normal",
                    borderRadius: "0.375rem",
                  }),
                  option: (styles) => ({
                    ...styles,
                    fontWeight: "normal",
                    fontSize: "0.875rem",
                    color: "black",
                  }),
                }}
                onChange={(e) => {
                  setContentType(e);
                }}
              />
            </div>

            <div className="text-left w-full font-bold py-2 px-3 space-y-3">
              <span className="opacity-70 font-bold text-sm uppercase">
                Categories
              </span>
              <Select
                options={categoryTypeOption}
                value={categories}
                isMulti
                isSearchable
                getOptionLabel={(option) => `${option.categoryName}`}
                getOptionValue={(option) => `${option.categoryId}`}
                styles={{
                  control: (styles) => ({
                    ...styles,
                    backgroundColor: "#F9FAFB",
                    boxShadow: "inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);",
                    border: "none",
                    fontSize: "0.875rem",
                    fontWeight: "normal",
                    borderRadius: "0.375rem",
                  }),
                  option: (styles) => ({
                    ...styles,
                    fontWeight: "normal",
                    fontSize: "0.875rem",
                    color: "black",
                  }),
                }}
                onChange={(e) => {
                  setCategories(e);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
