import React, { useContext, useEffect, useState } from "react";
import * as mime from "mime-types";
import DocsReader from "../Contents/DocsReader";
import ContentModal from "../Admin/Contents/Single Content View/ContentModal";
import { AuthContext } from "../../context/AuthContext";
import EpubReader from "../Contents/EpubReader";

var env = process.env.NODE_ENV || "development";
const server = `${
  env === "development"
    ? process.env.REACT_APP_DEV_SERVER
    : process.env.REACT_APP_PROD_SERVER
}`;

const ViewModal = ({ path, isOpen, onClose, fileId, contentId }) => {
  const [type, setType] = useState("");

  useEffect(() => {
    setType(
      mime.lookup(server + path.filePath + path.fileName) === false
        ? ""
        : mime.lookup(server + path.filePath + path.fileName)
    );
  }, [path]);

  const authContext = useContext(AuthContext);

  return (
    <ContentModal
      isOpen={isOpen}
      onClose={onClose}
      className="max-w-full min-h-full my-20"
    >
      <div className="overflow-auto h-full w-full py-0 relative">
        {type.includes("epub") ? (
          <EpubReader path={path.fileId} />
        ) : type.includes("video") ? (
          <div
            className="relative rounded-lg bg-black bg-opacity-90 flex justify-center "
            onContextMenu={(e) => e.preventDefault()}
            style={{ height: "700px" }}
          >
            <video
              controls
              disablePictureInPicture
              controlsList="nodownload"
              className="absolute top-0 h-full object-fit outline-none"
            >
              <source
                src={`${server}/Admin/view-content/file/${path.fileId}?access_token=${authContext.authState.token}`}
              />
            </video>
          </div>
        ) : (
          <DocsReader path={path} contentId={contentId} />
        )}
      </div>
    </ContentModal>
  );
};

export default ViewModal;
