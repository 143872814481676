import axios from "axios";
import { authAxios } from "./interceptor";

var env = process.env.NODE_ENV || "development";
const baseUrl = `${
  env === "development"
    ? process.env.REACT_APP_DEV_SERVER
    : process.env.REACT_APP_PROD_SERVER
}/review`;

export const writeReview = (data) => {
  return authAxios.post(`${baseUrl}/add-review`, data).then((res) => {
    return res;
  });
};

export const getReplies = ({ queryKey }) => {
  const [_key, reviewId] = queryKey;
  return authAxios.get(`${baseUrl}/get-replies/${reviewId}`).then((res) => {
    return res.data;
  });
};

export const addComment = (data) => {
  return authAxios.post(`${baseUrl}/add-comment/`, data).then((res) => {
    // console.log(res);
    return res;
  });
};
