import React, { createContext, useState } from "react";
import { changePassword, updateProfile } from "../services/users";
import { useToasts } from "react-toast-notifications";
import { useQueryClient } from "react-query";

const MyProfileContext = createContext();
const { Provider } = MyProfileContext;

const MyProfileProvider = ({ children }) => {
  const { addToast } = useToasts();
  const queryClient = useQueryClient();
  const [currentPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [passwordStatus, setPasswordStatus] = useState("");
  const [profileStatus, setProfileStatus] = useState("");

  const UpdateProfile = (e, data) => {
    setLoading(true);
    e.preventDefault();
    var formData = new FormData();
    formData.append("FirstName", data.firstName);
    formData.append("MiddleName", data.middleName);
    formData.append("LastName", data.lastName);
    formData.append("EmailAddress", data.email);
    formData.append("DateOfBirth", data.dateOfBirth);
    formData.append("Gender", "Male");
    formData.append("ContactNumber", data.phoneNumber);
    formData.append("DialCode", data.dialCode);
    formData.append("CountryCode", data.countryCode);
    if (data.profilePicture !== null)
      formData.append("ProfilePicture", data.profilePicture);

    updateProfile(formData)
      .then((res) => {
        // console.log(res);
        queryClient.invalidateQueries("my-profile");
        queryClient.invalidateQueries("userProfile");
        setLoading(false);
        addToast("Profile Updated", { appearance: "success" });
        setProfileStatus("success");
      })
      .catch((e) => {
        // console.log(e);
        setLoading(false);
        addToast("Error Updating Profile", { appearance: "error" });
      });
  };

  const ChangePassword = (e) => {
    e.preventDefault();

    setLoading(true);
    const data = {
      currentPassword,
      newPassword,
      confirmPassword,
    };
    changePassword(data)
      .then((res) => {
        // console.log(res);
        setPasswordStatus("success");
        setLoading(false);
        addToast("Password Successfully Changed", { appearance: "success" });
      })
      .catch((e) => {
        setLoading(false);
        setPasswordStatus("error");
        addToast("Error Changing Password", { appearance: "error" });
        setPasswordError(e.response.data);
      });
  };
  return (
    <Provider
      value={{
        loading,
        passwordError,
        passwordStatus,
        setPasswordStatus,
        setOldPassword,
        setNewPassword,
        setConfirmPassword,
        ChangePassword,
        UpdateProfile,
        profileStatus,
        setProfileStatus,
      }}
    >
      {children}
    </Provider>
  );
};

export { MyProfileContext, MyProfileProvider };
