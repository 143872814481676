import { authAxios } from "./interceptor";

var env = process.env.NODE_ENV || "development";
const baseUrl = `${
  env === "development"
    ? process.env.REACT_APP_DEV_SERVER
    : process.env.REACT_APP_PROD_SERVER
}/admin`;

export const getAllContentsList = ({ queryKey }) => {
  const [_key, { page, entries, filters, sortBy }] = queryKey;
  var result;
  var params = [];
  if (filters.contentName !== "")
    params.push("ContentName=" + filters.contentName);
  if (filters.author !== "") params.push("Author=" + filters.author);
  if (filters.status !== "") params.push("Status=" + filters.status);
  if (filters.dateRequestedFrom !== "")
    params.push("FromDate=" + filters.dateRequestedFrom);
  if (filters.dateRequestedTo !== "")
    params.push("ToDate=" + filters.dateRequestedTo);
  params = params.join("&");
  params = params !== "" ? "&" + params : "";

  // console.log(params);

  return authAxios
    .get(
      `${baseUrl}/get-content-list?PageSize=${entries}&PageNumber=${page}&OrderBy=${sortBy}${params}`
    )
    .then((res) => {
      // console.log(res.headers);
      result = {
        pages: JSON.parse(res.headers.pagination).totalPages,
        data: res.data,
      };
      return result;
    });
};

export const getPendingList = ({ queryKey }) => {
  const [_key, { page, entries, filters }] = queryKey;
  var result;
  var params = [];

  if (filters.contentName !== "")
    params.push("ContentName=" + filters.contentName);
  if (filters.author !== "") params.push("Author=" + filters.author);
  if (filters.dateRequestedFrom !== "")
    params.push("FromDate=" + filters.dateRequestedFrom);
  if (filters.dateRequestedTo !== "")
    params.push("ToDate=" + filters.dateRequestedTo);
  params = params.join("&");
  params = params !== "" ? "&" + params : "";

  return authAxios
    .get(
      `${baseUrl}/get-content-list?PageSize=${entries}&PageNumber=${page}&Status=Pending${params}`
    )
    .then((res) => {
      // console.log(res);
      result = {
        pages: JSON.parse(res.headers.pagination).totalPages,
        data: res.data,
      };
      return result;
    });
};

export const getReportedContents = ({ queryKey }) => {
  const [_key, { page, entries, filters, sortBy }] = queryKey;
  var result;
  var params = [];

  if (filters.contentName !== "")
    params.push("ContentName=" + filters.contentName);
  if (filters.author !== "") params.push("Author=" + filters.author);
  if (filters.dateRequestedFrom !== "")
    params.push("FromDate=" + filters.dateRequestedFrom);
  if (filters.dateRequestedTo !== "")
    params.push("ToDate=" + filters.dateRequestedTo);

  params = params.join("&");
  params = params !== "" ? "&" + params : "";

  return authAxios
    .get(
      `${baseUrl}/get-reported-content?PageSize=${entries}&PageNumber=${page}&OrderBy=${sortBy}${params}`
    )
    .then((res) => {
      result = {
        pages: JSON.parse(res.headers.pagination).totalPages,
        data: res.data,
      };
      return result;
    });
};

export const viewReportedContent = ({ queryKey }) => {
  const [_key, { reportId }] = queryKey;
  return authAxios
    .get(`${baseUrl}/view-reported-content/${reportId}`)
    .then((res) => {
      return res.data;
    });
};

export const viewContent = ({ queryKey }) => {
  const [key, contentId] = queryKey;

  return authAxios.get(`${baseUrl}/view-content/${contentId}`).then((res) => {
    return res.data;
  });
};

export const updateStatus = ({ id, data }) => {
  return authAxios
    .put(`${baseUrl}/update-content-status/${id}`, data)
    .then((res) => {
      return res;
    });
};

export const setContentViewOnly = ({ id, data }) => {
  return authAxios.put(`${baseUrl}/set-view-only/${id}`, data).then((res) => {
    return res;
  });
};

export const getCategories = ({ queryKey }) => {
  const [_key, { filters, entries, page, sortBy }] = queryKey;

  var result;
  var params = [];
  if (filters.categoryName !== "")
    params.push("CategoryName=" + filters.categoryName);

  params = params.join("&");
  params = params !== "" ? "&" + params : "";

  return authAxios
    .get(
      `${baseUrl}/get-category?PageSize=${entries}&PageNumber=${page}&OrderBy=${sortBy}${params}`
    )
    .then((res) => {
      result = {
        pages: JSON.parse(res.headers.pagination).totalPages,
        data: res.data,
      };
      return result;
    });
};

export const postCategory = (data) => {
  return authAxios.post(`${baseUrl}/add-category`, data).then((res) => {
    return res;
  });
};

export const editCategory = (data) => {
  const { categoryId, categoryName } = data;
  return authAxios
    .put(`${baseUrl}/update-category/${categoryId}`, {
      categoryName: categoryName,
    })
    .then((res) => {
      return res;
    });
};

export const deleteCategory = (categoryId) => {
  return authAxios
    .delete(`${baseUrl}/delete-category/${categoryId}`)
    .then((res) => {
      return res;
    });
};

export const getUser = ({ queryKey }) => {
  const [_key, { userId }] = queryKey;

  return authAxios.get(`${baseUrl}/get-user-details/${userId}`).then((res) => {
    return res.data;
  });
};

export const updateUserStatus = (id, data) => {
  // console.log(data);
  return authAxios
    .put(`${baseUrl}/update-user-status?userId=${id}`, data)
    .then((res) => {
      return res;
    });
};

export const updateUserRole = (id, data) => {
  // console.log(id);
  return authAxios
    .put(`${baseUrl}/update-user-role/${id}`, data)
    .then((res) => {
      return res;
    });
};

export const getUsers = ({ queryKey }) => {
  const [_key, { filters, entries, page, sortBy }] = queryKey;
  const { fullname, username, status } = filters;
  var result;

  var params = [];
  if (fullname !== "") params.push("FullName=" + fullname);
  if (username !== "") params.push("UserName=" + username);
  if (status !== "" || status !== "Any") params.push("Status=" + status);
  params = params.join("&");
  params = params !== "" ? "&" + params : "";

  // console.log(
  //   `${baseUrl}/get-users?PageSize=${entries}&PageNumber=${page}&OrderBy=${sortBy}${params}`
  // );

  return authAxios
    .get(
      `${baseUrl}/get-users?PageSize=${entries}&PageNumber=${page}&OrderBy=${sortBy}${params}`
    )
    .then((res) => {
      result = {
        pages: JSON.parse(res.headers.pagination).totalPages,
        data: res.data,
      };
      return result;
    });
};

export const getLogs = ({ queryKey }) => {
  const [_key, { filters, entries, page, sortBy }] = queryKey;
  const { type, action } = filters;
  var result;

  var params = [];
  if (type !== 0) params.push("type=" + type);
  if (action !== 0) params.push("action=" + action);

  params = params.join("&");
  params = params !== "" ? "&" + params : "";

  // console.log(
  //   `${baseUrl}/get-audit?PageSize=${entries}&PageNumber=${page}&OrderBy=${sortBy}${params}`
  // );
  return authAxios
    .get(
      `${baseUrl}/get-audit?PageSize=${entries}&PageNumber=${page}&OrderBy=${sortBy}${params}`
    )
    .then((res) => {
      result = {
        pages: JSON.parse(res.headers.pagination).totalPages,
        data: res.data,
      };
      return result;
    });
};

export const updateReportStatus = (id, data) => {
  return authAxios
    .put(`${baseUrl}/update-report-status/${id}`, data)
    .then((res) => {
      return res;
    });
};

export const reportedContentsCount = () => {
  return authAxios
    .get(`${baseUrl}/total-reported-content?Type=1&Status=1`)
    .then((res) => {
      // console.log(res);
      return res.data;
    });
};

export const totalContentsCount = () => {
  return authAxios
    .get(`${baseUrl}/total-contents?Type=1&Status=1`)
    .then((res) => {
      // console.log(res);
      return res.data;
    });
};

export const totalUsersCount = () => {
  return authAxios
    .get(`${baseUrl}/total-registered-users?Type=1&Status=1`)
    .then((res) => {
      // console.log(res);
      return res.data;
    });
};

export const totalPendingRequest = () => {
  return authAxios
    .get(`${baseUrl}/total-pending-contents?Type=1&Status=1`)
    .then((res) => {
      // console.log(res);
      return res.data;
    });
};

export const dashboard = () => {
  return authAxios.get(`${baseUrl}/dashboard`).then((res) => {
    return res.data;
  });
};
