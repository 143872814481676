import React, { useState, useContext, useEffect } from "react";
import Textbox from "../../Inputs/Textbox";
import {
  CheckIcon,
  DocumentDownloadIcon,
  XIcon,
} from "@heroicons/react/outline";
import { FlatButton } from "../../Buttons/Buttons";
import { CategoriesContext } from "../../../context/CategoriesContext";
import Select from "../../Inputs/Select";
import { useToasts } from "react-toast-notifications";
import { exportCategories } from "../../../services/export";

const Filters = ({ ExportFileFunction }) => {
  const context = useContext(CategoriesContext);
  const [newCategoryName, setNewCategoryName] = useState("");
  const { addToast } = useToasts();

  return (
    <div className="space-y-7">
      <div className=" w-full bg-white p-8 rounded-3xl shadow-xl space-y-4">
        <div className="font-bold text-Orange text-lg">Filters</div>
        <div className="flex items-end sm:flex-row flex-col gap-x-4 w-full gap-y-2">
          <div className="flex-1 w-full">
            <Textbox
              label="Category Name"
              value={context.categoryName}
              onChange={(e) => {
                context.setCategoryName(e.target.value);
              }}
            />
          </div>
          <div className="flex-1 flex space-x-4 w-full">
            <FlatButton
              className="w-full justify-center bg-blue hover:bg-blue text-white hover:bg-opacity-70"
              onClick={() => {
                context.categoryName !== "" && context.ApplyFilters();
              }}
            >
              Search
            </FlatButton>
            <FlatButton
              className="w-full justify-center btn-Orange-secondary"
              onClick={() => {
                context.ResetFilters();
              }}
            >
              Reset
            </FlatButton>
          </div>
        </div>
      </div>

      <div className="flex sm:flex-row flex-col items-center gap-y-2">
        <div className="flex items-center space-x-2 text-sm font-semibold text-gray-600">
          <div className="flex items-center">Show</div>
          <div className="w-16 ">
            <Select
              value={context.entries}
              className="bg-gray-200 rounded-md"
              onChange={(e) => {
                context.setEntries(e.target.value);
                context.setPage(1);
              }}
            >
              {[...Array(6)].map((x, i) => {
                return <option key={i}>{i + 5}</option>;
              })}
            </Select>
          </div>
          <div>Entries</div>
        </div>
        <div className="ml-auto sm:w-auto w-full flex gap-x-3 items-center">
          <FlatButton
            className={`justify-center flex-shrink-0 ${
              context.newCategory
                ? "bg-gray-400 hover:bg-gray-400 text-gray-300 cursor-not-allowed"
                : "bg-blue hover:bg-blue text-white hover:bg-opacity-70"
            }`}
            onClick={() => {
              context.setNewCategory(true);
            }}
            disabled={context.newCategory}
          >
            New Category
          </FlatButton>
          <FlatButton
            className="bg-dark-orange text-white hover:bg-dark-orange hover:bg-opacity-50 space-x-2 md:w-auto w-full justify-center"
            onClick={() => {
              exportCategories();
            }}
          >
            <span>Export File </span>
            <DocumentDownloadIcon className="h-5 w-5" />
          </FlatButton>
        </div>
      </div>
      {context.newCategory && context.count === 0 ? (
        <div className="flex gap-x-2 justify-center">
          <Textbox
            value={newCategoryName}
            placeholder="Enter Category Name..."
            onChange={(e) => {
              setNewCategoryName(e.target.value);
            }}
          />
          <FlatButton
            className="bg-blue hover:bg-blue text-white hover:bg-opacity-70 gap-x-2"
            onClick={() => {
              if (newCategoryName !== "")
                context.SaveNewCategory(newCategoryName);
            }}
          >
            <span className="text-xs">Save</span>
            <CheckIcon className="h-4 w-4" />
          </FlatButton>
          <FlatButton
            className="btn-Gray gap-x-2"
            onClick={() => {
              context.setNewCategory(false);
            }}
          >
            <span className="text-xs">Cancel</span>
            <XIcon className="h-4 w-4" />
          </FlatButton>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Filters;
