import {
  ChatAlt2Icon,
  ChatIcon,
  PaperAirplaneIcon,
  ReplyIcon,
} from "@heroicons/react/outline";
import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import * as signalR from "@microsoft/signalr";
import moment from "moment";
import {
  Switch,
  useParams,
  useRouteMatch,
  Route,
  useHistory,
  useLocation,
} from "react-router-dom";
import { PresenceContext } from "../context/PresenceContext";
import LoadingNotRounded from "../components/Others/LoadingNotRounded";
import { chatMemberDetails, messageMemberList } from "../services/messaging";
import InfiniteScroll from "react-infinite-scroll-component";
import { useInfiniteQuery, useQuery } from "react-query";
import Loader from "react-loader-spinner";

var env = process.env.NODE_ENV || "development";
const server = `${
  env === "development"
    ? process.env.REACT_APP_DEV_SERVER
    : process.env.REACT_APP_PROD_SERVER
}`;

const BubbleMe = ({ msg, time }) => {
  return (
    <div className="w-full  h-auto flex justify-end">
      <div className="flex items-center space-x-3 group">
        <div className="text-xs text-gray-400 float-right opacity-0 group-hover:opacity-100">
          {moment.utc(time).local().fromNow()}
        </div>
        <div
          className="bg-Orange w-auto p-2 px-3 text-sm text-white rounded-t-3xl rounded-l-3xl cursor-pointer break-words"
          style={{ maxWidth: "300px" }}
        >
          {msg}
        </div>
      </div>
    </div>
  );
};

const BubbleThem = ({ msg, time }) => {
  return (
    <div className="w-full h-auto flex justify-start ">
      <div className="flex items-center space-x-3 group">
        <div
          className="bg-gray-300 flex-none w-auto p-2 px-3 text-sm text-gray-600 rounded-t-3xl rounded-r-3xl cursor-pointer break-words"
          style={{ maxWidth: "300px" }}
        >
          {msg}
        </div>
        <div className="text-xs text-gray-400 text-left w-full opacity-0 group-hover:opacity-100">
          {moment.utc(time).local().fromNow()}
        </div>
      </div>
    </div>
  );
};

const Chat = () => {
  const presenceContext = useContext(PresenceContext);
  const { username } = useParams();
  const location = useLocation();
  const authContext = useContext(AuthContext);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [noHistory, setNoHistory] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  let [loading, setLoading] = useState(true);
  const { data, isLoading } = useQuery(
    ["chat-member-details", { userName: username }],
    chatMemberDetails
  );

  const [connection, setConnection] = useState("");

  useEffect(() => {
    setLoading(true);
    const connect = new signalR.HubConnectionBuilder()
      .withUrl(
        server +
          "/hubs/message?access_token=" +
          authContext.authState.token +
          "&user=" +
          username
      )
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Debug)
      .build();
    setConnection(connect);
  }, [username, location, authContext.authState.token]);

  useEffect(() => {
    if (connection) {
      connection.start().then(() => {
        connection.on("NewMessage", (data) => {
          // console.log(data);
          // //console.log(messages);
          // setNewMessage(data);
          const chatContainer = document.querySelector("#chatContainer");
          const chatBubble = document.querySelector("#chatBubble");
          chatContainer.scroll(0, chatBubble.clientHeight);
        });

        connection.on("ReceiveMessageThread", (data) => {
          // console.log(data, "ReceiveMessageThread");
          setMessages(data);
        });
      });
    }
  }, [connection]);

  useEffect(() => {
    setLoading(false);
    setTimeout(() => {
      const chatBubble = document.querySelector("#chatBubble");
      const chatContainer = document.querySelector("#chatContainer");
      chatContainer.scroll(0, chatBubble.clientHeight);
    }, 100);
    if (messages.length === 0) setNoHistory(true);
    else setNoHistory(false);
  }, [messages]);

  const Send = (e) => {
    e.preventDefault();

    setMessages((m) => [
      ...m,
      { senderUsername: authContext.profile.userName, content: message },
    ]);

    if (message.trim().length)
      connection.invoke("SendMessage", {
        RecipientUsername: username,
        Content: message,
      });

    setMessage("");
  };

  if (loading === true) {
    return (
      <div
        className="bg-white flex flex-col justify-center px-8 relative animate-pulse space-y-5"
        style={{ width: "700px" }}
      >
        <div className="h-14 w-14 overflow-hidden rounded-full bg-gray-300"></div>
        <div
          className="bg-gray-300 overflow-auto rounded-3xl p-5"
          style={{ height: "600px" }}
        ></div>
        <div className="bg-gray-300 h-10 w-full rounded-full"></div>
      </div>
    );
  }

  return (
    <div
      className="bg-white flex flex-col justify-center xs:px-8 px-5 py-5 relative gap-y-5"
      style={{ width: `${width > 765 ? "700px" : "100%"}` }}
    >
      {data && (
        <div className="flex items-center overflow-hidden gap-x-5 p-4">
          <div
            className={`h-12 w-12 overflow-hidden rounded-full ring-4 flex-none ${
              presenceContext.onlineUsers.filter(
                (username) => username === data.userName
              ).length > 0
                ? "ring-LightOrange"
                : "ring-gray-100"
            }`}
          >
            <img
              src={
                data.photoUrl
                  ? server + data.photoUrl
                  : "https://i.ibb.co/dm6nhgZ/Untitled-2-01.png"
              }
              className="h-full w-full object-cover"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "https://i.ibb.co/dm6nhgZ/Untitled-2-01.png";
              }}
            />
          </div>
          <div className="text-lg font-semibold w-full text-gray-600 flex flex-col items-start gap-y-1">
            <div className="overflow-hidden truncate sm:w-full w-32 ">
              {data.fullName || "CSI Member"}
            </div>
            {presenceContext.onlineUsers.filter(
              (username) => username === data.userName
            ).length > 0 ? (
              <div className="rounded-full bg-LightOrange text-Orange bg-opacity-50 w-auto p-1 px-3 text-xs">
                Online
              </div>
            ) : (
              <div className="rounded-full bg-gray-100 text-gray-500 w-auto p-1 px-3 text-xs">
                Offline
              </div>
            )}
          </div>
        </div>
      )}

      <div
        id="chatContainer"
        className="bg-gray-100 overflow-auto rounded-3xl p-5"
        style={{ height: "600px" }}
      >
        {noHistory && (
          <div className="h-full w-full flex items-center justify-center text-sm font-semibold">
            Start your conversation with {data && data.fullName} by sending "Hi"
          </div>
        )}
        <div className="space-y-1" id="chatBubble">
          {messages.map((message, key) => {
            return (
              <div key={key}>
                {message.senderUsername === authContext.profile.userName ? (
                  <BubbleMe msg={message.content} time={message.messageSent} />
                ) : (
                  <BubbleThem
                    msg={message.content}
                    time={message.messageSent}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
      <form
        className="flex space-x-3 items-center px-2"
        onSubmit={(e) => {
          Send(e);
        }}
      >
        <input
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          placeholder="Write Something"
          className="bg-gray-100 p-2 px-5 w-full shadow-inner shadow-md rounded-full outline-none focus:ring-2 focus:ring-LightOrange text-sm focus:ring-offset-2 "
        />
        <button className="text-white bg-orange h-8 w-8 flex justify-center items-center rounded-full flex-none ">
          <PaperAirplaneIcon className="h-4 w-4 transform rotate-90" />
        </button>
      </form>
    </div>
  );
};

const Member = ({
  userName,
  selectedUser,
  setSelectedUser,
  isOnline,
  photoUrl,
  fullName,
}) => {
  const history = useHistory();
  return (
    <div
      className={`sm:p-5 p-2 rounded-xl flex items-center space-x-3 cursor-pointer ${
        selectedUser === userName
          ? "bg-LightOrange bg-opacity-20 ring-2 ring-LightOrange text-Orange"
          : "hover:bg-LightOrange hover:bg-opacity-20 hover:text-Orange"
      }`}
      onClick={() => {
        history.push(`/messages/${userName}`);
        setSelectedUser(userName);
      }}
    >
      <div
        className={`h-10 w-10 bg-gray-300 rounded-full ring-4 relative flex-none ${
          isOnline ? "ring-LightOrange" : "ring-gray-100"
        }`}
      >
        <img
          className="h-full w-full object-cover rounded-full"
          src={
            photoUrl !== ""
              ? server + photoUrl
              : "https://i.ibb.co/dm6nhgZ/Untitled-2-01.png"
          }
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "https://i.ibb.co/dm6nhgZ/Untitled-2-01.png";
          }}
        />
      </div>
      <div className="hidden flex-col items-start justify-center space-y-1 lg:flex">
        <div className="capitalize text-sm w-60 truncate">{fullName}</div>
        {isOnline ? (
          <div className="rounded-full bg-LightOrange text-Orange bg-opacity-50 w-auto p-1 px-3 text-xs">
            Online
          </div>
        ) : (
          <div className="rounded-full bg-gray-100 text-gray-500 w-auto p-1 px-3 text-xs">
            Offline
          </div>
        )}
      </div>
    </div>
  );
};

const MemberList = ({ nameSearch }) => {
  const authContext = useContext(AuthContext);
  const history = useHistory();

  const presenceContext = useContext(PresenceContext);
  const [selectedUser, setSelectedUser] = useState("");
  const { data, fetchNextPage, isLoading, hasNextPage } = useInfiniteQuery(
    ["msg-member-list", { name: nameSearch }],
    ({ pageParam = 1, queryKey }) => messageMemberList(pageParam, queryKey),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length < 10) return undefined;
        else return allPages.length + 1;
      },
    }
  );

  if (isLoading) {
    return (
      <div
        className="px-6 animate-pulse flex-auto w-full overflow-auto space-y-1 "
        style={{ maxHeight: "100vh" }}
      >
        {[...Array(10)].map((x, i) => {
          return (
            <div key={i} className="bg-gray-300 w-full h-20 rounded-xl"></div>
          );
        })}
      </div>
    );
  }

  return (
    <div
      className="overflow-auto block md:px-5 px-2 "
      style={{ maxHeight: "90%" }}
      id="msg-member-list"
    >
      <InfiniteScroll
        next={fetchNextPage}
        hasMore={hasNextPage}
        scrollableTarget="msg-member-list"
        className="sm:p-2 p-1"
        dataLength={data.pages.length}
        loader={
          <div className="w-full p-5 flex justify-center ">
            <Loader type="TailSpin" color="#B5B5B5" height={40} width={40} />
          </div>
        }
      >
        {data.pages.map((pageData, key) => {
          return pageData.map((member, key) => {
            return (
              member.userName !== authContext.profile.userName &&
              presenceContext.onlineUsers.map((username) => {
                return (
                  username === member.userName && (
                    <Member
                      key={key}
                      userName={member.userName}
                      selectedUser={selectedUser}
                      setSelectedUser={setSelectedUser}
                      isOnline={true}
                      photoUrl={member.photoUrl}
                      fullName={member.fullName}
                    />
                  )
                );
              })
            );
          });
        })}

        {data.pages.map((pageData, key) => {
          return pageData.length === 0 &&
            nameSearch !== "" &&
            data.pages.length === 1 ? (
            <div key={key} className="text-center text-sm">
              Member not found.
            </div>
          ) : (
            pageData.map((member, key) => {
              return (
                member.userName !== authContext.profile.userName &&
                presenceContext.onlineUsers.filter(
                  (username) => username === member.userName
                ).length === 0 && (
                  <Member
                    key={key}
                    userName={member.userName}
                    selectedUser={selectedUser}
                    setSelectedUser={setSelectedUser}
                    isOnline={false}
                    photoUrl={member.photoUrl}
                    fullName={member.fullName}
                  />
                )
              );
            })
          );
        })}
      </InfiniteScroll>
    </div>
  );
};

const Messages = () => {
  let match = useRouteMatch();
  const [nameSearch, setNameSearch] = useState("");

  const [width, setWidth] = useState(window.innerWidth);

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  return (
    <div className="h-full w-full flex justify-center md:gap-x-10 gap-x-0 overflow-auto">
      <div className="md:py-10 py-0 h-full">
        <div className="bg-white pb-8 lg:w-96 w-full shadow-xl md:rounded-3xl rounded-none md:border-r-0 border-r h-full flex flex-col">
          <div className="space-y-4 py-5 overflow-hidden ">
            <div className="font-bold text-xl flex items-center lg:justify-start justify-center gap-x-2 text-Orange px-7">
              <div className="lg:block hidden">Messages</div>
              <ChatAlt2Icon className="lg:h-4 lg:w-4 w-6 h-6" />
            </div>
            <div className="px-6 lg:flex hidden">
              <input
                onChange={(e) => {
                  setNameSearch(e.target.value);
                }}
                placeholder="Search Messages"
                className="bg-gray-100 p-2 px-5 w-full shadow-inner shadow-md rounded-full outline-none focus:ring-2 focus:ring-LightOrange text-sm focus:ring-offset-2 "
              />
            </div>
            <MemberList nameSearch={nameSearch} />
          </div>
        </div>
      </div>
      <Switch>
        <Route path={`${match.path}/:username`}>
          <Chat />
        </Route>
        <Route path={match.path}>
          <div
            className="flex flex-col justify-center px-5 space-y-5 items-center font-bold text-lg text-gray-400"
            style={{ width: `${width > 765 ? "700px" : "100%"}` }}
          >
            Please select a user you want to message.
            <ChatIcon className="h-10 w-10" />
          </div>
        </Route>
      </Switch>
    </div>
  );
};

export default Messages;
