import React, { useState } from "react";
import { FlatButton } from "../../../Buttons/Buttons";
import {
  CheckIcon,
  ExclamationIcon,
  InformationCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import Modal from "../../../Others/Modal";
import TextArea from "../../../Inputs/TextArea";

const ContentButtons = ({
  contentStatus,
  context,
  contentId,
  reportStatus,
  isViewOnly,
}) => {
  const [modal, setModal] = useState(false);
  const [status, setStatus] = useState("");
  const [remarks, setRemarks] = useState("");
  const [viewOnly, setViewOnly] = useState(false);

  if (contentStatus === "Pending") {
    return (
      <div className="absolute bottom-8 bg-white shadow-lg right-8 flex p-2  rounded-xl gap-x-2">
        <FlatButton
          className="flex items-center btn-Orange-primary px-5"
          onClick={() => {
            setModal(true);
            setStatus("approve");
          }}
        >
          Approve Content
          <CheckIcon className="h-5 w-5 ml-2" />
        </FlatButton>
        <FlatButton
          className="btn-Gray px-5"
          onClick={() => {
            setModal(true);
            setStatus("decline");
          }}
        >
          Decline Content
          <XIcon className="h-5 w-5 ml-2" />
        </FlatButton>
        <Modal
          title={status === "decline" ? "Decline Content" : "Approve Content"}
          isOpen={modal}
          onClose={() => {
            setModal(false);
          }}
          className="relative"
          description={`Are you sure you want to ${status} this content?`}
        >
          <div
            className={`absolute inline-block top-3 z-100  p-2 text-white rounded-full right-3 ${
              status === "decline" ? "bg-red-500" : "bg-green-500"
            }`}
          >
            {status === "decline" ? (
              <ExclamationIcon className="h-7 w-7" />
            ) : (
              <CheckIcon className="h-7 w-7" />
            )}
          </div>
          {status === "decline" && (
            <div>
              <TextArea
                label="Remarks"
                className="mb-5"
                onChange={(e) => {
                  setRemarks(e.target.value);
                }}
              />
            </div>
          )}
          <div className="flex space-x-2 float-right">
            {status === "decline" ? (
              <FlatButton
                className="btn-Red"
                onClick={() => {
                  context.DeclineContent(contentId);
                  setModal(false);
                }}
              >
                Decline
              </FlatButton>
            ) : (
              <FlatButton
                className="btn-Green"
                onClick={() => {
                  context.ApproveContent(contentId);
                  setModal(false);
                }}
              >
                Approve
              </FlatButton>
            )}
            <FlatButton
              className="btn-Gray"
              onClick={() => {
                setModal(false);
              }}
            >
              Cancel
            </FlatButton>
          </div>
        </Modal>
      </div>
    );
  }

  if (contentStatus === "Reported" && reportStatus === "Pending") {
    return (
      <div className="absolute bottom-8 shadow bg-white right-8 flex p-2 rounded-xl gap-x-2">
        <FlatButton
          className="flex items-center btn-Gray px-5"
          onClick={() => {
            setModal(true);
            setStatus("decline");
          }}
        >
          Decline Report
          <ExclamationIcon className="h-5 w-5 ml-2" />
        </FlatButton>
        <FlatButton
          className="bg-yellow-500 hover:bg-yellow-600 text-white px-5"
          onClick={() => {
            setModal(true);
            setStatus("approve");
          }}
        >
          Approve Report
          <InformationCircleIcon className="h-5 w-5 ml-2" />
        </FlatButton>
        <Modal
          title={status === "decline" ? "Decline Report" : "Approve Report"}
          isOpen={modal}
          onClose={() => {
            setModal(false);
          }}
          className="relative"
          description={`Are you sure you want to ${status} this report?`}
        >
          <div
            className={`absolute inline-block top-3 z-100  p-2 text-white rounded-full right-3 bg-yellow-500`}
          >
            <ExclamationIcon className="h-7 w-7" />
          </div>
          {status === "disable" && (
            <div>
              <TextArea
                label="Remarks"
                className="mb-5"
                onChange={(e) => {
                  setRemarks(e.target.value);
                }}
              />
            </div>
          )}
          <div className="flex space-x-2 float-right">
            {status === "decline" ? (
              <FlatButton
                className="bg-yellow-500 hover:bg-yellow-600 text-white"
                onClick={() => {
                  context.UpdateReportStatus(contentId, "Declined", remarks);
                  setModal(false);
                }}
              >
                Decline Report
              </FlatButton>
            ) : (
              <FlatButton
                className="bg-yellow-500 hover:bg-yellow-600 text-white"
                onClick={() => {
                  context.UpdateReportStatus(contentId, "Approved", remarks);
                  setModal(false);
                }}
              >
                Approve Report
              </FlatButton>
            )}
            <FlatButton
              className="btn-Gray"
              onClick={() => {
                setModal(false);
              }}
            >
              Cancel
            </FlatButton>
          </div>
        </Modal>
      </div>
    );
  }

  if (contentStatus === "Approved") {
    return (
      <div className="absolute bottom-8 shadow bg-white right-8 flex p-2 rounded-xl gap-x-2">
        <FlatButton
          className="btn-Orange-primary"
          onClick={() => {
            // console.warn(isViewOnly);
            context.SetViewOnly(contentId, !isViewOnly);
          }}
        >
          {isViewOnly ? "Disable View Only" : "Set as View Only"}
        </FlatButton>
        <FlatButton
          className="btn-Gray"
          onClick={() => {
            setModal(true);
            setStatus("approve");
          }}
        >
          Disable Content
          <ExclamationIcon className="h-5 w-5 ml-2" />
        </FlatButton>
        <Modal
          title="Disable Content"
          isOpen={modal}
          onClose={() => {
            setModal(false);
          }}
          className="relative"
          description={`Are you sure you want to disable this content?`}
        >
          <div
            className={`absolute inline-block top-3 z-100  p-2 text-white rounded-full right-3 bg-yellow-500`}
          >
            <ExclamationIcon className="h-7 w-7" />
          </div>
          <div className="float-right flex gap-x-2">
            <FlatButton
              className="bg-yellow-500 hover:bg-yellow-600 text-white"
              onClick={() => {
                context.DisableContent(contentId);
                setModal(false);
              }}
            >
              Disable Content
            </FlatButton>
            <FlatButton
              className="btn-Gray"
              onClick={() => {
                setModal(false);
              }}
            >
              Cancel
            </FlatButton>
          </div>
        </Modal>
      </div>
    );
  }

  if (contentStatus === "Disabled") {
    return (
      <div className="absolute bottom-8 shadow bg-white right-8 flex p-2 rounded-xl gap-x-2">
        <FlatButton
          className="btn-Orange-primary"
          onClick={() => {
            // console.warn(isViewOnly);
            context.SetViewOnly(contentId, !isViewOnly);
          }}
        >
          {isViewOnly ? "Disable View Only" : "Set as View Only"}
        </FlatButton>
        <FlatButton
          className="btn-Orange-primary"
          onClick={() => {
            setModal(true);
            setStatus("approve");
          }}
        >
          Enable Content
          <ExclamationIcon className="h-5 w-5 ml-2" />
        </FlatButton>

        <Modal
          title="Enable Content"
          isOpen={modal}
          onClose={() => {
            setModal(false);
          }}
          className="relative"
          description={`Are you sure you want to enable this content?`}
        >
          <div
            className={`absolute inline-block top-3 z-100  p-2 text-white rounded-full right-3 bg-yellow-500`}
          >
            <InformationCircleIcon className="h-7 w-7" />
          </div>
          <div className="float-right flex gap-x-2">
            <FlatButton
              className="bg-yellow-500 hover:bg-yellow-600 text-white"
              onClick={() => {
                context.ApproveContent(contentId);
                setModal(false);
              }}
            >
              Enable Content
            </FlatButton>
            <FlatButton
              className="btn-Gray"
              onClick={() => {
                setModal(false);
              }}
            >
              Cancel
            </FlatButton>
          </div>
        </Modal>
      </div>
    );
  }

  return <div></div>;
};

export default ContentButtons;
