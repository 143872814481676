import React, { useState, useEffect } from "react";
import { FlatButton } from "../Buttons/Buttons";

const Stepper = ({ context }) => {
  const [disabledStep2, setDisabledStep2] = useState(true);
  const [disabledStep3, setDisabledStep3] = useState(true);

  useEffect(() => {
    // console.log(context);
    if (context.categories) {
      const {
        title,
        contentType,
        categories,
        files,
        embed,
        existingFiles,
        uploadStatus,
      } = context;
      if (
        title !== "" &&
        categories !== "" &&
        categories.length > 0 &&
        contentType !== ""
      )
        setDisabledStep2(false);
      else setDisabledStep2(true);

      if (
        (files.length > 0 &&
          uploadStatus.filter((s) => s.status === "success").length ===
            files.length) ||
        embed !== "" ||
        existingFiles.length > 0
      )
        setDisabledStep3(false);
      else setDisabledStep3(true);
    }
  }, [context]);

  return (
    <div className="p-2 flex items-center text-gray-400 justify-center font-bold gap-x-4">
      <FlatButton
        className={`h-10 w-10 flex flex-none justify-center font-bold rounded-full ${
          context.step >= 1 ? "btn-Orange-primary" : "border border-gray-300"
        } ${
          context.step === 1 &&
          "ring-LightOrange ring-opacity-40 ring-4 ring-offset-2"
        }`}
        onClick={() => {
          context.setStep(1);
        }}
      >
        1
      </FlatButton>
      <span className={`md:block hidden ${context.step >= 1 && `text-Orange`}`}>
        Details
      </span>
      <div
        className={`w-48 h-1 rounded-xl ${
          context.step > 1 ? "bg-Orange" : "bg-gray-300"
        }`}
      ></div>
      <FlatButton
        className={`h-10 w-10 flex flex-none justify-center font-bold rounded-full ${
          context.step >= 2 ? "btn-Orange-primary" : "border border-gray-300"
        } ${
          context.step === 2
            ? "ring-LightOrange ring-opacity-40 ring-4 ring-offset-2"
            : ""
        }`}
        disabled={disabledStep2}
        onClick={() => {
          context.setStep(2);
        }}
      >
        2
      </FlatButton>
      <span
        className={`md:block hidden ${context.step >= 2 ? `text-Orange` : ""}`}
      >
        Add File
      </span>
      <div
        className={`w-48 h-1 rounded-xl ${
          context.step > 2 ? "bg-Orange" : "bg-gray-300"
        }`}
      ></div>
      <FlatButton
        className={`h-10 w-10 flex flex-none justify-center font-bold rounded-full ${
          context.step === 3 ? "btn-Orange-primary" : "border border-gray-300"
        } ${
          context.step === 3
            ? "ring-Orange ring-opacity-40 ring-4 ring-offset-2"
            : ""
        }`}
        disabled={disabledStep3}
        onClick={() => {
          context.setStep(3);
        }}
      >
        3
      </FlatButton>
      <span
        className={`md:block hidden ${context.step === 3 ? `text-Orange` : ""}`}
      >
        Summary
      </span>
    </div>
  );
};

export default Stepper;
