import React from "react";

const MenuItemButton = ({ onClick, text, active, className }) => {
  return (
    <button
      onClick={onClick}
      className={`hover:bg-orange hover:text-white group text-gray-400 flex rounded-md items-center w-full px-4 py-2 text-sm ${className}`}
    >
      {text}
    </button>
  );
};

export default MenuItemButton;
