import React from "react";
import AdminSidebar from "../components/Base/AdminSidebar";
import { CategoriesProvider } from "../context/CategoriesContext";
import Content from "../components/Admin/Categories/Content";

const Categories = () => {
  return (
    <div className="h-full ">
      <div className="flex h-full w-full">
        <AdminSidebar />
        <CategoriesProvider>
          <Content />
        </CategoriesProvider>
      </div>
    </div>
  );
};

export default Categories;
