import React, { useContext } from "react";
import { CategoriesContext } from "../../../context/CategoriesContext";
import Filters from "./Filters";
import Table from "./Table";
import Pagination from "../../Others/Pagination";
import Loading from "../../Others/Loading";

const Content = () => {
  const context = useContext(CategoriesContext);
  return (
    <div className="z-0 lg:p-10 mb-20 p-4 w-full overflow-auto space-y-7">
      <div className="flex items-center w-full text-2xl font-bold text-dark-blue uppercase mb-5 space-x-4">
        <span>Categories</span>
      </div>
      {context.saveLoading && <Loading loading={true} />}
      <Filters />
      <div className="space-y-4">
        <Table />
        <Pagination context={context} className="float-right" />
      </div>
    </div>
  );
};

export default Content;
