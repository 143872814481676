import React, { useContext, useEffect, useState } from "react";
import { ContentsContext } from "../../../../context/ContentsContext";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { getAllContentsList } from "../../../../services/admin";
import TableLoading from "./TableLoading";
import ContentError from "../../../Others/ContentError";
import DataTable from "../../../Base/DataTable";
import moment from "moment";
import { FlatButton } from "../../../Buttons/Buttons";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  EyeIcon,
} from "@heroicons/react/outline";

const AllRequests = () => {
  const context = useContext(ContentsContext);
  const filters = context.filters;
  const entries = context.entries;
  const page = context.page;
  const sortBy = context.sortBy;
  const history = useHistory();
  const { data, isError, isLoading } = useQuery(
    ["contents", { filters, entries, page, sortBy }],
    getAllContentsList
  );

  const [width, setWidth] = useState(window.innerWidth);
  const [headers, setHeaders] = useState([]);

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  useEffect(() => {
    if (context.table === "AllRequests" && data) {
      context.setPageCount(data.pages);
    }
  }, [context.table, data]);

  const SortBy = (type) => {
    const typeAsc = type + "Asc";
    const typeDesc = type + "Desc";
    context.sortBy === typeDesc
      ? context.setSortBy(typeAsc)
      : context.setSortBy(typeDesc);
  };

  useEffect(() => {
    if (width > 1200) {
      setHeaders([
        {
          text: "Content Name",
          className: "py-4 px-6 text-left cursor-pointer",
          onClick: () => SortBy("name"),
          component:
            context.sortBy === "nameAsc"
              ? ChevronUpIcon
              : context.sortBy === "nameDesc"
              ? ChevronDownIcon
              : "",
        },
        {
          text: "Author",
          className: "py-4 text-left cursor-pointer ",
          onClick: () => SortBy("author"),
          component:
            context.sortBy === "authorAsc"
              ? ChevronUpIcon
              : context.sortBy === "authorDesc"
              ? ChevronDownIcon
              : "",
        },
        { text: "Downloads", className: "", textAlign: "center" },
        { text: "Reports", className: "", textAlign: "center" },
        {
          text: "Status",
          className: "cursor-pointer",
          textAlign: "center",
          onClick: () => SortBy("status"),
          component:
            context.sortBy === "statusAsc"
              ? ChevronUpIcon
              : context.sortBy === "statusDesc"
              ? ChevronDownIcon
              : "",
        },
        {
          text: "Date Requested",
          className: "cursor-pointer",
          textAlign: "center",
          onClick: () => SortBy("date"),
          component:
            context.sortBy === "dateAsc"
              ? ChevronUpIcon
              : context.sortBy === "dateDesc"
              ? ChevronDownIcon
              : "",
        },
        { text: "Actions", className: "", textAlign: "center" },
      ]);
    } else if (width >= 450) {
      setHeaders([
        {
          text: "Content Name",
          className: "py-4 px-6 text-left cursor-pointer",
          onClick: () => SortBy("name"),
          component:
            context.sortBy === "nameAsc"
              ? ChevronUpIcon
              : context.sortBy === "nameDesc"
              ? ChevronDownIcon
              : "",
        },
        {
          text: "Status",
          className: "cursor-pointer ",
          textAlign: "center",
          onClick: () => SortBy("status"),
          component:
            context.sortBy === "statusAsc"
              ? ChevronUpIcon
              : context.sortBy === "statusDesc"
              ? ChevronDownIcon
              : "",
        },
        { text: "Actions", className: "", textAlign: "center" },
      ]);
    } else {
      setHeaders([
        {
          text: "Content Name",
          className: "py-4 px-6 text-left cursor-pointer",
          onClick: () => SortBy("name"),
          component:
            context.sortBy === "nameAsc"
              ? ChevronUpIcon
              : context.sortBy === "nameDesc"
              ? ChevronDownIcon
              : "",
        },
        { text: "Actions", className: "", textAlign: "center" },
      ]);
    }
  }, [width, context.sortBy]);

  // "nameAsc" => query.OrderBy(u => u.ReportedContent.Name),
  //"nameDesc" => query.OrderByDescending(u => u.ReportedContent.Name),
  //"authorAsc" => query.OrderBy(u => u.ReportedContent.Author),
  //               "authorDesc" => query.OrderByDescending(u => u.ReportedContent.Author),
  //               "reporterAsc" => query.OrderBy(u => u.Reporter.FullName),
  //               "reporterDesc" => query.OrderByDescending(u => u.Reporter.FullName),
  //               "statusAsc" => query.OrderBy(u => u.Status),
  //               "statusDesc" => query.OrderByDescending(u => u.Status),
  //               "dateAsc" => query.OrderBy(u => u.CreatedOn),
  //               "dateDesc" => query.OrderByDescending(u => u.CreatedOn),
  //               _ => query.OrderByDescending(u => u.CreatedOn)

  if (context.table === "AllRequests") {
    if (isLoading) {
      return <TableLoading />;
    }

    if (isError) {
      return <ContentError />;
    }

    // console.log(data);

    return (
      <div className="space-y-2 text-gray-600">
        <DataTable headers={headers} data={data.data}>
          {data.data.map((content) => {
            return (
              <tr className="hover:bg-gray-100 text-sm" key={content.contentId}>
                <td className="py-3 sm:px-6 px-3 w-3/12 ">
                  <div className="font-bold ">{content.name}</div>
                  <div
                    className={`text-xs gap-y-1 flex flex-col items-start ${
                      width > 1200 ? "hidden" : "block"
                    }`}
                  >
                    <div>
                      {content.authorName
                        ? content.authorName
                        : "Unknown Author"}
                    </div>
                    <span
                      className={`${width >= 450 && "hidden"} ${
                        content.status === "Approved"
                          ? "text-green-600 rounded-3xl"
                          : content.status === "Pending"
                          ? "text-yellow-600 rounded-3xl"
                          : content.status === "Declined"
                          ? "text-red-600 rounded-3xl"
                          : "text-gray-600 rounded-3xl"
                      }`}
                    >
                      {content.status}
                    </span>
                    <div className="text-gray-400 mt-2">
                      {moment.utc(content.uploadedDate).local().format("lll")}
                    </div>
                  </div>
                </td>
                <td className={`w-2/12 ${width > 1200 ? "" : "hidden"}`}>
                  {content.authorName ? content.authorName : "Unknown Author"}
                </td>
                <td className={`text-center ${width > 1200 ? "" : "hidden"}`}>
                  {content.numberOfDownloads}
                </td>
                <td className={`text-center ${width > 1200 ? "" : "hidden"}`}>
                  {content.numberOfReports}
                </td>
                <td
                  className={`text-xs text-center items-end ${
                    width < 450 && "hidden"
                  }`}
                >
                  <span
                    className={`${
                      content.status === "Approved"
                        ? "text-green-600 bg-green-200 px-3 py-1 rounded-3xl"
                        : content.status === "Pending"
                        ? "text-yellow-600 bg-yellow-200 px-3 py-1 rounded-3xl"
                        : content.status === "Declined"
                        ? "text-red-600 bg-red-200 px-3 py-1 rounded-3xl"
                        : "text-gray-600 bg-gray-200 px-3 py-1 rounded-3xl"
                    }`}
                  >
                    {content.status}
                  </span>
                </td>
                <td
                  className={`text-center text-xs ${
                    width > 1200 ? "" : "hidden"
                  }`}
                >
                  {moment.utc(content.uploadedDate).local().format("lll")}
                </td>
                <td className="">
                  <div className="w-full h-full flex justify-center py-3">
                    <FlatButton
                      onClick={() => {
                        history.push(
                          `/content-view/${content.contentId}/admin`
                        );
                      }}
                      className={`bg-orange text-white items-center flex gap-x-2 px-5 hover:bg-orange hover:bg-opacity-50`}
                    >
                      <span className="text-xs">View</span>
                      <EyeIcon className="h-4 w-4" />
                    </FlatButton>
                  </div>
                </td>
              </tr>
            );
          })}
        </DataTable>
      </div>
    );
  }
  return <div></div>;
};

export default AllRequests;
