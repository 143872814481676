import {
  BanIcon,
  CheckIcon,
  ClockIcon,
  ExclamationIcon,
  InformationCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import React from "react";
import ImageNotFound from "../../../../assets/ImageNotFound.jpg";

var env = process.env.NODE_ENV || "development";
const server = `${
  env === "development"
    ? process.env.REACT_APP_DEV_SERVER
    : process.env.REACT_APP_PROD_SERVER
}`;

const StatusIndicator = ({ bg, children }) => {
  return (
    <div
      className={`rounded-full w-5 h-5 flex items-center justify-center text-white bg-${bg}-600`}
    >
      {children}
    </div>
  );
};

const StatusBadge = ({ status, color, component: Component }) => {
  return (
    <div
      className={`flex items-center gap-x-2 bg-${color}-200 p-2 rounded-full `}
    >
      <span className={`font-semibold text-sm text-${color}-600`}>
        {status}
      </span>
      <StatusIndicator bg={color}>
        <Component className="h-4 w-4" />
      </StatusIndicator>
    </div>
  );
};

const ContentDetails = ({
  loading,
  type,
  thumbnailUrl,
  name,
  ratings,
  status,
  description,
  author,
  publisher,
  language,
}) => {
  return (
    <div className="w-full relative z-0 bg-black shadow-xl rounded-bl-3xl sm:p-10 py-10 pt-20 px-5">
      <div className="absolute top-0 right-0 left-0 bottom-0 overflow-hidden rounded-bl-3xl">
        <img
          src={
            thumbnailUrl === "" || thumbnailUrl === null
              ? "https://getuikit.com/v2/docs/images/placeholder_600x400.svg"
              : server + thumbnailUrl
          }
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = ImageNotFound;
          }}
          className=" h-full w-full opacity-50 object-cover z-0 lg:rounded-md rounded-t-md filter blur-md transform scale-125 "
        />
      </div>
      <div className="flex md:flex-row flex-col gap-x-10 w-full text-white relative z-10 gap-y-4">
        <div className="sm:h-96 h-50 w-full flex md:justify-end justify-center">
          <div className="h-full sm:w-8/12 w-full rounded-3xl overflow-hidden shadow-xl">
            <img
              src={
                thumbnailUrl === "" || thumbnailUrl === null
                  ? "https://getuikit.com/v2/docs/images/placeholder_600x400.svg"
                  : server + thumbnailUrl
              }
              className=" object-cover h-full w-full "
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = ImageNotFound;
              }}
            />
          </div>
        </div>
        <div className="content-info space-y-4 flex flex-col w-full items-center">
          <div className="sm:w-8/12 w-full">
            <div className="title flex items-start space-y-3 ">
              <span className="text-3xl font-bold mr-3">{name}</span>
              {type === "content" && (
                <div className="ml-auto">
                  {status === "Approved" && (
                    <StatusBadge
                      status={status}
                      color="green"
                      component={CheckIcon}
                    />
                  )}
                  {status === "Pending" && (
                    <StatusBadge
                      status={status}
                      color="yellow"
                      component={ClockIcon}
                    />
                  )}
                  {status === "Declined" && (
                    <StatusBadge
                      status={status}
                      color="red"
                      component={BanIcon}
                    />
                  )}
                  {status === "Disabled" && (
                    <StatusBadge
                      status={status}
                      color="gray"
                      component={BanIcon}
                    />
                  )}
                </div>
              )}
              {type === "report" && (
                <div className="ml-auto">
                  {status === "Approved" && (
                    <StatusBadge
                      status="Report Approved"
                      color="green"
                      component={CheckIcon}
                    />
                  )}
                  {status === "Pending" && (
                    <StatusBadge
                      status="Report Pending"
                      color="yellow"
                      component={ClockIcon}
                    />
                  )}
                  {status === "Declined" && (
                    <StatusBadge
                      status="Report Declined"
                      color="red"
                      component={BanIcon}
                    />
                  )}
                  {status === "Disabled" && (
                    <StatusBadge
                      status="Report Disabled"
                      color="gray"
                      component={BanIcon}
                    />
                  )}
                </div>
              )}
            </div>
            <div>
              <span className="font-semibold text-lg opacity-70">
                {author || "Unknown Author"}
              </span>
            </div>
            <div className="categories text-white text-sm ">
              <div>
                <span>{publisher || "Unknown Publisher"}</span>
              </div>
            </div>
          </div>
          <div className="description font-semibold opacity-70 sm:w-8/12 w-full flex justify-start">
            {description}
          </div>
          {/* <div className="tags flex-auto flex flex-col justify-end space-y-2">
            <span>Category</span>
            <div className="flex p-2 border border-gray-300 rounded-md">
              <div className="text-sm font-semibold bg-gray-200 py-1 px-2 rounded-md ">
                Fiction
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ContentDetails;
