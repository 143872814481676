import React from "react";

export const FlatButton = ({
  children,
  className,
  onClick,
  disabled,
  type,
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={`flex items-center px-3 py-2 hover:bg-gray-200 font-semibold text-sm rounded-md ${className}`}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
