import React, { createContext, useState } from "react";
import {
  updateReportStatus,
  updateStatus,
  setContentViewOnly,
} from "../services/admin";
import { useToasts } from "react-toast-notifications";
import { useQueryClient } from "react-query";

const ContentsContext = createContext();
const { Provider } = ContentsContext;

const ContentsProvider = ({ children }) => {
  // Filters
  const [contentName, setContentName] = useState("");
  const [author, setAuthor] = useState("");
  const [status, setStatus] = useState("");
  const [dateRequestedFrom, setDateRequestedFrom] = useState("");
  const [dateRequestedTo, setDateRequestedTo] = useState("");
  const [table, setTable] = useState("AllRequests");
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  const filtersInitialState = {
    contentName: "",
    author: "",
    status: "",
    dateRequestedFrom: "",
    dateRequestedTo: "",
  };

  const ApproveContent = (id) => {
    setLoading(true);
    const data = { status: "Approved" };
    updateStatus({ id, data })
      .then((res) => {
        setLoading(false);
        queryClient.invalidateQueries("content-view");
        addToast("Content Approved", { appearance: "success" });
        // console.log(res);
      })
      .catch(() => {
        setLoading(false);
        addToast("Error Updating Status", { appearance: "error" });
      });
  };

  const DeclineContent = (id) => {
    setLoading(true);
    const data = { status: "Declined" };
    updateStatus({ id, data })
      .then((res) => {
        setLoading(false);
        addToast("Content Declined", { appearance: "success" });
        queryClient.invalidateQueries("content-view");
        // console.log(res);
      })
      .catch(() => {
        setLoading(false);
        addToast("Error Updating Status", { appearance: "error" });
      });
  };

  const DisableContent = (id) => {
    setLoading(true);
    const data = { status: "Disabled" };
    updateStatus({ id, data })
      .then((res) => {
        setLoading(false);
        addToast("Content Disabled", { appearance: "success" });
        queryClient.invalidateQueries("content-view");
        // console.log(res);
      })
      .catch(() => {
        setLoading(false);
        addToast("Error Updating Status", { appearance: "error" });
      });
  };

  const SetViewOnly = (id, datas) => {
    const data = { isViewOnly: datas };
    // console.warn(data);
    setContentViewOnly({ id, data })
      .then((res) => {
        queryClient.invalidateQueries("content-view");
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const [filters, setFilters] = useState(filtersInitialState);

  const ApplyFilters = () => {
    setFilters({
      contentName,
      author,
      status,
      dateRequestedFrom,
      dateRequestedTo,
    });
    setPage(1);
  };

  const ResetFilters = () => {
    setFilters(filtersInitialState);
    setContentName("");
    setAuthor("");
    setStatus("");
    setDateRequestedFrom("");
    setDateRequestedTo("");
  };

  const UpdateReportStatus = (id, status, remarks) => {
    setLoading(true);
    const data = {
      status,
      remarks,
    };

    updateReportStatus(id, data)
      .then((res) => {
        setLoading(false);
        if (status === "Approved")
          addToast("Report Approved", { appearance: "success" });
        else addToast("Report Declined", { appearance: "success" });
        queryClient.invalidateQueries("report");
        // console.log(res);
      })
      .catch(() => {
        setLoading(false);
        addToast("Error Updating Status", { appearance: "error" });
      });
  };

  //Pagination
  const [entries, setEntries] = useState(10);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [sortBy, setSortBy] = useState("dateDesc");

  return (
    <Provider
      value={{
        sortBy,
        setSortBy,
        UpdateReportStatus,
        loading,
        DisableContent,
        SetViewOnly,
        ApproveContent,
        DeclineContent,
        table,
        setTable,
        entries,
        setEntries,
        page,
        setPage,
        pageCount,
        setPageCount,
        filtersInitialState,
        filters,
        setFilters,
        contentName,
        author,
        status,
        dateRequestedFrom,
        dateRequestedTo,
        setContentName,
        setAuthor,
        setStatus,
        setDateRequestedFrom,
        setDateRequestedTo,
        ApplyFilters,
        ResetFilters,
      }}
    >
      {children}
    </Provider>
  );
};

export { ContentsContext, ContentsProvider };
