import { authAxios } from "./interceptor";
import axios from "axios";

var env = process.env.NODE_ENV || "development";
const baseUrl = `${
  env === "development"
    ? process.env.REACT_APP_DEV_SERVER
    : process.env.REACT_APP_PROD_SERVER
}/Content/get-content-type`;

const headers = {
  headers: {
    "Content-Type": "application/json",
  },
};
export const contentTypeOptions = () => {
  return authAxios.get(`${baseUrl}`).then((res) => {
    return res.data;
  });
};


