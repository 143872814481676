import React from "react";
import Loader from "react-loader-spinner";

const LoadingNotRounded = ({ text, className }) => {
  return (
    <div className={className}>
      <div className="bg-white flex flex-col gap-y-4 justify-center items-center bg-opacity-50 z-50 absolute top-0 left-0 bottom-0 w-full h-full">
        {text && (
          <div className="font-semibold text-sm text-Orange animate-pulse ">
            {text}
          </div>
        )}
        <Loader type="TailSpin" color="#F7941E" height={70} width={70} />
      </div>
    </div>
  );
};

export default LoadingNotRounded;
