import {
  BookmarkAltIcon,
  BookOpenIcon,
  CodeIcon,
  DownloadIcon,
  PencilAltIcon,
  StarIcon,
  VideoCameraIcon,
} from "@heroicons/react/solid";
import * as mime from "mime-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import RatingToStar from "./RatingToStar";
import { useHistory } from "react-router";
import FastAverageColor from "fast-average-color";
import ImageNotFound from "../../assets/ImageNotFound.jpg";
import Tooltip from "react-simple-tooltip";

var env = process.env.NODE_ENV || "development";
const server = `${
  env === "development"
    ? process.env.REACT_APP_DEV_SERVER
    : process.env.REACT_APP_PROD_SERVER
}`;

const DisplayItem = ({ item }) => {
  const history = useHistory();
  const fac = new FastAverageColor();
  const [color, setColor] = useState("");

  useEffect(() => {
    if (item.thumbnailUrl) {
      fac
        .getColorAsync(server + item.thumbnailUrl)
        .then((color) => {
          setColor(color.hex);
        })
        .catch((e) => {
          // console.log(e);
        });
    }
  }, [item.thumbnailUrl]);

  // console.warn(mime.lookup(item.getFileDirectory.fileName).includes("video"));
  return (
    <div
      title={item.name}
      className="group w-full relative transition bg-white cursor-pointer shadow-lg hover:shadow-xl"
      onClick={() => {
        history.push(`/content-view/${item.contentId}`);
      }}
    >
      {/* <div className="absolute top-0 w-full h-full left-0 rounded-2xl bg-gradient-to-b from-gray-300 to-transparent flex items-center justify-center opacity-0 text-white group-hover:opacity-40 font-bold transition z-20 text-2xl ">
        View
      </div> */}

      {item &&
        (item.isEmbededMain ? (
          <div className="absolute z-30 ring-2 flex ring-opacity-50 justify-center items-center gap-x-2 ring-white border-white text-white p-1 px-2 transition font-semibold text-xs rounded-full top-4 right-4 opacity-0 group-hover:opacity-100">
            Embed <CodeIcon className="h-5 w-5" />
          </div>
        ) : (
          item.getFileDirectory !== null &&
          (!mime.lookup(item.getFileDirectory.fileName).includes("video") ? (
            <div className="absolute z-30 ring-2 flex ring-opacity-50 justify-center items-center gap-x-2 ring-white border-white text-white p-1 px-2 transition font-semibold text-xs rounded-full top-4 right-4 opacity-0 group-hover:opacity-100">
              Documents <BookOpenIcon className="h-5 w-5" />
            </div>
          ) : (
            <div className="absolute z-30 ring-2 flex justify-center ring-opacity-50 items-center gap-x-2 ring-white border-white text-white p-1 px-2 transition font-semibold text-xs rounded-full top-4 right-4 opacity-0 group-hover:opacity-100">
              Media <VideoCameraIcon className="h-5 w-5" />
            </div>
          ))
        ))}
      <div className="w-full">
        <div className="image relative bg-black bg-opacity-30 h-80 w-full justify-center flex items-center transition overflow-hidden group-hover:shadow-xl">
          <div className="absolute w-full h-full bg-black text-white  uppercase z-20 flex items-center justify-center bg-opacity-30 backdrop-filter backdrop-blur-sm opacity-0 group-hover:opacity-100 transition">
            <button
              className="text-white px-4 py-2 text-sm font-semibold rounded-md border-2 border-white hover:bg-Orange hover:border-Orange transition"
              onClick={() => {
                history.push(`/content-view/${item.contentId}`);
              }}
            >
              View Content
            </button>
          </div>

          <div className="w-full h-full flex items-center overflow-hidden shadow-xl">
            <img
              src={
                //w-8/12 h-56  `https://media.istockphoto.com/vectors/no-thumbnail-image-vector-graphic-vector-id1147544806?k=6&m=1147544806&s=612x612&w=0&h=569pPmUNG4Ivtw9d4597Q71mTD5hDAZoOfRPmi1xzqg=`
                item.thumbnailUrl === ""
                  ? ImageNotFound
                  : server + item.thumbnailUrl
              }
              className="object-cover h-full w-full relative z-10 duration-500 mx-auto transform transition"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = ImageNotFound;
              }}
            />
          </div>
          <div
            className="object-cover w-full z-0 opacity-50 absolute h-full duration-500 mx-auto transform transition"
            style={{ backgroundColor: color }}
          ></div>
        </div>
        <div className="relative z-40 font-semibold bg-white py-3  space-y-1 w-full">
          <div className="px-5 w-full">
            <p className="w-full sm:truncate overflow-none ">{item.name}</p>

            <div className="text-gray-500 h-5 text-xs">
              <div className="w-full truncate">
                {item.authorName ? item.authorName : "Unknown Author"}
              </div>
            </div>
          </div>

          <div className="flex flex-auto items-end w-full text-gray-400 transition bg-white p-3 rounded-2xl">
            <div className="flex flex-col text-xs items-center flex-1 space-y-2">
              <div>Rating</div>
              <div className="text-sm text-yellow-500 font-bold flex items-center space-x-1">
                <div>{item.ratings}/5</div>
                <StarIcon className="h-4 w-4 " />
              </div>
            </div>

            <div className="flex flex-col text-xs items-center flex-1 space-y-2">
              <div>Reviews</div>
              <div className="text-sm text-green-500 font-bold flex items-center space-x-1">
                <div>{item.totalReviews}</div>
                <PencilAltIcon className="h-4 w-4 " />
              </div>
            </div>
            <div className="flex flex-col text-xs items-center flex-1 space-y-2">
              <div>Downloads</div>
              <div className="text-sm text-blue font-bold flex items-center space-x-1">
                <div>{item.numberOfDownloads}</div>
                <DownloadIcon className="h-4 w-4 " />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisplayItem;
