import React, { useEffect, useState } from "react";
import { getNewReleases } from "../../../services/contents";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import Carousel from "../../Others/Carousel";
import ContentSkeleton from "../../Others/ContentSkeleton";
import ContentError from "../../Others/ContentError";

const NewReleases = () => {
  const { data, isLoading, isError } = useQuery("newReleases", getNewReleases);
  if (isError) {
    return <ContentError />;
  }

  if (isLoading) {
    return <ContentSkeleton />;
  }

  if (data) {
    return <Carousel data={data.slice(0, 8)} name="NewReleases" />;
  }
};

export default NewReleases;
