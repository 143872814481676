import React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import RatingToStar from "./RatingToStar";
import { Link } from "react-router-dom";
import DisplayItem from "./DisplayItem";
var right = 1;

var env = process.env.NODE_ENV || "development";
const server = `${
  env === "development"
    ? process.env.REACT_APP_DEV_SERVER
    : process.env.REACT_APP_PROD_SERVER
}`;

const carouselMove = (direction, name) => {
  const carousel = document.querySelector(`.c-${name}-container`);
  const carouselsm = document.querySelector(`.c-${name}-container-sm`);
  const contWidth = carouselsm.getBoundingClientRect().width / 8;

  if (direction == "right") {
    carousel.style.transform = "translateX(-50%)";

    if (right < 7) right += 1;
    carouselsm.style.transform = `translateX(-${contWidth * right}px)`;
  } else {
    carousel.style.transform = "translateX(0)";
    if (right > 0) right -= 1;
    carouselsm.style.transform = `translateX(-${contWidth * right}px)`;
  }
};

const Carousel = ({ data, name }) => {
  return (
    <div className="relative mt-5 px-10 c-container">
      <div className="w-full my-2 overflow-hidden">
        <div
          style={{ width: "200%" }}
          className={`hidden lg:grid 2xl:grid-cols-8 grid-cols-4 gap-x-3 p-2 transition c-${name}-container`}
        >
          {data.map((item, key) => {
            return (
              <DisplayItem item={item} key={key} />
              // <div key={key} className="group relative cursor-pointer">
              //   <div className="opacity-0 group-hover:opacity-100 absolute right-2 top-2 text-sm font-semibold px-3 py-2 rounded-md text-white bg-gray-800 bg-opacity-40 z-20">
              //     <Link to={`/content-view/${item.contentId}`}>View</Link>
              //   </div>
              //   <div className="image bg-gray-300 h-48 w-full flex border  items-center hover:border-CSIBlue hover:border-2 justify-center rounded-md transition group-hover:shadow-lg overflow-hidden z-10 ">
              //     <img
              //       src={
              //         item.thumbnailUrl !== ""
              //           ? process.env.REACT_APP_DEV_SERVER + item.thumbnailUrl
              //           : "https://media.istockphoto.com/vectors/no-thumbnail-image-vector-graphic-vector-id1147544806?k=6&m=1147544806&s=612x612&w=0&h=569pPmUNG4Ivtw9d4597Q71mTD5hDAZoOfRPmi1xzqg="
              //       }
              //       className="object-cover h-full rounded-md w-full duration-500 transform transition "
              //     />
              //   </div>
              //   <div className="px-3 py-1 ">
              //     <div className="font-semibold text-gray-500">{item.name}</div>
              //     <div className="flex">
              //       <RatingToStar rating={item.ratings} size={4} />
              //     </div>
              //   </div>
              // </div>
            );
          })}
        </div>
        <div
          style={{ width: "800%" }}
          className={`lg:hidden grid grid-cols-8 transition c-${name}-container-sm`}
        >
          {data.map((item, key) => {
            return (
              <DisplayItem item={item} key={key} />
              // <div key={key} className="hover:shadow relative">
              //   <div className="absolute right-2 top-2 text-sm font-semibold px-3 py-2 rounded-md text-white hover:bg-gray-800 hover:bg-opacity-40 z-20">
              //     View
              //   </div>
              //   <div className="image bg-gray-300 h-48 w-full flex items-center justify-center rounded-md overflow-hidden ">
              //     <img
              //       src={
              //         item.thumbnailUrl === null
              //           ? "https://getuikit.com/v2/docs/images/placeholder_600x400.svg"
              //           : process.env.REACT_APP_DEV_SERVER + item.thumbnailUrl
              //       }
              //       className="object-cover h-full rounded-md w-full duration-500 transform transition hover:scale-125"
              //     />
              //   </div>
              //   <div className="px-3 py-1 ">
              //     <div className="font-semibold text-gray-500">{item.name}</div>
              //     <div className="flex">
              //       <RatingToStar rating={item.rating} size={4} />
              //     </div>
              //   </div>
              // </div>
            );
          })}
        </div>
      </div>
      {data.length > 4 && (
        <>
          <button
            onClick={() => carouselMove("left", name)}
            className="absolute top-1/3 -left-2 p-2 z-100 hover:bg-gray-200 rounded-full text-gray-400 "
          >
            <ChevronLeftIcon className="h-5 w-5" />
          </button>
          <button
            onClick={() => carouselMove("right", name)}
            className="absolute top-1/3 -right-2 p-2 z-100 hover:bg-gray-200 rounded-full text-gray-400 "
          >
            <ChevronRightIcon className="h-5 w-5" />
          </button>{" "}
        </>
      )}
    </div>
  );
};

export default Carousel;
