import React, { useState, useContext, useEffect } from "react";
import { FlatButton } from "../components/Buttons/Buttons";
import { useHistory } from "react-router";
import {
  ArrowNarrowLeftIcon,
  CheckIcon,
  ChevronLeftIcon,
  EyeIcon,
} from "@heroicons/react/outline";
import AdminSidebar from "../components/Base/AdminSidebar";
import { useQuery } from "react-query";
import { viewContent } from "../services/admin";
import { useParams } from "react-router";
import DataTable from "../components/Base/DataTable";
import moment from "moment";
import Modal from "../components/Others/Modal";
import TextArea from "../components/Inputs/TextArea";
import { ContentsContext, ContentsProvider } from "../context/ContentsContext";
import ContentDetails from "../components/Admin/Contents/Single Content View/ContentDetails";
import ContentLoading from "../components/Admin/Contents/Single Content View/ContentLoading";
import ContentButtons from "../components/Admin/Contents/Single Content View/ContentButtons";
import DocsReader from "../components/Contents/DocsReader";
import ContentModal from "../components/Admin/Contents/Single Content View/ContentModal";
import Loading from "../components/Others/Loading";

import { AuthContext } from "../context/AuthContext";
import ContentError from "../components/Others/ContentError";

import ViewModal from "../components/Others/ViewModal";
import ReactHtmlParser from "react-html-parser";

const ContentInfo = () => {
  let { contentId } = useParams();
  const [modal, setModal] = useState(false);
  const [path, setPath] = useState("");
  const [fileId, setFileId] = useState("");

  const { data, isError, isLoading } = useQuery(
    ["content-view", contentId],
    viewContent
  );

  const context = useContext(ContentsContext);

  useEffect(() => {
    if (data) {
      const iframes = document.querySelectorAll("iframe");
      iframes.forEach((iframe) => {
        iframe.width = "100%";
        iframe.height = "100%";
      });
    }
  }, [data]);

  if (isLoading) {
    return (
      <div className="animate-pulse space-y-7">
        <div className=" space-y-7  bg-gray-300 rounded-bl-3xl  p-10">
          <div className="h-80"></div>
        </div>
        <div className="flex justify-center px-10">
          <div className="w-96 h-40 bg-gray-300 rounded-3xl"></div>
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="space-y-7">
        <div className=" space-y-7  bg-gray-300 rounded-bl-3xl  p-10">
          <div className="h-80">
            <ContentError />
          </div>
        </div>
        <div className="flex justify-center px-10">
          <div className="w-96 h-40 bg-gray-300 rounded-3xl"></div>
        </div>
      </div>
    );
  }

  if (data) {
    return (
      <div className="space-y-6 ">
        <ViewModal
          path={path}
          contentId={contentId}
          isOpen={modal}
          onClose={() => {
            setModal(false);
          }}
          fileId={fileId}
        />

        <ContentDetails
          loading={context.loading}
          type="content"
          thumbnailUrl={data.getContent.thumbnailUrl}
          name={data.getContent.name}
          ratings={data.getContent.ratings}
          status={data.getContent.status}
          description={data.getContent.description}
          author={data.getContent.authorName}
          publisher={data.getContent.publisher}
          language={null}
        />
        {data.files.length > 0 && (
          <div className="w-full flex justify-center relative z-0 ">
            <div className="sm:w-8/12 w-full sm:mx-0 mx-2 space-y-4 ">
              <div className="font-bold text-xl text-Orange mx-4">Files</div>
              <div className="sm:p-6 p-3 text-sm rounded-3xl bg-white shadow-xl flex flex-col text-gray-600">
                {data.files.map((file, i) => {
                  return (
                    <div
                      key={file.fileName}
                      className="flex items-center pl-4 w-full"
                    >
                      <div className=" flex gap-x-4 font-semibold">
                        <div className="font-bold">{i + 1}.</div>
                        <div>{file.fileName}</div>
                      </div>
                      <button
                        className="gap-x-2 ml-auto flex text-xs font-semibold text-gray-500 hover:bg-gray-100 px-3 py-2 rounded-md"
                        onClick={() => {
                          setModal(true);
                          setPath(file);
                          setFileId(file.fileId);
                        }}
                      >
                        View Content <EyeIcon className="h-4 w-4" />
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {data.getContent.embededUrl !== null && (
          <div className="w-full flex justify-center relative z-0 ">
            <div className="sm:w-8/12 w-full sm:mx-0 mx-2 space-y-4 ">
              <div className="font-bold text-xl text-Orange mx-4">Embed</div>
              <div className="sm:p-6 p-3 text-sm rounded-3xl bg-white shadow-xl flex flex-col text-gray-600">
                <div className="flex flex-col items-start gap-y-2">
                  <div className="w-full font-semibold">
                    <div className="w-full h-96">
                      {ReactHtmlParser(data.getContent.embededUrl)}
                    </div>
                  </div>
                  <div className="gap-x-2 flex flex-col w-full font-semibold text-gray-500 gap-y-2">
                    <div>Embed Code</div>
                    <div className="bg-black relative space-y-2 bg-opacity-70 p-4 text-white text-sm rounded-md">
                      <code className="w-full">
                        {data.getContent.embededUrl}
                      </code>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
  return <div></div>;
};

const Buttons = () => {
  let { contentId } = useParams();
  const context = useContext(ContentsContext);
  const { data, isError, isLoading } = useQuery(
    ["content-view", contentId],
    viewContent
  );

  if (isLoading) {
    return <div></div>;
  }

  if (isError) {
    return <div></div>;
  }

  return (
    <ContentButtons
      contentStatus={data.getContent.status}
      contentId={contentId}
      context={context}
      isViewOnly={data.getContent.isViewOnly}
    />
  );
};

const Load = () => {
  const context = useContext(ContentsContext);
  if (context.loading)
    return (
      <div>
        <Loading
          loading={true}
          className="absolute top-0 bottom-0 right-0 left-0"
        />
      </div>
    );
  return <div></div>;
};

const ContentViewAdmin = () => {
  const history = useHistory();
  const context = useContext(ContentsContext);

  return (
    <ContentsProvider>
      <div className="h-full ">
        <div className="flex h-full w-full border-black">
          <AdminSidebar />
          <Load />
          <div className="sm:ml-10 ml-0 overflow-auto w-full h-full pb-40">
            <div className="relative">
              <FlatButton
                className="absolute top-5 left-5 z-40 transition text-white hover:bg-opacity-20"
                onClick={() => {
                  history.push("/contents");
                }}
              >
                <ArrowNarrowLeftIcon className="h-5 w-5 mr-2" />
                Back to Contents
              </FlatButton>
            </div>
            <ContentInfo />

            <Buttons />
          </div>
        </div>
      </div>
    </ContentsProvider>
  );
};

export default ContentViewAdmin;
